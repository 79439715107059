import {
  EntityPageBase,
  EntityPageBaseRef,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { Order, PaymentData } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import { useStore } from '../../helpers/use-store';
import { Col, Flex, message, Row } from 'antd';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import OrderPaymentData from './order-payment-data';
import OrderStatusCell from './order-status';
import OrderUniqueIdAutocomplete from '../../components/order-autocomplete/order-autocomplete';

type Filters = {
  userId?: string;
  uniqId?: string;
};

const Orders = () => {
  const [filters, setFilters] = useState<Filters>({});

  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);

  const {
    dataStore: { orderStore, userStore },
  } = useStore();
  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onOrderUpdate = () => {
    refresh();

    message.success('Order status updated');
  };

  const onSearchClick = async (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }
    setFilters(filters);
    refresh();
  };

  const onOrderSearchClick = async (uniqId: string) => {
    if (uniqId) {
      filters.uniqId = uniqId;
    } else {
      delete filters.uniqId;
    }
    setFilters(filters);
    refresh();
  };

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await orderStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading orders for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<Order & { productName: string }> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Order ID',
      dataIndex: 'uniqId',
      render: (_, item) => {
        return (
          <TextComponent
            type="secondary"
            ellipsis
            copyable={{ text: item.uniqId }}
          >
            {item.uniqId}
          </TextComponent>
        );
      },
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary" copyable={{ text: item.userId }} />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'userEmail',
      align: 'center',
      render: (_, item) => {
        const email = item?.information?.email;
        return (
          <TextComponent
            type="secondary"
            copyable={email ? { text: email } : false}
          >
            {email}
          </TextComponent>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '5%',
      render: (_, item) => {
        return (
          <Flex align={'center'} justify={'center'}>
            <Statistic value={item.amount} />
          </Flex>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, item) => {
        return (
          <OrderStatusCell
            id={item._id}
            status={item.status}
            onSave={onOrderUpdate}
          />
        );
      },
    },
    {
      title: 'Product type',
      dataIndex: 'productType',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.productType}
          </TextComponent>
        );
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'productId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.productName}
          </TextComponent>
        );
      },
    },
    {
      title: 'Issue Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
          </TextComponent>
        );
      },
    },
    {
      title: 'Payment Data',
      dataIndex: 'method',
      align: 'center',

      render: (_, item) => {
        return (
          <>
            <OrderPaymentData
              paymentData={item.paymentData}
              onSave={async (paymentData: PaymentData) => {
                await orderStore.updatePaymentData(item.uniqId, paymentData);
                onOrderUpdate();
              }}
              uniqId={item.uniqId}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col span={6}>
          <Flex vertical>
            <TextComponent>Email: </TextComponent>
            <UserAutocomplete onSelect={(userId) => onSearchClick(userId)} />
          </Flex>
        </Col>
        <Col span={6}>
          <Flex vertical>
            <TextComponent>Order ID: </TextComponent>
            <OrderUniqueIdAutocomplete
              onSelect={(uniqId) => onOrderSearchClick(uniqId)}
            />
          </Flex>
        </Col>
      </Row>
      <EntityPageBase
        ref={entityPageRef}
        columns={columns}
        requestNextPage={requestPage}
        abilities={userStore.currentUser?.abilities}
      />
    </>
  );
};

export default Orders;
