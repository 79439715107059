import { BaseIdentifiers } from '../common/identifiers';
import { Product } from '../product/product';

export enum AddOnType {
  Renew = 'renew',
  BypassDailyPause = 'bypass-daily-pause',
}

export type AddOn = BaseIdentifiers &
  Product & {
    type: AddOnType;
    description?: string;
    programIds?: string[];
    config?: AddOnConfig;
  };

export type RenewAddOnConfig = {
  type: AddOnType.Renew;
  renewLength: number;
};

export type AddOnConfig = RenewAddOnConfig;
