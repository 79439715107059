import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  EntityPageBaseRef,
  EntityPageWithDrawer,
  PayoutStatusTag,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import {
  PaymentMethod,
  Payout,
  PayoutSource,
  PayoutStatus,
  User,
} from '@monorepo/types';
import {
  App,
  Button,
  Col,
  Descriptions,
  Flex,
  message,
  Row,
  Select,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import AccountAutocomplete from '../../components/account-autocomplete/account-autocomplete';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { useStore } from '../../helpers/use-store';
import CurrencyConverterModal from '../currency-converter-modal/currency-converter-modal';
import { FormModalActions } from '../form-modal/form-modal';
import { PayoutDrawer } from '../payout-drawer/payout-drawer';
import './payouts.scss';
import PayoutPaymentModal, {
  PayoutPaymentModalActions,
} from './payout-payment-modal';

export type PayoutWithUser = Payout & { user: User };

const columnsToFilterMap: { [key: string]: string[] } = {
  [PayoutSource.Account]: [],
  [PayoutSource.Affiliate]: [
    'companyShare',
    'transactionId',
    'creditAmount',
    'userShare',
    'balanceAmount',
  ],
};

type Filters = {
  userId?: string;
  sourceId?: string;
  status?: PayoutStatus;
};
const searchStatuses = Object.values(PayoutStatus).map((status) => ({
  label: <TextComponent capitalize={true}>{status}</TextComponent>,
  value: status,
})) as { label: JSX.Element; value: PayoutStatus | 'all' }[];

const getDefaultValues = (payout?: Payout): Partial<Payout> => {
  if (payout) {
    return payout;
  }
  return {
    status: PayoutStatus.Pending,
  };
};

searchStatuses.unshift({
  label: <TextComponent capitalize={true}>All</TextComponent>,
  value: 'all',
});

const Payouts = ({ type }: { type: PayoutSource }) => {
  const {
    dataStore: { payoutStore, userStore },
  } = useStore();
  const { modal } = App.useApp();
  const [filters, setFilters] = useState<Filters>({});
  const [currentPayout, setCurrentPayout] = useState<PayoutWithUser>(
    {} as PayoutWithUser
  );
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);
  const paymentModalRef = useRef<PayoutPaymentModalActions>(
    {} as PayoutPaymentModalActions
  );

  const currencyConverterModalRef = useRef<FormModalActions>(
    {} as FormModalActions
  );

  const form = useForm<Payout>({
    mode: 'all',
    defaultValues: { ...getDefaultValues(), type },
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await payoutStore.paginate({
        page,
        limit,
        filters: { ...filters, type },
      });
    } catch (e) {
      console.error(
        `Failed loading payouts for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const showPaymentData = (record: PayoutWithUser) => {
    modal.info({
      title: `Payment Details Details for: ${record.user.email}`,
      width: '50%',
      closable: true,
      maskClosable: true,
      content: (
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Payment Method">
            {record.paymentMethod}
          </Descriptions.Item>
          {record.metadata?.email && (
            <Descriptions.Item label="Email">
              {record.metadata?.email}
            </Descriptions.Item>
          )}
          {record.metadata?.cryptoCurrency && (
            <Descriptions.Item label="Crypto Currency">
              {record.metadata?.cryptoCurrency}
            </Descriptions.Item>
          )}
          {record.metadata?.currency && (
            <Descriptions.Item label="Currency">
              {record.metadata?.currency}
            </Descriptions.Item>
          )}
          {record.metadata?.walletAddress && (
            <Descriptions.Item label="Wallet Address">
              {record.metadata?.walletAddress}
            </Descriptions.Item>
          )}
          {record.metadata?.commission && (
            <Descriptions.Item label="Commission">
              {record.metadata?.commission}%
            </Descriptions.Item>
          )}
          {record.metadata?.fullName && (
            <Descriptions.Item label="Full name">
              {record.metadata?.fullName}
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
      okText: 'Close',
    });
  };

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onSearchChange = (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }

    setFilters(filters);
    refresh();
  };

  const onSearchStatusChange = (status: PayoutStatus | 'all') => {
    if (status && status !== 'all') {
      filters.status = status;
    } else {
      delete filters.status;
    }

    setFilters(filters);
    refresh();
  };

  const onAccountSearchClick = async (accountId: string) => {
    if (accountId) {
      filters.sourceId = accountId;
    } else {
      delete filters.sourceId;
    }

    setFilters(filters);
    refresh();
  };

  const columns: ColumnsType<
    PayoutWithUser & { accountTradingSystem: string }
  > = [
    {
      title: 'Payout Source Id',
      dataIndex: 'payoutId',
      width: '5%',
      render: (_, item) => {
        return <TextComponent>{item.accountTradingSystem}</TextComponent>;
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: '10%',
      render: (_, item) => {
        return (
          <>
            <div style={{ whiteSpace: 'nowrap' }}>
              <TextComponent>
                {item.user.firstName} {item.user.lastName}
              </TextComponent>
            </div>
            <div style={{ whiteSpace: 'nowrap' }}>
              <TextComponent type="secondary">{item.user.email}</TextComponent>
            </div>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '5%',
      render: (_, item: Payout) => {
        return <PayoutStatusTag status={item.status} />;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '5%',
      render: (_, item: Payout) => {
        return <Statistic value={item.amount} prefix={'$'} precision={2} />;
      },
    },
    {
      title: 'Company Balance Share',
      dataIndex: 'companyShare',
      width: '5%',
      render: (_, item: Payout) => {
        return (
          item.metadata?.companyShare && (
            <Statistic
              value={item.metadata?.companyShare}
              prefix={'$'}
              precision={2}
            />
          )
        );
      },
    },
    {
      title: 'User Balance Share',
      dataIndex: 'userShare',
      width: '5%',
      render: (_, item: Payout) => {
        return (
          item.metadata?.userShare && (
            <Statistic
              value={item.metadata?.userShare}
              prefix={'$'}
              precision={2}
            />
          )
        );
      },
    },
    {
      title: 'Balance Amount',
      dataIndex: 'balanceAmount',
      width: '5%',
      render: (_, item: Payout) => {
        return (
          item.metadata?.balanceAmount && (
            <Statistic
              value={item.metadata?.balanceAmount}
              prefix={'$'}
              precision={2}
            />
          )
        );
      },
    },
    {
      title: 'Credit Amount',
      dataIndex: 'creditAmount',
      width: '10%',
      render: (_, item: Payout) => {
        return (
          item.metadata?.creditAmount && (
            <Statistic
              value={item.metadata?.creditAmount}
              prefix={'$'}
              precision={2}
            />
          )
        );
      },
    },
    {
      title: 'User Payout total (after commission)',
      dataIndex: 'userPayoutAfterCommission',
      width: '10%',
      render: (_, item: Payout) => {
        return (
          item.metadata?.userPayoutAfterCommission && (
            <Statistic
              value={item.metadata?.userPayoutAfterCommission}
              prefix={'$'}
              precision={2}
            />
          )
        );
      },
    },
    {
      title: 'Payment Data',
      dataIndex: 'method',
      align: 'center',
      width: '20%',

      render: (_, item) => {
        return (
          <Button
            type={'text'}
            onClick={() => showPaymentData(item)}
            icon={<InfoCircleTwoTone />}
          />
        );
      },
    },
    {
      title: 'Completed at',
      dataIndex: 'completedAt',
      width: '15%',
      render: (_, item) => {
        return (
          <span>
            {item.completedAt
              ? dayjs(item.completedAt).format('DD MMM YYYY')
              : '-'}
          </span>
        );
      },
    },
    {
      title: 'Issued at',
      dataIndex: 'createdAt',
      width: '20%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {dayjs(item.createdAt).format('DD/MM/YY, HH:MM')}
          </TextComponent>
        );
      },
    },
    {
      title: 'reasonToDecline',
      dataIndex: 'reasonToDecline',
      width: '20%',
      render: (_, item) => {
        return <TextComponent>{item.reasonToDecline}</TextComponent>;
      },
    },
    {
      title: 'TransactionId',
      dataIndex: 'transactionId',
      width: '20%',
      render: (_, item) => {
        return <TextComponent>{item.transactionId}</TextComponent>;
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '20%',
      render: (_, item) => {
        return <TextComponent>{item.comment}</TextComponent>;
      },
    },
    {
      title: 'Pay',
      dataIndex: 'pay',
      render: (_, item) => {
        const allowedPayments = [PaymentMethod.Rise, PaymentMethod.Crypto];

        if (
          !allowedPayments.includes(item.paymentMethod) ||
          item.status !== PayoutStatus.Approved
        ) {
          return;
        }

        return (
          <Button
            disabled={!item.metadata?.userPayoutAfterCommission}
            onClick={() => {
              setCurrentPayout(item);
              paymentModalRef?.current.open();
            }}
          >
            Pay
          </Button>
        );
      },
    },
  ];
  const colsToFilter = columnsToFilterMap[type];

  const filteredColumns = columns.filter(
    (col) => !colsToFilter.includes((col as any).dataIndex as string)
  );

  const openCurrencyConverterModal = () => {
    currencyConverterModalRef.current.open();
  };

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async (isEdit?: boolean) => {
    const values = form.getValues();
    values.type = type;

    try {
      if (isEdit) {
        await payoutStore.update(values._id, values);
      } else {
        await payoutStore.create(values);
      }
      message.info(`${isEdit ? 'Update' : 'Create'} payout successfully`);
    } catch (error) {
      message.error(`Failed to ${isEdit ? 'update' : 'create'} payout`);
    }
  };

  const onDelete = async (id: string) => {
    try {
      await payoutStore.delete(id);
      message.info('Delete payout successfully');
    } catch (error) {
      message.info('Failed to delete payout');
    }
  };

  return (
    <>
      <Row align={'bottom'} gutter={[15, 15]}>
        <Col span={6}>
          <span>Filter by email :</span>

          <UserAutocomplete onSelect={(userId) => onSearchChange(userId)} />
        </Col>
        <Col span={6}>
          <span>Filter by by source id :</span>

          <AccountAutocomplete
            onSelect={(accountId) => onAccountSearchClick(accountId)}
          />
        </Col>

        <Col span={3}>
          <span>Filter by status :</span>

          <Select
            style={{ width: '90%' }}
            onSelect={(status) => onSearchStatusChange(status as PayoutStatus)}
            defaultValue={'all'}
            options={searchStatuses}
          />
        </Col>

        <Col span={6}>
          <Flex vertical align={'end'} justify={'center'}>
            <Button
              key="currency-converter-btn"
              onClick={() => openCurrencyConverterModal()}
            >
              Currency Converter
            </Button>
          </Flex>
        </Col>
        <Col span={24}>
          <EntityPageWithDrawer
            ref={entityPageRef}
            getDefaultValues={getDefaultValues}
            form={form}
            validateForm={validateForm}
            drawerBody={<PayoutDrawer form={form} type={type} />}
            onSubmit={submitForm}
            columns={filteredColumns}
            isEditAllowed
            isCreateAllowed
            onDelete={onDelete}
            requestNextPage={requestPage}
            abilities={userStore.currentUser.abilities}
          />
          <CurrencyConverterModal ref={currencyConverterModalRef} />
        </Col>
      </Row>
      <PayoutPaymentModal
        refresh={refresh}
        ref={paymentModalRef}
        payout={currentPayout}
      />
    </>
  );
};

export default Payouts;
