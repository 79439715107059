import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { App, Col, Row } from 'antd';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import {
  FormSelect,
  FormInput,
  TextComponent,
  Statistic,
} from '@monorepo/react-components';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const getDefaultValues = () => ({
  amount: 1,
  currency: 'EUR',
});

const CurrencyConverterModal = forwardRef<FormModalActions>((_, ref) => {
  const { message } = App.useApp();
  const form = useForm<any>({
    defaultValues: getDefaultValues(),
  });
  const [currencies, setCurrencies] = useState<Record<string, number>>({});
  const [conversionOutcome, setConversionOutcome] = useState<number | null>(
    null
  );
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);

  const {
    dataStore: { payoutStore },
  } = useStore();

  const getCurrenciesRates = async () =>
    await payoutStore.fetchCurrenciesRates();

  const debounceFetcher = useMemo(() => {
    const processConversion = async () => {
      try {
        const { amount, currency } = form.getValues();

        const { rates, lastupdate } = await getCurrenciesRates();

        const conversionOutcome = amount * rates[currency];

        setConversionOutcome(conversionOutcome);
        setCurrencies(rates);
        setLastUpdated(dayjs(lastupdate).format('YYYY-MM-DD HH:mm:ss'));
      } catch (e) {
        message.open({
          type: 'error',
          content: 'Something went wrong',
        });
      }
    };

    return debounce(processConversion, 400);
  }, []);

  const amountWatch = form.watch('amount');
  const currencyWatch = form.watch('currency');

  useEffect(() => {
    const validate = async () => {
      await form.trigger();

      if (!form.formState.isValid) {
        debounceFetcher.cancel();
        setConversionOutcome(null);
        return;
      }

      debounceFetcher();
    };

    validate();
  }, [amountWatch, currencyWatch]);

  return (
    <FormModal
      title="Currency Converter"
      ref={ref}
      form={form}
      width={'40%'}
      footer={null}
      getDefaultValues={getDefaultValues}
      onSave={async () => {}}
    >
      <Row gutter={[15, 15]} align={'middle'} justify={'center'}>
        <Col xs={20} lg={10}>
          <FormInput
            text={'Amount (USD)'}
            form={form}
            controllerProps={{
              name: 'amount',
              rules: {
                required: 'Amount is required',
                pattern: {
                  value: /^-?\d+(\.\d+)?$/,
                  message: 'Invalid amount',
                },
              },
            }}
          />
        </Col>

        <Col xs={20} lg={10}>
          <FormSelect
            form={form}
            controllerProps={{
              name: 'currency',
              rules: { required: 'Currency is required' },
            }}
            text={'Convert To'}
            options={Object.keys(currencies).map((currency) => ({
              label: <TextComponent>{currency}</TextComponent>,
              value: currency,
            }))}
            showSearch
          />
        </Col>
      </Row>
      {conversionOutcome && (
        <Row justify={'center'} gutter={[10, 10]}>
          <Col>
            <Statistic value={form.getValues().amount} precision={2} />
          </Col>
          <TextComponent>USD = </TextComponent>
          <Col>
            <Statistic value={conversionOutcome} precision={3} />
          </Col>
          <TextComponent>{form.getValues().currency}</TextComponent>
          <Col>
            <TextComponent type="secondary">
              Last updated at: {lastUpdated ? lastUpdated : 'N/A'}
            </TextComponent>
          </Col>
        </Row>
      )}
    </FormModal>
  );
});

export default CurrencyConverterModal;
