import {
  CreditAction,
  CreditType,
  PaginationRequest,
  User,
  UsersRequestFilters,
} from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import UsersService from '../../../services/entities/users-service';
import { EntityStore } from '@monorepo/client-common';

export default class UsersStore extends EntityStore<UsersService, User> {
  constructor() {
    const {
      apiFactory: { usersService },
    } = getEnv();

    super(usersService);
  }

  fetchById(userId: string) {
    return this.service.fetchById(userId);
  }

  search(query: UsersRequestFilters) {
    return this.service.search(query);
  }

  paginate(paginateRequest: PaginationRequest) {
    return this.service.paginate<User>(paginateRequest);
  }

  resendConfirmation(id: string) {
    return this.service.resendConfirmation(id);
  }

  deleteUserInformation(userId: string) {
    return this.service.deleteUserInformation(userId);
  }

  addCredit(
    userId: string,
    amount: string,
    type: CreditType,
    action: CreditAction,
    notes: string,
    expirationDate: string
  ) {
    return this.service.addCredit(
      userId,
      parseFloat(amount),
      type,
      action,
      notes,
      expirationDate
    );
  }

  updateStatus(id: string, verificationData: Record<string, unknown>) {
    return this.service.updateStatus({ id, verificationData });
  }

  requestKYC(userId: string, email: string) {
    return this.service.requestKYC(userId, email);
  }
}
