import { Program } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import ProgramConsistencyRulesForm from './program-consistency-rules-form';
import { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  program: Program;
  onSave: () => void;
}

const ProgramConsistencyRules: FunctionComponent<Props> = ({
  program,
  onSave,
}) => {
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);
  const items: DescriptionsProps['items'] =
    program.config.consistencyRules?.map(({ rule, value }) => {
      return {
        key: rule,
        label: rule,
        span: 1,
        children: value,
      };
    }) || [];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => programFormRef.current.open(program)}
        >
          {items.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {items.length > 0 && (
          <Descriptions column={2} layout="vertical" bordered items={items} />
        )}
        <ProgramConsistencyRulesForm onSave={onSave} ref={programFormRef} />
      </Col>
    </Row>
  );
};

export default ProgramConsistencyRules;
