import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { Organization } from '@monorepo/types';
import { FormInput } from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';

export const OrganizationDrawer: FunctionComponent<{
  form: UseFormReturn<Organization>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Name"
          text={'Name'}
          form={form}
          controllerProps={{
            name: 'name',
            rules: { required: { value: true, message: 'Name is required' } },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Code"
          text={'Code'}
          form={form}
          controllerProps={{
            name: 'code',
            rules: { required: { value: true, message: 'Code is required' } },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Website"
          text={'Website'}
          form={form}
          controllerProps={{
            name: 'website',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Country"
          text={'Country'}
          form={form}
          controllerProps={{
            name: 'country',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="State"
          text={'State'}
          form={form}
          controllerProps={{
            name: 'state',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Street"
          text={'Street'}
          form={form}
          controllerProps={{
            name: 'street',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Email"
          text={'Email'}
          form={form}
          controllerProps={{
            name: 'email',
            rules: { required: { value: true, message: 'Email is required' } },
          }}
        />
      </Col>
    </Row>
  );
};
