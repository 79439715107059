import { TextComponent } from '@monorepo/react-components';
import { EmailTemplate, NotificationName } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';

import { EntityPageWithDrawer } from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import { EmailTemplateDrawer } from '../email-template-drawer/email-template-drawer';
import { message } from 'antd';

import './email-templates.scss';

const INITIAL_PAGE_LIMIT = 50;

const getTemplateTypeName = (value: string) => {
  for (const key in NotificationName) {
    if (NotificationName[key as keyof typeof NotificationName] === value) {
      return key;
    }
  }
  return value;
};

const getDefaultValues = (
  emailTemplate?: EmailTemplate
): Partial<EmailTemplate> => {
  if (emailTemplate) {
    return emailTemplate;
  }
  return {
    templateName: '',
    description: '',
    subject: '',
  };
};

const EmailTemplates = () => {
  const {
    dataStore: { emailTemplate, userStore },
  } = useStore();

  const form = useForm<EmailTemplate>({
    mode: 'all',
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await emailTemplate.paginate({
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading emails for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<EmailTemplate> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Email Type',
      dataIndex: 'type',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {getTemplateTypeName(item.type)}
          </TextComponent>
        );
      },
    },
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.templateName}</TextComponent>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '15%',
      render: (_, item) => {
        return (
          <TextComponent
            className="email-template-description"
            ellipsis={{ tooltip: item.description }}
          >
            {item.description}
          </TextComponent>
        );
      },
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      width: '10%',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.subject}</TextComponent>;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitEmailTemplateForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      try {
        await emailTemplate.create(form.getValues());
        message.success('Email created successfully');
      } catch (e) {
        if (e instanceof Error) {
          message.error(e.message);
        }
      }
      return;
    }

    try {
      await emailTemplate.update(form.getValues()._id, form.getValues());
      message.success('Email updated successfully');
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<EmailTemplateDrawer form={form} />}
      onSubmit={submitEmailTemplateForm}
      columns={columns}
      isEditAllowed
      isCreateAllowed
      requestNextPage={requestPage}
      initialLimit={INITIAL_PAGE_LIMIT}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default EmailTemplates;
