import { AddOnType, ApplicationConfig } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import AddOnAppConfigForm from '../../../components/add-on-app-config-form/add-on-app-config-form';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormModalActions } from '../../form-modal/form-modal';
import { observer } from 'mobx-react-lite';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
  applicationConfig: ApplicationConfig;
  onSave: (item: ApplicationConfig) => void;
}

const AddOns: FunctionComponent<Props> = ({ applicationConfig, onSave }) => {
  const addOnConfigFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] =
    Object.values(AddOnType).map((type) => {
      const isAddOnEnabled =
        applicationConfig.addOns && applicationConfig.addOns.includes(type);

      return {
        key: type,
        label: type,
        span: 1,
        children: isAddOnEnabled && <CheckOutlined />,
      };
    }) || [];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => addOnConfigFormRef.current.open(applicationConfig)}
        >
          {items.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {items.length > 0 && (
          <Descriptions column={6} layout="vertical" bordered items={items} />
        )}
        <AddOnAppConfigForm onSave={onSave} ref={addOnConfigFormRef} />
      </Col>
    </Row>
  );
};

export default observer(AddOns);
