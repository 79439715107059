import { Organization } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';

interface Props {
  onSelect: (userId: string) => void;
}

const OrganizationAutocomplete: FunctionComponent<Props> = ({ onSelect }) => {
  const {
    dataStore: { organizationStore },
  } = useStore();

  const searchInputChange = async (name: string) => {
    const organizations = (await organizationStore.search({
      name,
    })) as Partial<Organization>[];

    return organizations.map((organization) => ({
      label: organization.name,
      value: organization.code,
    })) as Option[];
  };

  return (
    <Autocomplete
      width="200px"
      onSelect={(option) => {
        onSelect(option.value);
      }}
      onClear={() => onSelect('')}
      searchInputChange={searchInputChange}
      placeholder={'Organization name'}
    />
  );
};

export default OrganizationAutocomplete;
