import { FormInput } from '@monorepo/react-components';
import { AccountType } from '@monorepo/types';
import { Col, Row } from 'antd';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  name: string;
  onSave: (item: any) => void;
}

const TargetEmailsForm = forwardRef<FormModalActions, Props>(
  ({ name, onSave }, ref) => {
    const form = useForm<any>({ mode: 'onChange' });

    return (
      <FormModal
        title={'Update Target Emails'}
        ref={ref}
        form={form}
        width="60%"
        onSave={async (item) => {
          onSave(item);
        }}
      >
        <Row gutter={[25, 5]}>
          {Object.values(AccountType).map((type) => {
            return (
              <Col span={8} key={type}>
                <FormInput
                  form={form}
                  controllerProps={{ name: type }}
                  text={type}
                />
              </Col>
            );
          })}
        </Row>
      </FormModal>
    );
  }
);

export default TargetEmailsForm;
