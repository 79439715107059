import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoggedIn from './routers/logged-in/logged-in';
import LoggedOut from './routers/logged-out/logged-out';
import { useStore } from './helpers/use-store';
import { ConfigProvider, Spin, App as AntdApp } from 'antd';
import { Helmet } from 'react-helmet';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './stores/data/user/user-store';

function App() {
  const [isLoading, setLoading] = useState(true);
  const {
    uiStore,
    dataStore: { userStore, applicationConfigStore },
  } = useStore();

  const isAuthenticated = userStore.isLoggedIn;

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(document.location.search);
      const idTokenParam = params.get('id_token');
      const refreshTokenParam = params.get('refresh_token');

      const token = idTokenParam || localStorage.getItem(TOKEN_KEY);
      const refreshToken =
        refreshTokenParam || localStorage.getItem(REFRESH_TOKEN_KEY);
      if (token && refreshToken) {
        userStore.saveToken(token, refreshToken);
        await userStore
          .login()
          .catch((e) => console.info(`Couldn't refresh token`, e));

        if (idTokenParam && refreshTokenParam) {
          params.delete('id_token');
          params.delete('refresh_token');

          window.history.pushState('', '', params.toString());
        }
      }
      setLoading(false);
    };

    init();
  }, []);

  const getTitle = () => {
    return applicationConfigStore.applicationConfig.name
      ? `${applicationConfigStore.applicationConfig.name}-ADMIN`
      : 'ADMIN';
  };

  return (
    <ConfigProvider theme={uiStore.themeConfigurations[uiStore.currentTheme]}>
      <AntdApp>
        <div className="App">
          <Helmet defaultTitle="HUB">
            <title>{getTitle()}</title>
          </Helmet>
          {isLoading && <Spin size="large" className="loading-spinner" />}
          {!isLoading && isAuthenticated && <LoggedIn />}
          {!isLoading && !isAuthenticated && <LoggedOut />}
        </div>
      </AntdApp>
    </ConfigProvider>
  );
}

export default observer(App);
