import { HttpService } from './http-service';

export interface BaseResponse<T> {
  data: T;
}

interface BaseHttpServiceParams {
  prefix?: string;
  route: string;
  httpService: HttpService;
}

class BaseEntityService {
  static route = 'not route name implemented';

  protected httpService: HttpService;
  private readonly route: string;
  private readonly prefix: string;

  constructor({ route, httpService, prefix }: BaseHttpServiceParams) {
    this.route = route;
    this.prefix = prefix || '';
    this.httpService = httpService;
  }

  protected get path() {
    return `${this.prefix}/${this.route}`;
  }
}

export default BaseEntityService;
