import { EntityStore } from '@monorepo/client-common';
import { AdminUser, PaginationResult } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import AdminUsersService from '../../../services/entities/admin-users-service';
import { observable, runInAction } from 'mobx';

export type AdminUserWithId = AdminUser & { _id: string };

export default class AdminUsersStore extends EntityStore<
  AdminUsersService,
  AdminUser & { _id: string }
> {
  @observable
  listUsers: AdminUserWithId[] = [];

  constructor() {
    const {
      apiFactory: { adminUsersService },
    } = getEnv();

    super(adminUsersService);
  }

  async list(): Promise<PaginationResult<AdminUserWithId>> {
    const result = await this.service.list();

    runInAction(() => {
      this.listUsers = result.results;
    });

    return result;
  }
}
