import { EditOutlined } from '@ant-design/icons';
import { TextComponent, FormDatePicker } from '@monorepo/react-components';
import { Abilities } from '@monorepo/types';
import { App, Button, Space } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import ProtectedElement from '../../components/protected-element/protected-element';
import dayjs from 'dayjs';

type UpdateExpireDatePayload = {
  expireDate: Date;
};

type Props = {
  id: string;
  expireDate: Date;
  onSave: () => void;
};

const AccountExpireDateCell: FunctionComponent<Props> = ({
  id,
  expireDate,
  onSave,
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const { message } = App.useApp();
  const expireDateFormRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<UpdateExpireDatePayload>({ mode: 'onChange' });

  const updateExpireDate = async (item: UpdateExpireDatePayload) => {
    try {
      await accountStore.updateExpireDate(id, item.expireDate);
      onSave();
    } catch (e) {
      message.error('Something went wrong');
      console.error(e);
    }
  };

  const formattedExpireDate = expireDate
    ? dayjs(expireDate).format('DD/MM/YYYY')
    : '-';

  return (
    <ProtectedElement
      abilities={[Abilities.ManageAccounts, Abilities.UpdateAccountExpireDate]}
      notAllowedElement={<TextComponent>{formattedExpireDate}</TextComponent>}
    >
      <div className="account-expire-date">
        <Space>
          <TextComponent ellipsis>{formattedExpireDate}</TextComponent>
          <Button
            size="small"
            onClick={() => expireDateFormRef.current.open()}
            icon={<EditOutlined />}
          />
        </Space>
        <FormModal
          title={'Update account expire date'}
          ref={expireDateFormRef}
          form={form}
          width={'30%'}
          onSave={updateExpireDate}
        >
          <FormDatePicker
            form={form}
            controllerProps={{
              name: 'expireDate',
            }}
            text={'Expire date'}
          />
        </FormModal>
      </div>
    </ProtectedElement>
  );
};

export default AccountExpireDateCell;
