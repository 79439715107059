import { BaseResponse, CrudService } from '@monorepo/client-common';
import {
  Account,
  AccountEvent,
  AccountState,
  AccountsRequestFilters,
  AddProfitableDayPayload,
  UpdateAccountStatus,
} from '@monorepo/types';

export default class AccountsService extends CrudService {
  static override route = 'account';

  async search(filters: AccountsRequestFilters): Promise<Account> {
    const response = await this.httpService.get<BaseResponse<Account>>(
      `${this.path}/search`,
      {
        params: filters,
      }
    );

    return response.data;
  }

  async updateStatus(data: UpdateAccountStatus) {
    const response = await this.httpService.post<
      UpdateAccountStatus,
      { data: string }
    >(`${this.path}/update-status`, data);

    return response.data;
  }

  async impersonate(accountId: string, impersonatedUserId: string) {
    const response = await this.httpService.post<
      { accountId: string; impersonatedUserId: string },
      BaseResponse<string>
    >(`${this.path}/impersonate`, { accountId, impersonatedUserId });

    return response.data;
  }

  async createAccounts(data: any, isParallel: boolean) {
    const response = await this.httpService.post<any, { data: boolean }>(
      `${this.path}/create`,
      { data, isParallel }
    );

    return response.data;
  }

  async createPendingAccount(account: Partial<Account>) {
    const response = await this.httpService.post<any, BaseResponse<any>>(
      `${this.path}/create-pending`,
      account
    );

    return response.data;
  }

  async updateUser(id: string, userId: string) {
    const response = await this.httpService.post<
      { id: string; userId: string },
      BaseResponse<string>
    >(`${this.path}/update-user`, { id, userId });

    return response.data;
  }

  async updateState(id: string, state: AccountState) {
    const response = await this.httpService.post<any, BaseResponse<any>>(
      `${this.path}/update-state`,
      { id, state }
    );
    return response.data;
  }

  async updateExpireDate(id: string, expireDate: Date) {
    const response = await this.httpService.post<any, BaseResponse<any>>(
      `${this.path}/update-expire-date`,
      { id, expireDate }
    );
    return response.data;
  }

  async addProfitableDay(data: AddProfitableDayPayload) {
    const response = await this.httpService.post<
      AddProfitableDayPayload,
      { data: string }
    >(`${this.path}/add-profitable-day`, data);

    return response.data;
  }
  async sync(accountId: string) {
    const response = await this.httpService.post<
      { accountId: string },
      BaseResponse<boolean>
    >(`${this.path}/sync`, { accountId });

    return response.data;
  }

  async getAccountEvents(accountId: string) {
    const response = await this.httpService.get<BaseResponse<AccountEvent[]>>(
      `${this.path}/account-events`,
      {
        params: { accountId },
      }
    );

    return response.data;
  }
}
