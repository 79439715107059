import { wrapRoot } from 'mobx-easy';
import RootStore from '../stores/root-store';
import { HttpService } from '@monorepo/client-common';
import ApiFactory from '../services/api-factory';

export interface Environment {
  hub_url: string;
  api_end_point: string;
  pusher_api_key: string;
  pusher_cluster: string;
  leaddyno_public_key: string;
  aws_region: string;
  aws_client_id: string;
  cognito_pool_name: string;
  cdn_base_url: string;
}

export interface RootEnv {
  envConfig: Environment;
  apiFactory: ApiFactory;
}

export interface CreateStoreResult {
  rootStore: RootStore;
  env: RootEnv;
}

export interface CreateStoreOptions {
  envConfig: Environment;
}

const createStore = ({ envConfig }: CreateStoreOptions): CreateStoreResult => {
  const httpService = new HttpService({ baseURL: envConfig.api_end_point });
  const apiFactory = new ApiFactory({ httpService });

  const env: RootEnv = {
    apiFactory,
    envConfig,
  };

  const rootStore = wrapRoot({ RootStore: RootStore, env });

  return {
    rootStore,
    env,
  };
};

export default createStore;
