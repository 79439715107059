import {
  Flex,
  FormCheckbox,
  FormInput,
  FormInputNumber,
  FormRadioGroup,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import {
  ApplicationConfig,
  CountryFilterType,
  DashboardLayouts,
  TimeZone,
} from '@monorepo/types';
import { Col, Row } from 'antd';
import { noop } from 'lodash';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';
import { getCountries } from '@monorepo/client-common';

interface Props {
  applicationConfig?: ApplicationConfig;
  onSave?: () => void;
}

const ApplicationConfigOverviewForm = forwardRef<FormModalActions, Props>(
  ({ applicationConfig = {}, onSave = noop }, ref) => {
    const {
      dataStore: { applicationConfigStore },
    } = useStore();
    const form = useForm<ApplicationConfig>({ mode: 'onChange' });

    const countryFilterTypeOptions = Object.entries(CountryFilterType).map(
      ([key, value]) => ({
        text: <TextComponent capitalize={true}>{key}</TextComponent>,
        value,
      })
    );
    const countryOptions = getCountries().map(({ text, value }) => ({
      label: <TextComponent capitalize={true}>{text}</TextComponent>,
      value,
    }));
    return (
      <FormModal
        title={`Update ApplicationConfig: ${applicationConfig.name}`}
        ref={ref}
        form={form}
        onSave={async (item: ApplicationConfig) => {
          await applicationConfigStore.update(item._id, item);

          onSave();
        }}
      >
        <Row gutter={[25, 5]}>
          <Col span={6}>
            <FormInput
              form={form}
              text="Name"
              controllerProps={{
                rules: { required: 'Missing Name' },
                name: 'name',
              }}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              form={form}
              controllerProps={{ name: 'timeZone' }}
              text={'Time Zone'}
              options={Object.values(TimeZone).map((layout) => ({
                label: (
                  <TextComponent capitalize={true}>{layout}</TextComponent>
                ),
                value: layout,
              }))}
            />
          </Col>
          <Col span={6}>
            <FormCheckbox
              form={form}
              text="Should Create Demo Account"
              controllerProps={{
                rules: { required: false },
                defaultValue: false,
                name: 'shouldCreateDemoAccount',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Website"
              controllerProps={{
                rules: { required: 'Missing Website' },
                name: 'website',
              }}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              form={form}
              controllerProps={{ name: 'dashboardLayout' }}
              text={'Dashboard Layout'}
              options={Object.values(DashboardLayouts).map((layout) => ({
                label: (
                  <TextComponent capitalize={true}>{layout}</TextComponent>
                ),
                value: layout,
              }))}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Terms And Conditions"
              controllerProps={{
                rules: { required: 'Missing Terms And Conditions' },
                name: 'termsAndConditions',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Discord Link"
              controllerProps={{
                rules: { required: 'Missing Discord Link' },
                name: 'discordLink',
              }}
            />
          </Col>
          <Col span={6}>
            <FormCheckbox
              form={form}
              text="Restrict Discord Access For New Users"
              controllerProps={{
                rules: { required: false },
                defaultValue: false,
                name: 'restrictAccessToDiscord',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Youtube Link"
              controllerProps={{
                name: 'youtubeLink',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Facebook Link"
              controllerProps={{
                name: 'facebookLink',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Twitter Link"
              controllerProps={{
                name: 'twitterLink',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              form={form}
              text="Instagram Link"
              controllerProps={{
                name: 'instagramLink',
              }}
            />
          </Col>
          <Col span={6}>
            <FormInputNumber
              form={form}
              text="User buying power limitation"
              controllerProps={{
                name: 'userBuyingPowerLimitation',
              }}
            />
          </Col>
          <Col span={6}>
            <FormCheckbox
              form={form}
              text="Acheivements"
              controllerProps={{
                rules: { required: false },
                defaultValue: false,
                name: 'achievements',
              }}
            />
          </Col>
          <Col span={6}>
            <FormCheckbox
              form={form}
              text="Group Programs"
              controllerProps={{
                rules: { required: false },
                defaultValue: false,
                name: 'groupPrograms',
              }}
            />
          </Col>
          <Col span={12}>
            <Flex gap={5}>
              <FormRadioGroup
                options={countryFilterTypeOptions}
                form={form}
                text="Countries Filter Type"
                controllerProps={{
                  rules: { required: false },
                  name: 'countryFilter.type',
                }}
              />
              <FormSelect
                mode="multiple"
                options={countryOptions}
                form={form}
                text="Countries To Filter"
                controllerProps={{
                  rules: { required: false },
                  name: 'countryFilter.countries',
                }}
              />
            </Flex>
          </Col>
        </Row>
      </FormModal>
    );
  }
);

export default ApplicationConfigOverviewForm;
