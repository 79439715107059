import {
  AffiliateCommissionStatus,
  AFFILIATION_COMMISSION_LIMIT,
  AffiliationCommission,
  AutomaticStatuses,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { ColumnsType } from 'antd/lib/table';
import {
  CommissionStatusTag,
  EntityPageBaseRef,
  EntityPageWithDrawer,
  FormSelect,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Col, Flex, Modal, Row, Space } from 'antd';
import { AffiliationCommissionDrawer } from '../affiliation-commission-drawer/affiliation-commission-drawer';
import React, { useEffect, useRef, useState } from 'react';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { TableRowSelection } from 'antd/lib/table/interface';
import { LoadingState, useLoading } from '@monorepo/client-common';

type Filters = {
  _id?: string;
  userId?: string;
  email?: string;
};

const getDefaultValues = (
  affiliationCommission?: AffiliationCommission
): Partial<AffiliationCommission> => {
  if (affiliationCommission) {
    return {
      ...affiliationCommission,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      dueAt: dayjs(affiliationCommission.dueAt),
    };
  }

  return {
    amount: 0,
    affiliateEmail: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dueAt: dayjs().add(14, 'day'),
  };
};
export const AffiliationCommissions = () => {
  const form = useForm<AffiliationCommission>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const {
    dataStore: { affiliateCommissionsStore, userStore },
  } = useStore();

  const [filters, setFilters] = useState<Filters>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    console.log(selectedRowKeys);
  }, [selectedRowKeys]);

  const onUserSearchClick = async (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }

    setFilters(filters);
    refresh();
  };

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      if (!filters.userId) {
        return { total: 0, results: [] };
      }

      setCurrentPage(page);
      setSelectedRowKeys([]);

      return await affiliateCommissionsStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading affiliate commissions for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<AffiliationCommission> = [
    {
      title: 'Affiliate Id',
      dataIndex: 'affiliateId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent
            type="secondary"
            copyable={{ text: item.affiliateId }}
          />
        );
      },
    },
    {
      title: 'Commission Id',
      dataIndex: 'externalId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">{item.externalId}</TextComponent>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'affiliateEmail',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">{item.affiliateEmail}</TextComponent>
        );
      },
    },
    {
      title: 'Commission amount',
      dataIndex: 'amount',
      align: 'center',
      render: (_, item) => {
        return <Statistic value={item.amount} precision={2} prefix={'$'} />;
      },
    },
    {
      title: 'Due',
      dataIndex: 'dueAt',
      align: 'center',
      render: (_, item) => {
        return <span>{dayjs(item.dueAt).format('DD/MM/YYYY HH:mm')}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '5%',
      render: (_, item) => {
        return <CommissionStatusTag status={item.status} />;
      },
    },
  ];

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: AffiliationCommission[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setTotalSelected(selectedRows.reduce((acc, curr) => acc + curr.amount, 0));
  };

  const rowSelection: TableRowSelection<AffiliationCommission> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const submitCommissionForm = async (isEdit?: boolean) => {
    const dto = form.getValues();
    dto.dueAt = (form.getValues('dueAt') as unknown as Dayjs).toDate();

    if (!isEdit) {
      await affiliateCommissionsStore.create(dto);
    } else {
      await affiliateCommissionsStore.updateBulkStatus(
        [dto.externalId],
        dto.status,
        currentPage,
        dto.userId
      );
    }
  };

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const handleModalOpen = () => {
    if (selectedRowKeys.length > 0) {
      setModalOpen(true);
    }
  };

  const handleBulkStatusUpdate = async () => {
    try {
      if (!filters.userId || selectedRowKeys.length === 0) {
        return;
      }
      updateLoadingState(LoadingState.Loading);

      const status = form.getValues('status');
      const externalIds = selectedRowKeys.map((key) => key.toString());

      await affiliateCommissionsStore.updateBulkStatus(
        externalIds,
        status,
        currentPage,
        filters.userId
      );
      updateLoadingState(LoadingState.Loaded);
      refresh();
    } catch (e) {
      console.error('Could not update status', e);
      updateLoadingState(LoadingState.Error);
    } finally {
      setModalOpen(false);
    }
  };

  return (
    <Space direction="vertical">
      <Modal
        title={`Change ${selectedRowKeys.length} commissions status`}
        width={'20%'}
        open={modalOpen}
        onOk={handleBulkStatusUpdate}
        onCancel={() => setModalOpen(false)}
        confirmLoading={loadingState === LoadingState.Loading}
      >
        <Row gutter={[15, 15]}>
          <Col span={8}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'status',
              }}
              text={'Status'}
              options={Object.values(AffiliateCommissionStatus)
                .filter((status) => !AutomaticStatuses.includes(status))
                .map((type) => ({
                  label: (
                    <TextComponent capitalize={true}>{type}</TextComponent>
                  ),
                  value: type,
                }))}
            />
          </Col>
        </Row>
      </Modal>
      <div>
        <div>Email:</div>
        <Flex gap={8} align={'center'}>
          <UserAutocomplete onSelect={(userId) => onUserSearchClick(userId)} />
          <Statistic value={totalSelected} prefix={'$'} precision={2} />{' '}
          selected
        </Flex>
      </div>

      <Button
        type={'primary'}
        onClick={handleModalOpen}
        disabled={selectedRowKeys.length === 0}
      >
        {' '}
        {selectedRowKeys.length > 0
          ? `Update ${selectedRowKeys.length} records`
          : 'Update'}
      </Button>
      <EntityPageWithDrawer
        rowKey={'externalId'}
        rowSelection={rowSelection}
        form={form}
        columns={columns as ColumnsType}
        isEditAllowed
        isCreateAllowed
        showSizeChanger={false}
        initialLimit={AFFILIATION_COMMISSION_LIMIT}
        requestNextPage={requestPage}
        ref={entityPageRef}
        onSubmit={submitCommissionForm}
        validateForm={validateForm}
        getDefaultValues={getDefaultValues}
        drawerBody={<AffiliationCommissionDrawer form={form} />}
        abilities={userStore.currentUser.abilities}
      />
    </Space>
  );
};
