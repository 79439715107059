import { BaseResponse, CrudService } from '@monorepo/client-common';
import {
  ConsistentRulesConfig,
  CreditEvents,
  PartnerType,
  Program,
  ProgramsRequestFilters,
  ProgramTradingSystemConfig,
  WithdrawRule,
} from '@monorepo/types';

export default class ProgramService extends CrudService {
  static override route = 'program';

  async search(filters: ProgramsRequestFilters): Promise<Program[]> {
    const response = await this.httpService.get<BaseResponse<Program[]>>(
      `${this.path}/search`,
      {
        params: filters,
      }
    );

    return response.data;
  }

  async updatePurchaseLimit(data: {
    id: string;
    amount: number;
    includeDisabledAccounts?: boolean;
  }) {
    const response = await this.httpService.post<
      { id: string; amount: number },
      { data: string }
    >(`${this.path}/update-purchase-limit`, data);

    return response.data;
  }

  async updateBoosters(data: { id: string; boosters: Record<string, number> }) {
    const response = await this.httpService.post<
      { id: string; boosters: Record<PartnerType, number> },
      { data: string }
    >(`${this.path}/update-boosters`, data);

    return response.data;
  }

  async updateConsistencyRules(data: {
    id: string;
    consistencyRules: ConsistentRulesConfig[];
  }) {
    const response = await this.httpService.post<
      { id: string; consistencyRules: ConsistentRulesConfig[] },
      { data: string }
    >(`${this.path}/update-consistency-rules`, data);

    return response.data;
  }

  async updateWithdrawRules(data: {
    id: string;
    withdrawRules: WithdrawRule[];
  }) {
    const response = await this.httpService.post<
      { id: string; withdrawRules: WithdrawRule[] },
      { data: string }
    >(`${this.path}/update-withdraw-rules`, data);

    return response.data;
  }

  async duplicate(data: { id: string }) {
    const response = await this.httpService.post<
      { id: string },
      { data: string }
    >(`${this.path}/duplicate`, data);

    return response.data;
  }

  async updateCreditEvents(data: { id: string; creditEvents: CreditEvents }) {
    const response = await this.httpService.post<
      { id: string; creditEvents: CreditEvents },
      { data: string }
    >(`${this.path}/update-credit-events`, data);

    return response.data;
  }

  async updateTradingSystems(data: {
    id: string;
    tradingSystems: ProgramTradingSystemConfig[];
  }) {
    const response = await this.httpService.post<
      { id: string; tradingSystems: ProgramTradingSystemConfig[] },
      { data: string }
    >(`${this.path}/update-trading-systems`, data);

    return response.data;
  }
}
