import {
  FormInput,
  FormInputNumber,
  FormSelect,
} from '@monorepo/react-components';
import { Pages, SidebarItem } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type SidebarItemForm = SidebarItem & { order: number; _id: string };

const SidebarDrawer: FunctionComponent<{
  form: UseFormReturn<SidebarItemForm>;
}> = ({ form }) => {
  const componentOptions = Object.values(Pages).map((page) => ({
    value: page,
    label: page,
  }));

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          placeholder="overview"
          text={'Page'}
          form={form}
          options={componentOptions}
          controllerProps={{
            name: 'page',
            rules: {
              required: { value: true, message: 'page is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Overview"
          text={'Label'}
          form={form}
          controllerProps={{
            name: 'label',
            rules: {
              required: { value: true, message: 'label is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInputNumber
          placeholder="1"
          text={'Order'}
          form={form}
          controllerProps={{
            name: 'order',
            rules: {
              required: { value: true, message: 'order is required' },
              min: { value: 1, message: 'order must be greater than 0' },
            },
          }}
        />
      </Col>
    </Row>
  );
};

export default SidebarDrawer;
