import { BaseResponse, CrudService } from '@monorepo/client-common';
import { Order, OrdersRequestFilters, PaymentData } from '@monorepo/types';

export default class OrdersService extends CrudService {
  static override route = 'order';

  async updateStatus(data: {
    id: string;
    status: string;
    disableAccount?: boolean;
  }) {
    const response = await this.httpService.post<
      { id: string; status: string },
      { data: string }
    >(`${this.path}/update-status`, data);

    return response.data;
  }

  async updatePaymentData(data: { uniqId: string; paymentData: PaymentData }) {
    const response = await this.httpService.post<
      { uniqId: string; paymentData: PaymentData },
      { data: string }
    >(`${this.path}/update-payment-data`, data);

    return response.data;
  }

  async searchByOrderUniqId(filters: OrdersRequestFilters): Promise<Order> {
    const response = await this.httpService.get<BaseResponse<Order>>(
      `${this.path}/search-unique-id`,
      {
        params: filters,
      }
    );

    return response.data;
  }
}
