import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { ColumnsType } from 'antd/es/table';
import { ApplicationTradingSystem, TradingSystem } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import { ApplicationTradingSystemDrawer } from './application-trading-system-drawer';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

const getDefaultValues = (
  applicationTradingSystem?: ApplicationTradingSystem
): Partial<ApplicationTradingSystem> => {
  if (applicationTradingSystem) {
    return applicationTradingSystem;
  }
  return {
    sellable: false,
    tradingSystem: '' as TradingSystem,
  };
};

interface Props {
  tradingSystems: ApplicationTradingSystem[];
  onSave: (tradingSystems: ApplicationTradingSystem[]) => void;
}

const ApplicationTradingSystems: FunctionComponent<Props> = ({
  tradingSystems,
  onSave,
}) => {
  const form = useForm<ApplicationTradingSystem>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const requestPage = async () => {
    return {
      total: tradingSystems.length,
      results: tradingSystems.map((applicationTradingSystem) => ({
        _id: applicationTradingSystem.tradingSystem,
        ...applicationTradingSystem,
      })),
    };
  };

  const columns: ColumnsType<ApplicationTradingSystem> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.tradingSystem}</TextComponent>;
      },
    },
    {
      title: 'Sellable',
      dataIndex: 'sellable',
      width: '15%',
      render: (_, item) => {
        return item.sellable ? <CheckOutlined /> : <CloseOutlined />;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async () => {
    const applicationTradingSystem = form.getValues();

    const index = tradingSystems.findIndex(
      (item) => item.tradingSystem === applicationTradingSystem.tradingSystem
    );

    if (index === -1) {
      tradingSystems.push(applicationTradingSystem);
    } else {
      tradingSystems[index] = applicationTradingSystem;
    }

    onSave(tradingSystems);
  };

  const onDelete = async (tradingSystem: string) => {
    const index = tradingSystems.findIndex(
      (item) => item.tradingSystem === tradingSystem
    );

    if (index !== -1) {
      tradingSystems.splice(index, 1);
      onSave(tradingSystems);
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<ApplicationTradingSystemDrawer form={form} />}
      onSubmit={submitForm}
      columns={columns}
      onDelete={onDelete}
      isCreateAllowed
      isEditAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default observer(ApplicationTradingSystems);
