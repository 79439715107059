import PartnerStore from './partner/partner-store';
import UserStore from './user/user-store';
import DiscountsStore from './discounts/discounts-store';
import PayoutStore from './payouts/payout-store';
import CreditStore from './credits/credit-store';
import ProgramStore from './program/program-store';
import AddOnStore from './add-on/add-on-store';
import OrderStore from './orders/order-store';
import RolesAbilitiesStore from './roles-abilities/roles-abilities-store';
import AccountStore from './accounts/account-store';
import EmailTemplateStore from './email-templates/email-template-store';
import PaymentProviderStore from './payment-providers/payment-providers-store';
import UsersStore from './users/users-store';
import AdminUsersStore from './admin-users/admin-users-store';
import ApplicationConfigStore from './application-config/application-config-store';
import TradingSystemStore from './trading-system/trading-system-store';
import { GroupStore } from './group/group-store';
import AffiliateCommissionsStore from './affiliation-commissions/affiliate-commissions-store';
import CouponStore from './coupons/coupon-store';
import OrganizationStore from './organization/organization-store';

class DataStore {
  userStore: UserStore;
  couponStore: CouponStore;
  organizationStore: OrganizationStore;
  usersStore: UsersStore;
  adminUsersStore: AdminUsersStore;
  discountsStore: DiscountsStore;
  payoutStore: PayoutStore;
  creditStore: CreditStore;
  partnerStore: PartnerStore;
  programStore: ProgramStore;
  groupStore: GroupStore;
  addOnStore: AddOnStore;
  orderStore: OrderStore;
  rolesAbilitiesStore: RolesAbilitiesStore;
  accountStore: AccountStore;
  emailTemplate: EmailTemplateStore;
  paymentProvider: PaymentProviderStore;
  applicationConfigStore: ApplicationConfigStore;
  tradingSystemStore: TradingSystemStore;
  affiliateCommissionsStore: AffiliateCommissionsStore;
  constructor() {
    this.userStore = new UserStore();
    this.usersStore = new UsersStore();
    this.adminUsersStore = new AdminUsersStore();
    this.discountsStore = new DiscountsStore();
    this.payoutStore = new PayoutStore();
    this.creditStore = new CreditStore();
    this.paymentProvider = new PaymentProviderStore();
    this.orderStore = new OrderStore();
    this.rolesAbilitiesStore = new RolesAbilitiesStore();
    this.partnerStore = new PartnerStore();
    this.programStore = new ProgramStore();
    this.groupStore = new GroupStore();
    this.addOnStore = new AddOnStore();
    this.accountStore = new AccountStore();
    this.emailTemplate = new EmailTemplateStore();
    this.applicationConfigStore = new ApplicationConfigStore();
    this.tradingSystemStore = new TradingSystemStore();
    this.affiliateCommissionsStore = new AffiliateCommissionsStore();
    this.couponStore = new CouponStore();
    this.organizationStore = new OrganizationStore();
  }
}

export default DataStore;
