import ttpLogo from './ttp-logo.svg';
import the5ersLogo from './5ers-logo.svg';
import { FunctionComponent } from 'react';
import './logo.scss';
import { Application } from '@monorepo/types';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const Logo: FunctionComponent = () => {
  const logoMap: { [key: string]: string } = {
    [Application.TTP]: ttpLogo,
    [Application.THE5ERS]: the5ersLogo,
  };

  return <img className="logo" src={logoMap[project]} alt="Logo" />;
};

export default Logo;
