import { useStore } from '../../helpers/use-store';
import { Abilities, AddOn } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  EntityPageBase,
  EntityPageBaseRef,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { FormModalActions } from '../form-modal/form-modal';
import { AddOnOverviewForm } from './add-on-overview/add-on-overview-form';
import AddOnAutocomplete from '../../components/add-on-autocomplete/add-on-autocomplete';
import ProtectedElement from '../../components/protected-element/protected-element';
import { AddOnWithProgramName } from '../../stores/data/add-on/add-on-store';

type Filters = {
  _id?: string[];
};

const AddOns = () => {
  const addOnFormRef = useRef<FormModalActions>({} as FormModalActions);
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);

  const {
    dataStore: { addOnStore, userStore },
  } = useStore();

  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({});

  const requestPage = async ({
    filters,
    page,
    limit,
  }: {
    filters?: any;
    page: number;
    limit: number;
  }) => {
    try {
      const res = await addOnStore.paginate({
        filters,
        page,
        limit,
      });
      return res;
    } catch (e) {
      console.error(
        `Failed loading add ons for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<AddOnWithProgramName> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (_, item) => {
        return <Statistic value={item.price} prefix="$" />;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.type}</TextComponent>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.description}</TextComponent>
        );
      },
    },
    {
      title: 'Config Type',
      dataIndex: 'config-type',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.config?.type}</TextComponent>
        );
      },
    },
    {
      title: 'Renew Days',
      dataIndex: 'description',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {item.config?.renewLength}
          </TextComponent>
        );
      },
    },
    {
      title: 'Program name',
      dataIndex: 'program-name',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {item?.programNames?.join(', ')}
          </TextComponent>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      align: 'center',
      render: () => {
        return <ArrowRightOutlined />;
      },
    },
  ];

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onCreateClick = () => {
    addOnFormRef.current.open();
  };

  const onSearchClick = async (item: { label: string; value: string }) => {
    const { label, value } = item;
    if (label === value) {
      const addOns = (await addOnStore.search({
        name: value,
      })) as Partial<AddOn>[];

      const ids = addOns.map((addOn) => addOn._id);
      filters._id = ids as string[];
    } else {
      filters._id = [value];
    }

    setFilters(filters);
    refresh();
  };

  const onClearClick = async () => {
    delete filters._id;
    setFilters(filters);
    refresh();
  };

  return (
    <>
      <Row align={'bottom'}>
        <Col span={12}>
          <div>Filter by add on name:</div>
          <AddOnAutocomplete onSelect={onSearchClick} onClear={onClearClick} />
        </Col>

        <ProtectedElement abilities={[Abilities.ManageAddOns]}>
          <Col span={12}>
            <Flex justify={'end'}>
              <Button key="create-btn" onClick={onCreateClick}>
                Create
              </Button>
            </Flex>
          </Col>
        </ProtectedElement>

        <AddOnOverviewForm
          onSave={() => entityPageRef.current.reFetch()}
          ref={addOnFormRef}
        />
      </Row>

      <EntityPageBase
        initialLimit={50}
        ref={entityPageRef}
        onRowClick={(addOn: AddOn) => navigate(`/add-ons/${addOn._id}`)}
        columns={columns}
        requestNextPage={requestPage}
        filters={filters}
        abilities={userStore.currentUser.abilities}
      />
    </>
  );
};

export default AddOns;
