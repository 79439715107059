import { FormSelect } from '@monorepo/react-components';
import { AdminNotification } from '@monorepo/types';
import { Col, Row, message } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';

enum AdminNotificationName {
  AdminAccountPayout = 'admin-account-payout',
  AdminAffiliatePayout = 'admin-affiliate-payout',
}

export const AdminNotificationDrawer: FunctionComponent<{
  form: UseFormReturn<AdminNotification>;
}> = ({ form }) => {
  const {
    dataStore: { adminUsersStore },
  } = useStore();

  const [recipientOptions, setRecipientOptions] = useState([
    { label: '', value: '' },
  ]);

  const nameOptions = Object.entries(AdminNotificationName).map(
    ([key, value]) => ({
      label: key,
      value,
    })
  );

  const fetchAdmins = async () => {
    try {
      const admins = await adminUsersStore.list();

      const recipientsOptions = admins.results.map((admin) => ({
        label: admin.email,
        value: admin.email,
      }));

      setRecipientOptions(recipientsOptions);
    } catch (e) {
      console.error(e);
      message.error('Failed to fetch recipients');
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'name',
            rules: {
              required: {
                value: true,
                message: 'Notification name is required',
              },
            },
          }}
          text={'Notification Name'}
          options={nameOptions}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          mode={'multiple'}
          form={form}
          options={recipientOptions}
          controllerProps={{
            name: 'recipients',
            rules: {
              required: { value: true, message: 'Recipients is required' },
            },
          }}
          text={'Recipients'}
        />
      </Col>
    </Row>
  );
};
