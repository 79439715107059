import { AddOn } from '@monorepo/types';
import { useForm } from 'react-hook-form';
import React, { forwardRef } from 'react';
import { Col, Row } from 'antd';
import { FormInputNumber } from '@monorepo/react-components';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';
import { useStore } from '../../../helpers/use-store';
import { noop } from 'lodash';

interface Props {
  addOn: AddOn;
  onSave?: () => void;
}

export const AddOnConfigForm = forwardRef<FormModalActions, Props>(
  ({ addOn, onSave = noop }, ref) => {
    const {
      dataStore: { addOnStore },
    } = useStore();

    const form = useForm<AddOn>({ mode: 'onChange' });

    const addOnConfigFormItems = {
      renew: (
        <Col span={6}>
          <FormInputNumber
            form={form}
            text="Renew Days"
            placeholder={'30'}
            controllerProps={{
              rules: {
                required: true,
              },
              name: 'config.renewLength',
            }}
          />
        </Col>
      ),
    };

    const items =
      addOnConfigFormItems[addOn.type as keyof typeof addOnConfigFormItems];

    return (
      <FormModal
        title={`Update add on config: ${addOn.name}`}
        ref={ref}
        form={form}
        onSave={async (item: AddOn) => {
          item.type = addOn.type;

          await addOnStore.update(item._id, item);

          onSave();
        }}
      >
        <Row gutter={[25, 5]}>{items}</Row>
      </FormModal>
    );
  }
);

export default AddOnConfigForm;
