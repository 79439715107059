import { TradingSystem } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export default class TradingSystemStore {
  async syncClosedPositions(payload: {
    startDate: string;
    endDate: string;
    tradingSystem: TradingSystem;
  }): Promise<boolean> {
    const {
      apiFactory: { tradingSystemService },
    } = getEnv();

    return tradingSystemService.syncClosedPositions(payload);
  }

  async deletePositionByExternalId(
    tradingSystem: TradingSystem,
    externalId: string
  ) {
    try {
      const {
        apiFactory: { tradingSystemService },
      } = getEnv();

      return await tradingSystemService.deletePositionByExternalId(
        tradingSystem,
        externalId
      );
    } catch (e) {
      console.error(
        `Failed delete position ${externalId} for ${tradingSystem}`,
        e
      );
      throw e;
    }
  }
}
