import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';
import { Account } from '@monorepo/types';

interface Props {
  onSelect: (accountId: string) => void;
}

const AccountAutocomplete: FunctionComponent<Props> = ({ onSelect }) => {
  const {
    dataStore: { accountStore },
  } = useStore();

  const searchInputChange = async (tsAccount: string) => {
    const accounts = (await accountStore.search({
      tsAccount,
    })) as unknown as Account[];

    return accounts.map((account) => ({
      label: account.tsAccount,
      value: account._id,
    })) as Option[];
  };

  return (
    <Autocomplete
      width="200px"
      onSelect={(account) => onSelect(account.value)}
      onClear={() => onSelect('')}
      searchInputChange={searchInputChange}
      placeholder={'10000'}
    />
  );
};

export default AccountAutocomplete;
