import {
  AddOnType,
  AdminNotification as AdminNotificationType,
  ApplicationConfig,
  ApplicationContract,
  ApplicationScheduledJob,
  ApplicationTradingSystem,
  SidebarItem,
  TargetEmails as TargetEmailsType,
} from '@monorepo/types';
import { Col, Row, Tabs, Typography, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../helpers/use-store';
import EmailTemplates from '../email-templates/email-templates';
import PaymentProviders from '../payment-providers/payment-providers';
import ApplicationConfigOverview from './application-config-overview/application-config-overview';
import ApplicationConfigScheduledJobs from './application-config-scheduled-jobs/application-config-scheduled-jobs';
import ApplicationConfigWithdrawRules from './application-config-withdraw-rules/application-config-withdraw-rules';
import TargetEmails from './target-emails/target-emails';
import AddOns from './application-config-add-ons/application-config-add-ons';
import ApplicationConfigAffiliateWithdrawRules from './application-config-affiliatie-withdraw-rules/application-config-affiliate-withdraw-rules';
import Sidebar from './application-config-sidebar/application-config-sidebar';
import Contracts from './application-config-contracts/application-config-contracts';
import ApplicationTradingSystems from './application-trading-systems/application-trading-systems';
import AdminNotification from './application-config-admin-notification/application-config-admin-notification';
import Banners from './application-config-banners/application-config-banners';

const ApplicationConfigComponent = () => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();

  const { applicationConfig } = applicationConfigStore;

  const updateAccountWithdrawRules = async (item: ApplicationConfig) => {
    await applicationConfigStore.updateAccountWithdrawRules(
      item._id,
      item.accountWithdrawRules
    );

    message.success('Account Withdraw rules updated');
  };

  const updateAffiliateWithdrawRules = async (item: ApplicationConfig) => {
    await applicationConfigStore.updateAffiliateWithdrawRules(
      item._id,
      item.affiliateWithdrawRules
    );

    message.success('Affiliation Withdraw rules updated');
  };

  const updateScheduledJobs = async (
    scheduledJobs: ApplicationScheduledJob[]
  ) => {
    await applicationConfigStore.updateScheduledJobs(
      applicationConfig._id,
      scheduledJobs
    );

    message.success('Scheduled jobs updated');
  };

  const runJob = async (job: ApplicationScheduledJob) => {
    await applicationConfigStore.runJob(job);
    message.success('Successfully executed Job');
  };

  const updateTradingSystems = async (
    tradingSystems: ApplicationTradingSystem[]
  ) => {
    try {
      await applicationConfigStore.updateTradingSystems(
        applicationConfig._id,
        tradingSystems
      );

      message.success('Trading Systems updated');
    } catch (e) {
      console.error(`failed updating application trading systems`, e);
      message.error(`failed updating application trading systems`);
    }
  };

  const updateTargetEmails = async (targetEmails: TargetEmailsType) => {
    await applicationConfigStore.updateTargetEmails(
      applicationConfig._id,
      targetEmails
    );

    message.success('Target emails updated');
  };

  const updateAddOns = async (item: ApplicationConfig) => {
    await applicationConfigStore.updateAddOns(
      item._id,
      item.addOns as AddOnType[]
    );

    message.success('Add on updated');
  };

  const updateSidebar = async (sidebar: SidebarItem[]) => {
    try {
      await applicationConfigStore.updateSidebar(
        applicationConfig._id,
        sidebar
      );
      message.success('Sidebar updated');
    } catch (e) {
      message.error('Failed to update sidebar');
      console.error(e);
    }
  };

  const updateContracts = async (contracts: ApplicationContract[]) => {
    try {
      await applicationConfigStore.updateContracts(
        applicationConfig._id,
        contracts
      );
      message.success('Contracts updated');
    } catch (error) {
      message.error('Failed to update contracts');
      console.error(error);
    }
  };

  const updateAdminNotification = async (
    adminNotification: AdminNotificationType[]
  ) => {
    try {
      await applicationConfigStore.updateAdminNotification(
        applicationConfig._id,
        adminNotification
      );
      message.success('Admin Notification updated');
    } catch (e) {
      message.error('Failed to update Admin Notification');
      console.error(e);
    }
  };

  const updateBanners = async (formData: FormData) => {
    try {
      formData.append('id', applicationConfig._id);

      await applicationConfigStore.updateBanners(formData);
      message.success('Banners updated');
    } catch (e) {
      message.error('Failed to update Banners');
      console.error(e);
    }
  };

  return (
    <Row gutter={[15, 15]} className="admin-applicationConfig">
      <Col span={12}>
        <Typography.Title style={{ marginBottom: '15px' }} level={4}>
          {applicationConfig.name}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs
          style={{ height: '80vh' }}
          tabPosition="left"
          items={[
            {
              label: 'Overview',
              key: 'overview',
              children: (
                <ApplicationConfigOverview
                  applicationConfig={applicationConfig}
                  onSave={() => message.success('application config updated')}
                />
              ),
            },
            {
              label: 'Withdraw Rules',
              key: 'withdraw-rules',
              children: (
                <ApplicationConfigWithdrawRules
                  applicationConfig={applicationConfig}
                  onSave={updateAccountWithdrawRules}
                />
              ),
            },
            {
              label: 'Affiliate Withdraw Rules',
              key: 'affiliate-withdraw-rules',
              children: (
                <ApplicationConfigAffiliateWithdrawRules
                  applicationConfig={applicationConfig}
                  onSave={updateAffiliateWithdrawRules}
                />
              ),
            },
            {
              label: 'Email Templates',
              key: 'email-templates',
              children: <EmailTemplates />,
            },
            {
              label: 'Scheduled Jobs',
              key: 'scheduled-jobs',
              children: (
                <ApplicationConfigScheduledJobs
                  scheduledJobs={applicationConfig.scheduledJobs}
                  onSave={updateScheduledJobs}
                  onRunJob={runJob}
                />
              ),
            },
            {
              label: 'Payment Providers',
              key: 'payment-providers',
              children: <PaymentProviders />,
            },
            {
              label: 'Target Emails',
              key: 'traget-emails',
              children: (
                <TargetEmails
                  targetEmails={applicationConfig.targetEmails}
                  onSave={updateTargetEmails}
                />
              ),
            },
            {
              label: 'Add Ons',
              key: 'add-ons',
              children: (
                <AddOns
                  applicationConfig={applicationConfig}
                  onSave={updateAddOns}
                />
              ),
            },
            {
              label: 'Sidebar',
              key: 'sidebar',
              children: (
                <Sidebar
                  sidebar={applicationConfig.sidebar}
                  onSave={updateSidebar}
                />
              ),
            },
            {
              label: 'Contracts',
              key: 'contracts',
              children: (
                <Contracts
                  contracts={applicationConfig.contracts || []}
                  onSave={updateContracts}
                />
              ),
            },
            {
              label: 'Trading Systems',
              key: 'trading-systems',
              children: (
                <ApplicationTradingSystems
                  tradingSystems={applicationConfig.tradingSystems || []}
                  onSave={updateTradingSystems}
                />
              ),
            },
            {
              label: 'Admin Notification',
              key: 'admin-notification',
              children: (
                <AdminNotification
                  adminNotifications={applicationConfig.adminNotification || []}
                  onSave={updateAdminNotification}
                />
              ),
            },
            {
              label: 'Banners',
              key: 'banners',
              children: (
                <Banners
                  banners={applicationConfig.banners || []}
                  onSave={updateBanners}
                />
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default observer(ApplicationConfigComponent);
