import {
  CloseOutlined,
  DollarOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { App, Button, Dropdown, message, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { FunctionComponent, useRef } from 'react';
import { useStore } from '../../helpers/use-store';
import AddCreditModal from '../credits/add-credit-modal';
import { FormModalActions } from '../form-modal/form-modal';
import {
  Abilities,
  CreditAction,
  CreditType,
  User,
  UserVerificationStatus,
} from '@monorepo/types';

const UserActions: FunctionComponent<{
  user: User;
  onActionSuccess: () => void;
}> = ({ user, onActionSuccess }) => {
  const {
    dataStore: {
      userStore: { isUserAllowed, currentUser },
      usersStore,
    },
  } = useStore();
  const {
    modal: { confirm },
  } = App.useApp();

  const { _id: id, userId, email, verificationData } = user;

  const addCreditModalRef = useRef<FormModalActions>({} as FormModalActions);

  const items: MenuProps['items'] = [];

  if (isUserAllowed(currentUser, [Abilities.AddCredits])) {
    items.push({
      key: 'add-credit',
      label: 'Add credit',
      onClick: () =>
        addCreditModalRef.current?.open({
          userId,
          amount: '',
          notes: '',
          type: CreditType.Payment,
          action: CreditAction.Add,
        }),
      icon: <DollarOutlined />,
    });
  }

  if (
    verificationData?.status !== UserVerificationStatus.Approved &&
    isUserAllowed(currentUser, [Abilities.RequestKYC])
  ) {
    items.push({
      key: 'request-kyc',
      label: 'Request KYC',
      onClick: async () => {
        confirm({
          title: 'Do you want to send kyc email?',
          icon: <ExclamationCircleFilled />,
          content: `For email: ${email}`,
          async onOk() {
            await usersStore.requestKYC(userId, email);

            message.info('Request KYC email sent');
          },
        });
      },
      icon: <UserOutlined />,
    });
  }

  if (isUserAllowed(currentUser, [Abilities.ResendUserConfirmation])) {
    items.push({
      key: 'resend-confirmation',
      label: 'Resend confirmation',
      onClick: async () => {
        confirm({
          title: 'Do you want to resend confirmation email?',
          icon: <ExclamationCircleFilled />,
          content: `For email: ${email}`,
          async onOk() {
            await usersStore.resendConfirmation(id);

            message.info('Resend confirmation email sent');
          },
        });
      },
      icon: <SendOutlined />,
    });
  }
  if (isUserAllowed(currentUser, [Abilities.ManageUsers]) && !user.deleted) {
    items.push({
      key: 'delete-user',
      label: 'Delete user',
      onClick: async () => {
        confirm({
          title: 'Do you want to delete user information?',
          icon: <ExclamationCircleFilled />,
          content: `For email: ${email}`,
          async onOk() {
            await usersStore.deleteUserInformation(userId);
            await onActionSuccess();
            message.info(`User ${email} was deleted successfully`);
          },
        });
      },
      icon: <CloseOutlined />,
    });
  }

  return (
    <div className="user-actions">
      <Dropdown menu={{ items }}>
        <Button type="default" size="small">
          <Space>
            <MoreOutlined />
          </Space>
        </Button>
      </Dropdown>
      <AddCreditModal
        ref={addCreditModalRef}
        onSave={() => message.success('Credit added')}
      />
    </div>
  );
};

export default UserActions;
