import { EntityStore } from '@monorepo/client-common';
import {
  Account,
  AccountState,
  AccountsRequestFilters,
  UpdateAccountStatus,
  PaginationRequest,
  AddProfitableDayPayload,
} from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import AccountService from '../../../services/entities/accounts-service';

export default class AccountStore extends EntityStore<AccountService, Account> {
  constructor() {
    const {
      apiFactory: { accountsService },
    } = getEnv();

    super(accountsService);
  }

  search(query: AccountsRequestFilters) {
    return this.service.search(query);
  }

  paginate(paginateRequest: PaginationRequest) {
    return this.service.paginate<Account>(paginateRequest);
  }

  updateStatus(data: UpdateAccountStatus) {
    return this.service.updateStatus(data);
  }

  impersonate(accountId: string, accountUserId: string) {
    return this.service.impersonate(accountId, accountUserId);
  }

  createAccounts(createAccounts: any, isParallel: boolean) {
    return this.service.createAccounts(createAccounts, isParallel);
  }

  createPendingAccount(account: Partial<Account>) {
    return this.service.createPendingAccount(account);
  }

  updateUser(id: string, userId: string) {
    return this.service.updateUser(id, userId);
  }

  updateState(id: string, state: AccountState) {
    return this.service.updateState(id, state);
  }

  updateExpireDate(id: string, expireDate: Date) {
    return this.service.updateExpireDate(id, expireDate);
  }

  addProfitableDay(data: AddProfitableDayPayload) {
    return this.service.addProfitableDay(data);
  }
  sync(accountId: string) {
    return this.service.sync(accountId);
  }

  getAccountEvents(accountId: string) {
    return this.service.getAccountEvents(accountId);
  }
}
