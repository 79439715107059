import React, { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

export const FormDatePicker: FunctionComponent<
  Omit<FormFieldProps, 'render'> &
    DatePickerProps & { showTime?: object | boolean }
> = (formFieldProps) => {
  const fieldProps: DatePickerProps = {
    showTime: formFieldProps.showTime,
    defaultValue: formFieldProps.defaultValue,
    disabledDate: formFieldProps.disabledDate,
    size: formFieldProps.size,
    placeholder: formFieldProps.placeholder,
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        field.value =
          typeof field.value === 'string' ? dayjs(field.value) : field.value;

        return (
          <DatePicker
            className="form-field__date-picker"
            {...fieldProps}
            {...field}
          />
        );
      }}
    />
  );
};
