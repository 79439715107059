import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import {
  EntityPageBase,
  EntityPageBaseRef,
  Select,
  Statistic,
  TextComponent,
  UserStatusTag,
} from '@monorepo/react-components';
import {
  Abilities,
  Account,
  AccountState,
  AccountStateKey,
  AccountStatus,
  AccountType,
  BaseIdentifiers,
  Contract,
  ContractStatus,
  PaginationSort,
  Role,
  SortDirection,
  User,
} from '@monorepo/types';
import {
  App,
  Button,
  Col,
  Dropdown,
  Flex,
  MenuProps,
  message,
  Row,
  Tooltip,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnsType } from 'antd/es/table';
import { Checkbox } from 'antd/lib';
import { useRef, useState } from 'react';
import ProtectedElement from '../../components/protected-element/protected-element';
import { useStore } from '../../helpers/use-store';
import AccountStatusCell from './account-status';
import UploadAccounts from './upload-accounts/upload-accounts';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import AccountAutocomplete from '../../components/account-autocomplete/account-autocomplete';
import OrderAutocomplete from '../../components/order-autocomplete/order-autocomplete';
import AccountExpireDateCell from './account-expire-date';
import AccountStateCell from './account-state';
import AccountActions from './accounts-actions';
import AccountUser from './account-user';
import dayjs from 'dayjs';
import { useSort } from '@monorepo/client-common';

export type AccountAdmin = Account & {
  programName: string;
  user: User;
  contract: Contract & BaseIdentifiers;
  balance: string;
};

type Filters = {
  _id?: string;
  userId?: string;
  status?: AccountStatus;
  type?: AccountType;
  state?: Partial<AccountState>;
  orderId?: string;
};

const Accounts = () => {
  const {
    dataStore: { accountStore, userStore },
  } = useStore();
  const [filters, setFilters] = useState<Filters>({});
  const { sort, onSortChange } = useSort<Account>({
    sortDirection: SortDirection.Desc,
    sortField: 'updatedAt',
  });
  const { modal } = App.useApp();

  const statusSelectOptions = Object.values(AccountStatus).map((status) => ({
    label: status,
    value: status,
  }));
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);

  const requestPage = async ({
    filters,
    page,
    limit,
    sort,
  }: {
    filters?: any;
    page: number;
    limit: number;
    sort?: PaginationSort;
  }) => {
    try {
      return await accountStore.paginate({
        filters,
        page,
        limit,
        sort,
      });
    } catch (e) {
      console.error(
        `Failed loading accounts for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const refresh = () => {
    entityPageRef.current.reFetch(filters, sort);
  };

  const applyFilters = () => {
    setFilters(filters);
    refresh();
  };

  const onAccountUpdate = () => {
    refresh();

    message.success('Account updated');
  };

  const onUserSearchClick = async (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }

    applyFilters();
  };

  const onAccountSearchClick = async (accountId: string) => {
    if (accountId) {
      filters._id = accountId;
    } else {
      delete filters._id;
    }

    applyFilters();
  };

  const onOrderSearchClick = async (orderId: string) => {
    if (orderId) {
      filters.orderId = orderId;
    } else {
      delete filters.orderId;
    }

    applyFilters();
  };

  const toggleNewFundedWithRisk = (e: CheckboxChangeEvent) => {
    const value = e?.target?.checked;

    if (value) {
      filters.status = AccountStatus.Disabled;
      filters.type = AccountType.Funded;

      filters.state = {
        [AccountStateKey.Terminated]: false,
        [AccountStateKey.DailyLoss]: false,
        [AccountStateKey.Expired]: false,
        [AccountStateKey.DailyPause]: false,
        [AccountStateKey.Target]: false,
      };
    } else {
      delete filters['status'];
      delete filters['type'];
      delete filters.state;
    }

    applyFilters();
  };

  const onSearchStatus = (status?: AccountStatus | 'all') => {
    if (status && status !== 'all') {
      filters.status = status;
    } else {
      delete filters.status;
    }

    applyFilters();
  };

  const columns: ColumnsType<AccountAdmin> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      render: (_, item) => {
        const content = (
          <TextComponent
            style={{ maxWidth: '100px' }}
            ellipsis={true}
            type="secondary"
          >
            {item.orderId}
          </TextComponent>
        );

        if (item.orderId && item.orderId.length < 10) {
          return content;
        }

        return <Tooltip title={item.orderId}>{content}</Tooltip>;
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, item) => {
        return (
          <TextComponent type="secondary" ellipsis={true}>
            {' '}
            {dayjs(item.createdAt).format('DD/MM/YYYY hh:mm:ss')}{' '}
          </TextComponent>
        );
      },
    },
    {
      title: 'KYC',
      dataIndex: 'kyc',
      render: (_, item) => {
        const kycStatus = item?.user?.verificationData?.status;

        return kycStatus ? <UserStatusTag status={kycStatus} /> : null;
      },
    },
    {
      title: 'Contract',
      dataIndex: 'contractAt',
      render: (_, item) => {
        const contractStatus = item?.contract?.status;
        const version = item?.contract?.version;
        const isSigned = contractStatus === ContractStatus.Signed;

        if (!isSigned) {
          return null;
        }

        return (
          <>
            <TextComponent type="secondary">version {version}</TextComponent>
            <TextComponent type="secondary" ellipsis={true}>
              {dayjs(item.contract.updatedAt).format('DD/MM/YYYY hh:mm:ss')}
            </TextComponent>
          </>
        );
      },
    },
    {
      title: 'TradingSystem Account',
      dataIndex: 'tsAccount',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.tsAccount}</TextComponent>;
      },
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.user?.firstName || 'N/A'} {item.user?.lastName || 'N/A'}
          </TextComponent>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (_, item) => {
        return (
          <AccountUser
            id={item._id}
            email={item.user?.email}
            userId={item.userId}
            onSave={onAccountUpdate}
          />
        );
      },
    },
    userStore.currentUser.role === Role.SuperUser
      ? {
          title: 'State',
          dataIndex: 'state',
          render: (_: any, item: AccountAdmin) => {
            return (
              <AccountStateCell
                id={item._id}
                state={item.state}
                onSave={onAccountUpdate}
              />
            );
          },
        }
      : {},
    {
      title: 'Program Name',
      dataIndex: 'program',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.programName}</TextComponent>
        );
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (_, item) => {
        return <Statistic value={item.balance} />;
      },
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      render: (_, item) => {
        return (
          <AccountStatusCell
            id={item._id}
            status={item.status}
            onSave={onAccountUpdate}
            disableReason={item.disableReason}
          />
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.type}
          </TextComponent>
        );
      },
    },
    {
      title: 'Expire Date',
      dataIndex: 'expireDate',
      render: (_, item) => {
        return (
          <AccountExpireDateCell
            id={item._id}
            expireDate={item.expireDate}
            onSave={onAccountUpdate}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, item) => {
        return <AccountActions reFetch={refresh} account={item} />;
      },
    },
  ];

  const items: MenuProps['items'] = [
    {
      label: 'Create Accounts From CSV',
      key: '2',
      icon: <UserOutlined />,
      onClick: () => {
        modal.info({
          width: '80%',
          title: `Upload Accounts`,
          closable: true,
          maskClosable: true,
          content: <UploadAccounts />,
          okText: 'Close',
        });
      },
    },
  ];

  return (
    <>
      <Row align={'bottom'} gutter={[15, 15]}>
        <Col span={4}>
          <div>Email: </div>
          <UserAutocomplete onSelect={(userId) => onUserSearchClick(userId)} />
        </Col>
        <Col span={4}>
          <div>Account Id: </div>

          <AccountAutocomplete
            onSelect={(accountId) => onAccountSearchClick(accountId)}
          />
        </Col>
        <Col span={4}>
          <div>Order Id: </div>

          <OrderAutocomplete
            onSelect={(orderId) => onOrderSearchClick(orderId)}
          />
        </Col>
        <Col span={4}>
          <TextComponent>Account status :</TextComponent>

          <Select
            allowClear
            onClear={onSearchStatus}
            onSelect={onSearchStatus}
            placeholder={'All'}
            options={statusSelectOptions}
          />
        </Col>
        <Col span={4}>
          <Flex gap={4} align="center">
            <Checkbox id="waitingForRisk" onChange={toggleNewFundedWithRisk} />
            <label htmlFor="waitingForRisk" style={{ cursor: 'pointer' }}>
              <TextComponent>Waiting For Risk</TextComponent>
            </label>
            <Tooltip title="Show Only New Funded Waiting For Risk">
              <QuestionCircleOutlined />
            </Tooltip>
          </Flex>
        </Col>
        <Col span={4}>
          <Flex justify="end" align="center" gap={5}>
            <ProtectedElement
              key={'create-accs'}
              abilities={[Abilities.CreateAccounts]}
            >
              <Dropdown key="dd" menu={{ items }}>
                <Button>Actions</Button>
              </Dropdown>
            </ProtectedElement>
          </Flex>
        </Col>
      </Row>
      <EntityPageBase
        columns={columns}
        requestNextPage={requestPage}
        filters={filters}
        sort={sort}
        setSort={onSortChange}
        ref={entityPageRef}
        abilities={userStore.currentUser.abilities}
      />
    </>
  );
};

export default Accounts;
