import { FunctionComponent } from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';
import { OrderStatus } from '@monorepo/types';

interface Props {
  status: OrderStatus;
}

export const OrderStatusTag: FunctionComponent<Props> = ({
  status = OrderStatus.Pending,
}) => {
  const accountStatusToColor = {
    success: OrderStatus.Paid === status,
    warning: OrderStatus.Pending === status,
    error: OrderStatus.Declined === status,
    cyan: OrderStatus.Refunded === status,
    purple: OrderStatus.Processing === status,
  };

  return (
    <Tag
      style={{ textTransform: 'capitalize' }}
      color={classNames(accountStatusToColor)}
    >
      {status}
    </Tag>
  );
};
