import { FormCheckbox, FormInputNumber } from '@monorepo/react-components';
import { Program } from '@monorepo/types';
import { Col, Row } from 'antd';
import { noop } from 'lodash';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  onSave?: () => void;
}

const ProgramPurchaseLimitsForm = forwardRef<FormModalActions, Props>(
  ({ onSave = noop }, ref) => {
    const {
      dataStore: { programStore },
    } = useStore();
    const form = useForm<Program>({ mode: 'onChange' });

    return (
      <FormModal
        title={'Update Purchase Limits'}
        ref={ref}
        form={form}
        width="30%"
        onSave={async (item: Program) => {
          await programStore.updatePurchaseLimit(
            item._id,
            item.config.purchaseAmount?.amount || 0,
            item.config.purchaseAmount?.includeDisabledAccounts || false
          );

          onSave();
        }}
      >
        <Row gutter={[25, 5]}>
          <Col span={24}>
            <FormInputNumber
              form={form}
              text="Amount"
              placeholder="100"
              controllerProps={{
                rules: { required: true },
                name: 'config.purchaseAmount.amount',
              }}
            />
            <FormCheckbox
              form={form}
              text="Include Disabled Accounts In Limit"
              placeholder="100"
              controllerProps={{
                rules: { required: false },
                name: 'config.purchaseAmount.includeDisabledAccounts',
              }}
            />
          </Col>
        </Row>
      </FormModal>
    );
  }
);

export default ProgramPurchaseLimitsForm;
