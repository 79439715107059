import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;

export const FormRangePicker: FunctionComponent<
  Omit<FormFieldProps, 'render'> & RangePickerProps
> = (formFieldProps) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return <RangePicker showTime {...field} />;
      }}
    />
  );
};
