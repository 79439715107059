import { CrudService } from '@monorepo/client-common';
import { AffiliateCommissionStatus } from '@monorepo/types';

export default class AffiliationCommissionService extends CrudService {
  static override route = 'affiliate-commission';

  async updateBulkStatus(
    externalIds: string[],
    status: AffiliateCommissionStatus,
    currentPage: number,
    userId: string
  ) {
    const response = await this.httpService.post<
      {
        externalIds: string[];
        status: AffiliateCommissionStatus;
        currentPage: number;
        userId: string;
      },
      { data: string }
    >(`${this.path}/update-status`, {
      externalIds,
      status,
      currentPage,
      userId,
    });

    return response.data;
  }
}
