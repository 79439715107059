import { HttpService } from '@monorepo/client-common';
import UserService from './entities/user-service';
import DiscountsService from './entities/discounts-service';
import PayoutsService from './entities/payouts-service';
import CreditsService from './entities/credits-service';
import PartnerService from './entities/partner-service';
import ProgramService from './entities/program-service';
import AddOnService from './entities/add-on-service';
import OrdersService from './entities/orders-service';
import UsersService from './entities/users-service';
import AdminUsersService from './entities/admin-users-service';
import AccountsService from './entities/accounts-service';
import ApplicationConfigService from './entities/application-config-service';
import EmailTemplateService from './entities/email-template-service';
import PaymentProviderService from './entities/payment-provider-service';
import RoleAbilityService from './entities/role-ability-service';
import TradingSystemService from './entities/trading-system-service';
import GroupService from './entities/group-service';
import AffiliationCommissionService from './entities/affiliation-commission-service';
import CouponService from './entities/coupon-service';
import OrganizationService from './entities/organization-service';

interface ApiItem {
  variableName: string;
  // eslint-disable-next-line
  classEntity: any;
  route: string;
}

const ApiList: ApiItem[] = [
  {
    variableName: 'userService',
    classEntity: UserService,
    route: UserService.route,
  },
  {
    variableName: 'discountsService',
    classEntity: DiscountsService,
    route: DiscountsService.route,
  },
  {
    variableName: 'payoutsService',
    classEntity: PayoutsService,
    route: PayoutsService.route,
  },
  {
    variableName: 'partnerService',
    classEntity: PartnerService,
    route: PartnerService.route,
  },
  {
    variableName: 'affiliationCommissionService',
    classEntity: AffiliationCommissionService,
    route: AffiliationCommissionService.route,
  },
  {
    variableName: 'programService',
    classEntity: ProgramService,
    route: ProgramService.route,
  },
  {
    variableName: 'groupService',
    classEntity: GroupService,
    route: GroupService.route,
  },
  {
    variableName: 'addOnService',
    classEntity: AddOnService,
    route: AddOnService.route,
  },
  {
    variableName: 'usersService',
    classEntity: UsersService,
    route: UsersService.route,
  },
  {
    variableName: 'ordersService',
    classEntity: OrdersService,
    route: OrdersService.route,
  },
  {
    variableName: 'accountsService',
    classEntity: AccountsService,
    route: AccountsService.route,
  },
  {
    variableName: 'adminUsersService',
    classEntity: AdminUsersService,
    route: AdminUsersService.route,
  },
  {
    variableName: 'emailTemplateService',
    classEntity: EmailTemplateService,
    route: EmailTemplateService.route,
  },
  {
    variableName: 'paymentProviderService',
    classEntity: PaymentProviderService,
    route: PaymentProviderService.route,
  },
  {
    variableName: 'couponService',
    classEntity: CouponService,
    route: CouponService.route,
  },
  {
    variableName: 'applicationConfigService',
    classEntity: ApplicationConfigService,
    route: ApplicationConfigService.route,
  },
  {
    variableName: 'roleAbilityService',
    classEntity: RoleAbilityService,
    route: RoleAbilityService.route,
  },
  {
    variableName: 'creditsService',
    classEntity: CreditsService,
    route: CreditsService.route,
  },
  {
    variableName: 'tradingSystemService',
    classEntity: TradingSystemService,
    route: TradingSystemService.route,
  },
  {
    variableName: 'organizationService',
    classEntity: OrganizationService,
    route: OrganizationService.route,
  },
];

export interface ApiFactoryParams {
  httpService: HttpService;
}

class ApiFactory {
  private httpService: HttpService;

  accountsService: AccountsService = {} as AccountsService;
  userService: UserService = {} as UserService;
  usersService: UsersService = {} as UsersService;
  adminUsersService: AdminUsersService = {} as AdminUsersService;
  discountsService: DiscountsService = {} as DiscountsService;
  payoutsService: PayoutsService = {} as PayoutsService;
  creditsService: CreditsService = {} as CreditsService;
  ordersService: OrdersService = {} as OrdersService;
  partnerService: PartnerService = {} as PartnerService;
  emailTemplateService: EmailTemplateService = {} as EmailTemplateService;
  roleAbilityService: RoleAbilityService = {} as RoleAbilityService;
  programService: ProgramService = {} as ProgramService;
  groupService: GroupService = {} as GroupService;
  addOnService: AddOnService = {} as AddOnService;
  couponService: CouponService = {} as CouponService;
  organizationService: OrganizationService = {} as OrganizationService;
  paymentProviderService: PaymentProviderService = {} as PaymentProviderService;
  tradingSystemService: TradingSystemService = {} as TradingSystemService;
  affiliationCommissionService: AffiliationCommissionService =
    {} as AffiliationCommissionService;
  applicationConfigService: ApplicationConfigService =
    {} as ApplicationConfigService;

  constructor({ httpService }: ApiFactoryParams) {
    this.httpService = httpService;

    ApiList.forEach((api: ApiItem) => {
      this[api.variableName as keyof ApiFactory] = new api.classEntity({
        httpService,
        route: api.route,
      });
    });
  }

  saveToken(token: string, refreshToken: () => Promise<string>) {
    this.httpService.setToken(token, refreshToken);
  }

  removeToken() {
    this.httpService.removeToken();
  }
}

export default ApiFactory;
