import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

type Props = TitleProps;

const { Title } = Typography;

export const TitleComponent: FunctionComponent<Props> = ({
  children,
  className,
  ...antdProps
}) => {
  return (
    <Title {...antdProps} className={classNames('5ers-title', className)}>
      {children}
    </Title>
  );
};
