import { FunctionComponent, useEffect, useState } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Button, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface HandleUploadParams {
  file: File;
  onSuccess: () => void;
  onError: () => void;
}

enum FileStatus {
  Uploading = 'uploading',
  Done = 'done',
  Error = 'error',
}

export const FormImage: FunctionComponent<
  Omit<FormFieldProps, 'render'> &
    UploadProps & {
      allowedFormats?: string[];
      maxFileSize?: number;
      fileName: string;
    }
> = (formFieldProps) => {
  const {
    allowedFormats,
    maxFileSize,
    controllerProps: { name },
    form,
    fileName,
  } = formFieldProps;

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: UploadFile) => {
    setFileList([
      {
        ...file,
        name: file.name,
        status: FileStatus.Uploading,
        percent: 0,
      },
    ]);

    if (maxFileSize) {
      const isFileSizeValid =
        file.size && file.size / 1024 / 1024 < maxFileSize;

      if (!isFileSizeValid) {
        form.setError(name, {
          message: `Image size exceeds the limit of ${maxFileSize} MB`,
        });
        return false;
      }
    }

    return true;
  };

  const afterUpload = async ({
    file,
    onSuccess,
    onError,
  }: HandleUploadParams) => {
    try {
      updateFileListPercent(100);
      updateFileListStatus(FileStatus.Done);

      const updatedFile = new File([file], fileName, { type: file.type });

      form.setValue(name, updatedFile);

      onSuccess();
    } catch (e) {
      console.error('Error uploading file:', e);
      updateFileListStatus(FileStatus.Error);
      onError();
    }
  };

  const afterRemove = () => {
    setFileList([]);
    form.resetField(name);
  };

  const updateFileListPercent = (percent: number) => {
    setFileList([{ ...fileList[0], percent }]);
  };

  const updateFileListStatus = (status: FileStatus) => {
    setFileList([{ ...fileList[0], status }]);
  };

  useEffect(() => {
    if (!form.getValues(name)) {
      setFileList([]);
    }
  }, [form.watch(name)]);

  return (
    <FormField
      {...formFieldProps}
      render={(field) => {
        return (
          <Upload
            {...field}
            fileList={fileList}
            listType={'text'}
            //@ts-expect-error/handle-later
            customRequest={afterUpload}
            beforeUpload={beforeUpload}
            onRemove={afterRemove}
            accept={allowedFormats?.join(',')}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        );
      }}
    />
  );
};
