import { AddOn } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';

interface Props {
  onSelect: (item: Option) => void;
  onClear: () => void;
}

const AddOnAutocomplete: FunctionComponent<Props> = ({ onSelect, onClear }) => {
  const {
    dataStore: { addOnStore },
  } = useStore();

  const searchInputChange = async (name: string) => {
    const addOns = (await addOnStore.search({
      name,
    })) as Partial<AddOn>[];

    return addOns.map((addOn) => ({
      label: addOn.name,
      value: addOn._id,
    })) as Option[];
  };

  return (
    <Autocomplete
      onSelect={onSelect}
      onClear={onClear}
      searchInputChange={searchInputChange}
      allowTermSearch
      placeholder="select odd on"
      width="300px"
    />
  );
};

export default AddOnAutocomplete;
