import { forwardRef } from 'react';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import { Flex, message } from 'antd';
import { AddProfitableDayPayload } from '@monorepo/types';
import { FormDatePicker } from '@monorepo/react-components';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  accountId: string;
}

const AccountProfitableDayModal = forwardRef<FormModalActions, Props>(
  ({ accountId }, ref) => {
    const {
      dataStore: { accountStore },
    } = useStore();

    const form = useForm<AddProfitableDayPayload>({ mode: 'onChange' });

    const disableDates = (date: Dayjs | string) => {
      const parsedDate = dayjs(date);
      const today = dayjs();
      const twoDaysPriorToday = today.subtract(2, 'day');
      return parsedDate.isAfter(twoDaysPriorToday.endOf('day'));
    };

    const addProfitableDay = async (values: AddProfitableDayPayload) => {
      try {
        await accountStore.addProfitableDay({
          day: dayjs(values.day).format('YYYY-MM-DD'),
          accountId,
        });
        message.success(
          `Profitable day ${values.day} added successfully for account ${accountId}`
        );
      } catch (e: any) {
        message.error(
          e?.response?.data?.message?.message || 'Something went wrong'
        );
        console.error(e);
      }
    };

    return (
      <FormModal
        title={'Add Profitable Day'}
        ref={ref}
        width={'30%'}
        onSave={addProfitableDay}
        form={form}
        destroyOnClose={true}
      >
        <Flex vertical>
          <FormDatePicker
            form={form}
            text="Pick a day"
            picker="date"
            disabledDate={disableDates}
            controllerProps={{
              name: 'day',
              rules: {
                required: 'Missing day',
              },
            }}
          />
        </Flex>
      </FormModal>
    );
  }
);
export default AccountProfitableDayModal;
