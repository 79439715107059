import { CrudService } from '@monorepo/client-common';
import { PaginationResult, RoleAbility } from '@monorepo/types';

export default class RoleAbilityService extends CrudService {
  static override route = 'roles-abilities';

  async list() {
    try {
      const response: { data: RoleAbility[] } = await this.httpService.get(
        this.path
      );

      const users = response.data;

      return { total: users.length, results: users } as PaginationResult;
    } catch (e) {
      console.error(e);
      return { total: 0, results: [] };
    }
  }
}
