import { FunctionComponent } from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';
import { UserVerificationStatus } from '@monorepo/types';

interface Props {
  status: UserVerificationStatus;
}

export const UserStatusTag: FunctionComponent<Props> = ({
  status = UserVerificationStatus.NotRequired,
}) => {
  const userStatusToColor = {
    success: UserVerificationStatus.Approved === status,
    warning: UserVerificationStatus.Pending === status,
    error: UserVerificationStatus.Rejected === status,
    processing: UserVerificationStatus.InProgress === status,
    default: UserVerificationStatus.NotRequired === status,
  };

  return (
    <Tag
      style={{ textTransform: 'capitalize' }}
      color={classNames(userStatusToColor)}
    >
      {status}
    </Tag>
  );
};
