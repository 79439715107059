import { AccountType, TargetEmails as TargetEmailsType } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormModalActions } from '../../form-modal/form-modal';
import TargetEmailsForm from './target-emails-form';

interface Props {
  targetEmails: TargetEmailsType | undefined;
  onSave: (targetEmails: TargetEmailsType) => void;
}

const TargetEmails: FunctionComponent<Props> = ({ targetEmails, onSave }) => {
  const applicationConfigFormRef = useRef<FormModalActions>(
    {} as FormModalActions
  );

  const items: DescriptionsProps['items'] = Object.values(AccountType).map(
    (type) => {
      const value = (targetEmails || {})[type as AccountType];
      return {
        key: type,
        label: type,
        span: 1,
        children: value || 'N/A',
      };
    }
  );

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => applicationConfigFormRef.current.open(targetEmails)}
        >
          {items.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {items.length && (
          <Descriptions column={6} layout="vertical" bordered items={items} />
        )}
        <TargetEmailsForm
          name="targetEmails"
          onSave={onSave}
          ref={applicationConfigFormRef}
        />
      </Col>
    </Row>
  );
};

export default TargetEmails;
