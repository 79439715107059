import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { ApplicationContract, ContractType } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import ContractDrawer from './application-config-contracts-drawer';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../helpers/use-store';

interface Props {
  contracts: ApplicationContract[];
  onSave: (contracts: ApplicationContract[]) => void;
}

const getDefaultValues = (
  contract?: ApplicationContract
): Partial<ApplicationContract> => {
  if (contract) {
    return contract;
  }
  return {
    type: ContractType.Funded,
    currentVersion: 1,
  };
};

const Contracts: FunctionComponent<Props> = ({ contracts, onSave }: Props) => {
  const form = useForm<ApplicationContract>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const columns: ColumnsType<ApplicationContract> = [
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.type}</TextComponent>;
      },
    },
    {
      title: 'Version',
      dataIndex: 'currentVersion',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.currentVersion}</TextComponent>;
      },
    },
  ];

  const requestPage = async () => {
    return {
      total: contracts.length,
      results: contracts.map((contract) => ({
        ...contract,
        _id: contract.type,
      })),
    };
  };

  const submitContractForm = async () => {
    const contract = form.getValues();

    const index = contracts.findIndex((item) => item.type === contract.type);

    index !== -1 ? (contracts[index] = contract) : contracts.push(contract);

    onSave(contracts);
  };

  const onDelete = (type: string) => {
    const index = contracts.findIndex((item) => item.type === type);

    if (index !== -1) {
      contracts.splice(index, 1);
      onSave(contracts);
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<ContractDrawer form={form} />}
      onSubmit={submitContractForm}
      columns={columns}
      isEditAllowed
      isCreateAllowed
      onDelete={onDelete}
      requestNextPage={requestPage}
      initialLimit={10}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default observer(Contracts);
