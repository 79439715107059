import {
  ConsistentRulesConfig,
  CreditEvents,
  PartnerType,
  Program,
  ProgramsRequestFilters,
  ProgramTradingSystemConfig,
  WithdrawRule,
} from '@monorepo/types';
import ProgramService from '../../../services/entities/program-service';
import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export default class ProgramStore extends EntityStore<ProgramService, Program> {
  constructor() {
    const {
      apiFactory: { programService },
    } = getEnv();

    super(programService);
  }

  search(query: ProgramsRequestFilters) {
    return this.service.search(query);
  }

  updatePurchaseLimit(
    id: string,
    amount: number,
    includeDisabledAccounts?: boolean
  ) {
    return this.service.updatePurchaseLimit({
      id,
      amount,
      includeDisabledAccounts,
    });
  }

  updateBoosters(id: string, boosters: Record<PartnerType, number>) {
    return this.service.updateBoosters({ id, boosters });
  }

  updateConsistencyRules(
    id: string,
    consistencyRules: ConsistentRulesConfig[]
  ) {
    return this.service.updateConsistencyRules({
      id,
      consistencyRules,
    });
  }

  updateWithdrawRules(id: string, withdrawRules: WithdrawRule[]) {
    return this.service.updateWithdrawRules({
      id,
      withdrawRules,
    });
  }

  duplicate(id: string) {
    return this.service.duplicate({ id });
  }

  updateCreditEvents(id: string, creditEvents: CreditEvents) {
    return this.service.updateCreditEvents({ id, creditEvents });
  }
  updateTradingSystems(
    id: string,
    tradingSystems: ProgramTradingSystemConfig[]
  ) {
    return this.service.updateTradingSystems({ id, tradingSystems });
  }
}
