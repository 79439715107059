import { EntityStore } from '@monorepo/client-common';
import { EmailTemplate } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import EmailTemplateService from '../../../services/entities/email-template-service';

export default class EmailTemplateStore extends EntityStore<
  EmailTemplateService,
  EmailTemplate
> {
  constructor() {
    const {
      apiFactory: { emailTemplateService },
    } = getEnv();

    super(emailTemplateService);
  }
}
