import { FormSelect } from '@monorepo/react-components';
import { Abilities, Role, RoleAbility } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const roles = Object.values(Role)
  .filter((role) => role !== Role.SuperUser && role !== Role.None)
  .map((role) => {
    return { value: role, label: role };
  });

const abilities = Object.values(Abilities).map((ability) => {
  return { value: ability, label: ability };
});

export const RoleAbilityDrawer: FunctionComponent<{
  form: UseFormReturn<RoleAbility & { _id: string }>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'role',
            rules: {
              required: { value: true, message: 'Role is required' },
            },
          }}
          text={'role'}
          options={roles}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          mode="tags"
          controllerProps={{ name: 'abilities' }}
          text={'abilities'}
          options={abilities}
        />
      </Col>
    </Row>
  );
};
