import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';
import { Order } from '@monorepo/types';

interface Props {
  onSelect: (uniqId: string) => void;
}

const OrderUniqueIdAutocomplete: FunctionComponent<Props> = ({ onSelect }) => {
  const {
    dataStore: { orderStore },
  } = useStore();

  const searchInputChange = async (uniqId: string) => {
    const orders = (await orderStore.searchByOrderUniqId({
      uniqId,
    })) as unknown as Order[];

    return orders.map((order) => ({
      label: order.uniqId,
      value: order.uniqId,
    })) as Option[];
  };

  return (
    <Autocomplete
      width="200px"
      onSelect={(order) => onSelect(order.value)}
      onClear={() => onSelect('')}
      searchInputChange={searchInputChange}
      placeholder={'10000'}
    />
  );
};

export default OrderUniqueIdAutocomplete;
