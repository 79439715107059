import { CrudService } from '@monorepo/client-common';
import { AdminLoginResponse } from '@monorepo/types';
import { AxiosError } from 'axios';

export default class UserService extends CrudService {
  static override route = 'user';

  async login(email?: string, password?: string) {
    try {
      const response: { data: AdminLoginResponse } =
        await this.httpService.post(`${this.path}/login`, {
          username: email,
          password,
        });
      return response.data;
    } catch (e) {
      this.handleLoginError(e);
    }
  }

  async refreshToken(refreshToken: string, userId: string) {
    const tokenData = await this.httpService.post<
      { refreshToken: string; userId: string },
      { data: string }
    >(`${this.path}/refresh-token`, {
      refreshToken,
      userId,
    });

    return tokenData.data;
  }

  private handleLoginError(error: unknown) {
    if (error instanceof AxiosError) {
      const responseError = error.response?.data;
      if (responseError?.statusCode === 401) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Something went wrong');
    }
    if (error instanceof Error) {
      throw new Error('Something went wrong');
    }
    throw new Error('Something went wrong');
  }
}
