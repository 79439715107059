import { CrudService } from '@monorepo/client-common';
import { PaginationResult, PaymentProvider } from '@monorepo/types';

export default class PaymentProviderService extends CrudService {
  static override route = 'payment-provider';

  async list() {
    try {
      const response: { data: PaymentProvider[] } = await this.httpService.get(
        this.path
      );

      const users = response.data;

      return { total: users.length, results: users } as PaginationResult;
    } catch (e) {
      console.error(e);
      return { total: 0, results: [] };
    }
  }
}
