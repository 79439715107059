import { Typography } from 'antd';
import { useStore } from '../../helpers/use-store';
import { Organization } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import { OrganizationDrawer } from '../organization-drawer/organization-drawer';

const { Link, Text } = Typography;

const getDefaultValues = (
  organization?: Organization
): Partial<Organization> => {
  if (organization) {
    return organization;
  }
  return {
    zipCode: '',
    street: '',
    country: '',
    name: '',
    email: '',
    state: '',
  };
};

const Organizations = () => {
  const {
    dataStore: { organizationStore, userStore },
  } = useStore();

  const form = useForm<Organization>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await organizationStore.paginate({
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading partners for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<Organization> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <Text type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.name}
          </TextComponent>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.code}
          </TextComponent>
        );
      },
    },
    {
      title: 'Website',
      dataIndex: 'website',
      align: 'center',
      render: (_, item) => {
        return (
          <Link ellipsis={true} type="secondary">
            {item.website}
          </Link>
        );
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.country}
          </TextComponent>
        );
      },
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.state}
          </TextComponent>
        );
      },
    },
    {
      title: 'Street',
      dataIndex: 'street',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.street}
          </TextComponent>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.email}
          </TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitPartnerForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      await organizationStore.create(form.getValues());
    } else {
      await organizationStore.update(form.getValues()._id, form.getValues());
    }
  };

  const onDelete = async (id: string) => {
    await organizationStore.delete(id);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<OrganizationDrawer form={form} />}
      onSubmit={submitPartnerForm}
      columns={columns}
      isEditAllowed
      onDelete={onDelete}
      isCreateAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default Organizations;
