import { AffiliationDiscount } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { ColumnsType } from 'antd/lib/table';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { Tag } from 'antd';
import { useForm } from 'react-hook-form';
import { DiscountGroupDrawer } from '../discount-group-drawer/discount-group-drawer';

const getDefaultValues = (
  affiliationDiscount?: AffiliationDiscount
): Partial<AffiliationDiscount> => {
  if (affiliationDiscount) {
    return affiliationDiscount;
  }

  return {
    groupName: '',
    affiliateId: '',
    recurring: false,
    discountPercentage: 0,
  };
};

export const Discounts = () => {
  const form = useForm<AffiliationDiscount>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });
  const {
    dataStore: { discountsStore, userStore },
  } = useStore();

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await discountsStore.paginate({
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading discounts for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<AffiliationDiscount> = [
    {
      title: 'Discount ID',
      dataIndex: '_id',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Affiliate Id',
      dataIndex: 'affiliateId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">
            {item.affiliateId ? item.affiliateId : '-'}
          </TextComponent>
        );
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">
            {item.groupName ? item.groupName : '-'}
          </TextComponent>
        );
      },
    },
    {
      title: 'Commission Percentage',
      dataIndex: 'commissionPercentage',
      align: 'center',
      render: (_, item) => {
        return <span>{item.commissionPercentage}%</span>;
      },
    },
    {
      title: 'Discount Percentage',
      dataIndex: 'discountPercentage',
      align: 'center',
      render: (_, item) => {
        return <span>{item.discountPercentage}%</span>;
      },
    },
    {
      title: 'Recurring Discount',
      dataIndex: 'recurring',
      align: 'end',
      render: (_, item) => {
        return (
          <>
            {item.recurring ? (
              <Tag color={'green'}>Yes</Tag>
            ) : (
              <Tag color={'red'}>No</Tag>
            )}
          </>
        );
      },
    },
  ];

  const submitDiscountForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      await discountsStore.create(form.getValues());
    } else {
      await discountsStore.update(form.getValues()._id, form.getValues());
    }
  };

  const validateForm = async () => {
    await form.trigger();
    const formValues = form.getValues();
    if (!formValues.groupName && !formValues.affiliateId) {
      form.setError('groupName', {
        type: 'required',
        message: 'Please set either group name or affiliate id',
      });
      form.setError('affiliateId', {
        type: 'required',
        message: 'Please set either group name or affiliate id',
      });
      return false;
    }

    return form.formState.isValid;
  };

  return (
    <EntityPageWithDrawer
      form={form}
      columns={columns as ColumnsType}
      onDelete={(id: string) => discountsStore.delete(id)}
      isEditAllowed
      isCreateAllowed
      initialLimit={30}
      requestNextPage={requestPage}
      onSubmit={submitDiscountForm}
      validateForm={validateForm}
      getDefaultValues={getDefaultValues}
      drawerBody={<DiscountGroupDrawer form={form} />}
      abilities={userStore.currentUser.abilities}
    />
  );
};
