import {
  AccountEvent,
  AccountEventItem,
  EventColor,
  eventColorMap,
  AccountState,
  ACCOUNT_STATE_TITLES,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { Flex, Modal, Skeleton, Timeline, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { TextComponent } from '@monorepo/react-components';
import { LoadingState } from '@monorepo/client-common';

interface Props {
  accountId: string;
  open: boolean;
  close: () => void;
}

const AccountEventsModal = ({ accountId, open, close }: Props) => {
  const {
    dataStore: { accountStore },
  } = useStore();

  const [events, setEvents] = useState<AccountEvent[]>([]);
  const [loadingState, setLoadingState] = useState(LoadingState.Initial);

  const init = async () => {
    try {
      setLoadingState(LoadingState.Loading);

      const response = await accountStore.getAccountEvents(accountId);

      setEvents(response);
    } catch (e) {
      message.error('Failed to get account events');
      console.error(
        `Failed to get account events for account: ${accountId}`,
        e
      );
    } finally {
      setLoadingState(LoadingState.Loaded);
    }
  };

  useEffect(() => {
    if (open) {
      init();
    }
  }, [open]);

  const eventText = (event: AccountEvent) => {
    const { event: eventType, metadata } = event;

    return (
      <Flex vertical={true}>
        <TextComponent capitalize>{eventType}</TextComponent>
        {metadata?.eventByUserEmail && (
          <TextComponent type="secondary">
            {metadata.eventByUserEmail}
          </TextComponent>
        )}
        {metadata?.state && (
          <TextComponent type="success">
            {getStateString('state', metadata.state)}
          </TextComponent>
        )}
        {metadata?.prevState && (
          <TextComponent type="warning">
            {getStateString('previous state', metadata.prevState)}
          </TextComponent>
        )}
      </Flex>
    );
  };

  const getStateString = (title: string, state: AccountState): string => {
    const activeStates = Object.keys(state || {}).filter((key) =>
      Boolean(state[key as keyof AccountState])
    ) as [keyof AccountState];

    const activeStatesTitles = activeStates
      .map((key) => ACCOUNT_STATE_TITLES[key as keyof AccountState])
      .join(', ');

    return activeStatesTitles ? `${title}: ${activeStatesTitles}` : '';
  };

  const getEventColor = (event: AccountEventItem): EventColor => {
    for (const [color, events] of Object.entries(eventColorMap)) {
      if (events.includes(event)) {
        return color as EventColor;
      }
    }
    return EventColor.Gray;
  };

  const items = events.map((event) => {
    return {
      label: dayjs(event.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      children: eventText(event),
      color: getEventColor(event.event),
    };
  });

  return (
    <Modal
      title={`Account Events - ${accountId}`}
      open={open}
      footer={null}
      onCancel={close}
      style={{
        maxHeight: '65vh',
        overflowY: 'scroll',
      }}
    >
      {loadingState === LoadingState.Loading ? (
        <Skeleton active={true} />
      ) : (
        <Timeline mode={'left'} items={items} style={{ padding: '20px' }} />
      )}
    </Modal>
  );
};
export default AccountEventsModal;
