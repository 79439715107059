import { Statistic, TextComponent } from '@monorepo/react-components';
import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Row,
  Typography,
} from 'antd';
import { FunctionComponent, useRef } from 'react';
import { FormModalActions } from '../../form-modal/form-modal';
import AddOnOverviewForm from './add-on-overview-form';
import { AddOnWithProgramName } from '../../../stores/data/add-on/add-on-store';

interface Props {
  addOn: AddOnWithProgramName;
  onSave: () => void;
}
const AddOnOverview: FunctionComponent<Props> = ({ addOn, onSave }) => {
  const addOnFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Name',
      children: <TextComponent>{addOn.name}</TextComponent>,
    },
    {
      key: '2',
      label: 'Type',
      span: 1,
      children: <TextComponent>{addOn.type}</TextComponent>,
    },
    {
      key: '3',
      label: 'Price',
      span: 1,
      children: <Statistic value={addOn.price} prefix="$" />,
    },
    {
      key: '4',
      label: 'Description',
      span: 1,
      children: <TextComponent>{addOn.description}</TextComponent>,
    },
    {
      key: '4',
      label: 'Programs name',
      span: 1,
      children: (
        <Typography.Paragraph
          ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
        >
          {addOn?.programNames?.join(', ')}
        </Typography.Paragraph>
      ),
    },
  ];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => addOnFormRef.current.open(addOn)}
        >
          Edit
        </Button>
      </Col>
      <Col span={24}>
        <Descriptions column={6} layout="vertical" bordered items={items} />
        <AddOnOverviewForm onSave={onSave} ref={addOnFormRef} addOn={addOn} />
      </Col>
    </Row>
  );
};

export default AddOnOverview;
