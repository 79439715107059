import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import { Button, Col, Row } from 'antd';
import { FormRangePicker, TextComponent } from '@monorepo/react-components';
import { TradingSystem } from '@monorepo/types';
import { Dayjs } from 'dayjs';

type SyncClosedPositionPayload = {
  dates: Dayjs[];
};

type SyncClosedPositionsProps = {
  tradingSystem: TradingSystem;
};

const SyncClosedPositionsModal: FunctionComponent<SyncClosedPositionsProps> = ({
  tradingSystem,
}) => {
  const {
    dataStore: { tradingSystemStore },
  } = useStore();
  const formModalRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<SyncClosedPositionPayload>({ mode: 'onChange' });

  const dates = form.watch('dates');
  let diffInHours = 4,
    isAllowed = false;

  if (dates && dates[0] && dates[1]) {
    diffInHours = Math.abs(dates[0].diff(dates[1], 'hours'));
    isAllowed = diffInHours < 4;
  }

  return (
    <>
      <Button onClick={() => formModalRef.current.open()}>
        Sync Closed Positions
      </Button>
      <FormModal
        okButtonProps={{
          disabled: !isAllowed,
        }}
        title={'Sync Closed Positions'}
        ref={formModalRef}
        form={form}
        width={'50%'}
        onSave={async (item: SyncClosedPositionPayload) => {
          const { dates } = item;

          const startDate = dates[0].toISOString();
          const endDate = dates[1].toISOString();

          await tradingSystemStore.syncClosedPositions({
            tradingSystem,
            startDate,
            endDate,
          });

          formModalRef.current.close();
        }}
      >
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <FormRangePicker
              showTime={{ format: 'HH:mm' }}
              form={form}
              controllerProps={{
                name: 'dates',
                rules: { required: 'Missing Dates' },
              }}
            />
          </Col>
          <TextComponent>Maximum diff in hours can be 4</TextComponent>
        </Row>
      </FormModal>
    </>
  );
};

export default SyncClosedPositionsModal;
