import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Select, SelectProps } from 'antd';
import { currenciesData } from '@monorepo/client-common';

export const FormCurrency: FunctionComponent<Omit<FormFieldProps, 'render'>> = (
  formFieldProps
) => {
  const fieldProps: SelectProps = {
    placeholder: formFieldProps.placeholder,
    style: { width: '100%' },
    mode: formFieldProps.mode || undefined,
    options: currenciesData.map((currency) => {
      return {
        label: currency.label,
        value: currency.countryCodeExtended,
      };
    }),
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return <Select size="large" {...fieldProps} {...field} />;
      }}
    />
  );
};
