import { FormInput, FormSelect } from '@monorepo/react-components';
import { Abilities, AdminUser, Role } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const roles = Object.values(Role)
  .filter((role) => role !== Role.SuperUser)
  .map((role) => {
    return { value: role, label: role };
  });

const abilities = Object.values(Abilities).map((ability) => {
  return { value: ability, label: ability };
});

export const AdminUserDrawer: FunctionComponent<{
  form: UseFormReturn<AdminUser & { _id: string }>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="First Name"
          text={'First Name'}
          form={form}
          controllerProps={{
            name: 'firstName',
            rules: {
              required: { value: true, message: 'First Name is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Last Name"
          text={'Last Name'}
          form={form}
          controllerProps={{
            name: 'lastName',
            rules: {
              required: { value: true, message: 'Last Name is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="email"
          text={'Email'}
          form={form}
          controllerProps={{
            name: 'email',
            rules: {
              required: { value: true, message: 'Email is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'role',
            rules: {
              required: { value: true, message: 'Role is required' },
            },
          }}
          text={'role'}
          options={roles}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          mode="tags"
          controllerProps={{ name: 'abilities' }}
          text={'abilities'}
          options={abilities}
        />
      </Col>
    </Row>
  );
};
