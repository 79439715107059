import {
  FormInput,
  FormSelect,
  FormTextArea,
} from '@monorepo/react-components';
import {
  ApplicationScheduledJob,
  HubQueueJobType,
  TimeZone,
} from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const hubQueueJobTypes = Object.values(HubQueueJobType).map((type) => {
  return { value: type, label: type };
});

const timeZones = Object.values(TimeZone).map((type) => {
  return { value: type, label: type };
});
const isValidJSON = (jsonStr: string) => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (error) {
    return 'Not a JSON Format';
  }
};
export const ApplicationScheduledJobDrawer: FunctionComponent<{
  form: UseFormReturn<ApplicationScheduledJob>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Name"
          text={'Name'}
          form={form}
          controllerProps={{
            name: 'name',
            rules: {
              required: { value: true, message: 'Name is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'jobType',
            rules: {
              required: { value: true, message: 'Job Type is required' },
            },
          }}
          text={'Job Type'}
          options={hubQueueJobTypes}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Schedule"
          text={'Schedule'}
          form={form}
          controllerProps={{
            name: 'schedule',
            rules: {
              required: { value: true, message: 'Schedule is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'timeZone',
          }}
          text={'Time Zone'}
          options={timeZones}
        />
      </Col>
      <Col span={24}>
        <FormTextArea
          form={form}
          controllerProps={{
            name: 'payload',
            rules: { validate: isValidJSON },
          }}
          text={'Payload'}
        />
      </Col>
    </Row>
  );
};
