import {
  AddOnType,
  AdminNotification,
  ApplicationConfig,
  ApplicationContract,
  ApplicationScheduledJob,
  ApplicationTradingSystem,
  Banner,
  BannerPlacement,
  BlacklistEmail,
  SidebarItem,
  TargetEmails,
  WithdrawRule,
} from '@monorepo/types';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import ApplicationConfigsService from '../../../services/entities/application-config-service';

export default class ApplicationConfigStore {
  @observable
  applicationConfig: ApplicationConfig = {
    tradingSystems: [],
  } as unknown as ApplicationConfig;

  service: ApplicationConfigsService;

  constructor() {
    const {
      apiFactory: { applicationConfigService },
    } = getEnv();

    this.service = applicationConfigService;

    makeObservable(this);
  }

  async get() {
    const appConfig =
      (await this.service.getAll<ApplicationConfig>()) as unknown as ApplicationConfig;

    this.setApplicationConfig(appConfig);

    return appConfig;
  }

  async update(id: string, data: ApplicationConfig) {
    const updatedAppConfig = (await this.service.update(
      id,
      data as unknown as Record<string, unknown>
    )) as unknown as ApplicationConfig;

    runInAction(() => {
      this.applicationConfig = updatedAppConfig;
    });
  }

  async updateAccountWithdrawRules(
    id: string,
    accountWithdrawRules: WithdrawRule[]
  ) {
    const updatedAppConfig = (await this.service.updateAccountWithdrawRules({
      id,
      accountWithdrawRules,
    })) as unknown as ApplicationConfig;

    runInAction(() => {
      this.applicationConfig = updatedAppConfig;
    });
  }

  async updateAffiliateWithdrawRules(
    id: string,
    affiliateWithdrawRules: WithdrawRule[]
  ) {
    const updatedAppConfig = (await this.service.updateAffiliateWithdrawRules({
      id,
      affiliateWithdrawRules,
    })) as unknown as ApplicationConfig;

    runInAction(() => {
      this.applicationConfig = updatedAppConfig;
    });
  }

  async updateScheduledJobs(
    id: string,
    scheduledJobs: ApplicationScheduledJob[]
  ) {
    await this.service.updateScheduledJobs({
      id,
      scheduledJobs,
    });

    runInAction(() => {
      this.applicationConfig.scheduledJobs = scheduledJobs;
    });
  }

  async runJob(job: ApplicationScheduledJob) {
    await this.service.runJob({
      job,
    });
  }

  async updateBlacklistEmails(id: string, blacklistEmails: BlacklistEmail[]) {
    await this.service.updateBlacklistEmails({
      id,
      blacklistEmails,
    });

    runInAction(() => {
      this.applicationConfig.blacklistEmails = blacklistEmails;
    });
  }

  async updateTradingSystems(
    id: string,
    tradingSystems: ApplicationTradingSystem[]
  ) {
    await this.service.updateTradingSystems({
      id,
      tradingSystems,
    });

    runInAction(() => {
      this.applicationConfig.tradingSystems = tradingSystems;
    });
  }

  async updateTargetEmails(id: string, targetEmails: TargetEmails) {
    await this.service.updateTargetEmails({
      id,
      targetEmails,
    });

    runInAction(() => {
      this.applicationConfig.targetEmails = targetEmails;
    });
  }

  @action
  setApplicationConfig(applicationConfig: ApplicationConfig) {
    this.applicationConfig = applicationConfig;
  }

  async updateAddOns(id: string, addOns: AddOnType[]) {
    await this.service.updateAddOns({
      id,
      addOns,
    });

    runInAction(() => {
      this.applicationConfig.addOns = addOns;
    });
  }

  async updateSidebar(id: string, sidebar: SidebarItem[]) {
    await this.service.updateSidebar({
      id,
      sidebar,
    });

    runInAction(() => {
      this.applicationConfig.sidebar = sidebar;
    });
  }

  async updateContracts(id: string, contracts: ApplicationContract[]) {
    await this.service.updateContracts({
      id,
      contracts,
    });

    runInAction(() => {
      this.applicationConfig.contracts = contracts;
    });
  }

  async updateAdminNotification(
    id: string,
    adminNotification: AdminNotification[]
  ) {
    await this.service.updateAdminNotification({
      id,
      adminNotification,
    });

    runInAction(() => {
      this.applicationConfig.adminNotification = adminNotification;
    });
  }

  async updateBanners(formData: FormData) {
    const updatedAppConfig = await this.service.updateBanners(formData);

    runInAction(() => {
      this.applicationConfig = updatedAppConfig;
    });
  }

  async deleteBanner(
    banners: Banner[],
    name: string,
    placement: BannerPlacement
  ) {
    await this.service.deleteBanner(
      this.applicationConfig._id,
      banners,
      name,
      placement
    );
  }
}
