import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { InputNumber, InputNumberProps } from 'antd';

export const FormInputNumber: FunctionComponent<
  Omit<FormFieldProps, 'render'> & { props?: InputNumberProps }
> = (formFieldProps) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <InputNumber
            size="large"
            className="form-field__input-number"
            placeholder={formFieldProps.placeholder}
            {...field}
            {...formFieldProps.props}
          />
        );
      }}
    />
  );
};
