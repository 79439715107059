import { Abilities, Role } from '@monorepo/types';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';

type Props = PropsWithChildren<{
  abilities: Abilities[];
}>;

const ProtectedRoute: FunctionComponent<Props> = ({ children, abilities }) => {
  const {
    dataStore: {
      userStore: { currentUser, isUserAllowed },
    },
  } = useStore();

  if (currentUser.role === Role.SuperUser) {
    return children;
  }

  return isUserAllowed(currentUser, abilities) ? (
    children
  ) : (
    <Navigate to="/not-allowed" replace />
  );
};

export default ProtectedRoute;
