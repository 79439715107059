import { Typography } from 'antd';
import { useStore } from '../../helpers/use-store';
import { Partner, PartnerType } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import { PartnerDrawer } from '../partner-drawer/partner-drawer';
import { CheckOutlined } from '@ant-design/icons';

import './partners.scss';

const { Link, Text } = Typography;

const getDefaultValues = (partner?: Partner): Partial<Partner> => {
  if (partner) {
    return partner;
  }
  return {
    title: '',
    description: '',
    enabled: false,
    videoUrl: '',
    googleSheetId: '',
    type: PartnerType.Journal,
  };
};

const Partners = () => {
  const {
    dataStore: { partnerStore, userStore },
  } = useStore();
  const form = useForm<Partner>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await partnerStore.paginate({
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading partners for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<Partner> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <Text type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.title}
          </TextComponent>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align: 'center',
      render: (_, item) => {
        return (
          <div className="partners-column-description">
            <TextComponent
              className="partners-column-description-text"
              ellipsis={{ tooltip: item.description }}
              type="secondary"
            >
              {item.description}
            </TextComponent>
          </div>
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      align: 'center',
      render: (_, item) => {
        return item.enabled ? <CheckOutlined /> : null;
      },
    },
    {
      title: 'VideoUrl',
      dataIndex: 'videoUrl',
      align: 'center',
      render: (_, item) => {
        return (
          <Link href={item.videoUrl} ellipsis target="_blank">
            {item.videoUrl}
          </Link>
        );
      },
    },
    {
      title: 'GoogleSheetId',
      dataIndex: 'googleSheetId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent
            type="secondary"
            copyable={{ text: item.googleSheetId }}
          />
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitPartnerForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      await partnerStore.create(form.getValues());
    } else {
      await partnerStore.update(form.getValues()._id, form.getValues());
    }
  };

  const onDelete = async (id: string) => {
    await partnerStore.delete(id);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<PartnerDrawer form={form} />}
      onSubmit={submitPartnerForm}
      columns={columns}
      isEditAllowed
      onDelete={onDelete}
      isCreateAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default Partners;
