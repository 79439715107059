import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { Pages, SidebarItem } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import SidebarDrawer, {
  SidebarItemForm,
} from './application-config-sidebar-drawer';
import { useStore } from '../../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { pick } from 'lodash';

interface Props {
  sidebar: SidebarItem[];
  onSave: (sidebar: SidebarItem[]) => void;
}

const Sidebar: FunctionComponent<Props> = ({ sidebar, onSave }: Props) => {
  const form = useForm<SidebarItemForm>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const getDefaultValues = (
    sidebarItem?: SidebarItemForm
  ): Partial<SidebarItemForm> => {
    if (sidebarItem) {
      return sidebarItem;
    }
    return {
      page: Pages.Overview,
      label: '',
    };
  };

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const columns: ColumnsType<SidebarItem> = [
    {
      title: 'Page',
      dataIndex: 'page',
      align: 'center',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.page}</TextComponent>;
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.label}</TextComponent>;
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: '10%',
      render: (_, item, index) => {
        return <TextComponent>{index + 1}</TextComponent>;
      },
    },
  ];

  const requestPage = async () => {
    return {
      total: sidebar.length,
      results: sidebar.map((item, index) => ({
        ...item,
        _id: item.page,
      })),
    };
  };

  const submitSidebarForm = async (isEdit?: boolean) => {
    const sidebarItem = form.getValues();

    if (isEdit) {
      const index = sidebar.findIndex((item) => item.page === sidebarItem.page);
      sidebar.splice(index, 1);
    }

    const placement = sidebarItem.order - 1;
    const sidebarItemWithoutOrder = pick(sidebarItem, ['page', 'label']);

    sidebar.splice(placement, 0, sidebarItemWithoutOrder);

    onSave(sidebar);
  };

  const onDelete = (page: string) => {
    const index = sidebar.findIndex((item) => item.page === page);

    if (index !== -1) {
      sidebar.splice(index, 1);
      onSave(sidebar);
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<SidebarDrawer form={form} />}
      onSubmit={submitSidebarForm}
      columns={columns}
      isEditAllowed
      isCreateAllowed
      onDelete={onDelete}
      requestNextPage={requestPage}
      initialLimit={10}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default observer(Sidebar);
