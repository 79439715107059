import { EntityStore } from '@monorepo/client-common';
import { PaginationResult, PaymentProvider } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import PaymentProviderService from '../../../services/entities/payment-provider-service';

export default class PaymentProviderStore extends EntityStore<
  PaymentProviderService,
  PaymentProvider
> {
  constructor() {
    const {
      apiFactory: { paymentProviderService },
    } = getEnv();

    super(paymentProviderService);
  }

  async list(): Promise<PaginationResult<any>> {
    return this.service.list();
  }
}
