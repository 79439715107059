import { FormImage, FormInput, FormSelect } from '@monorepo/react-components';
import { Banner, BannerPlacement } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type BannerForm = Pick<Banner, 'name' | 'placement' | 'link'> & {
  images: {
    desktopBanner: File | null;
    tabletBanner?: File;
    mobileBanner?: File;
  };
};

export const BannersDrawer: FunctionComponent<{
  form: UseFormReturn<BannerForm>;
}> = ({ form }) => {
  const placementsOptions = Object.entries(BannerPlacement).map(
    ([key, value]) => ({
      label: key,
      value,
    })
  );

  const selectedPlacement = form.watch('placement');

  useEffect(() => {
    form.resetField('images.tabletBanner');
    form.resetField('images.mobileBanner');
  }, [selectedPlacement]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          form={form}
          text={'Banner Name'}
          controllerProps={{
            name: 'name',
            rules: {
              required: {
                value: true,
                message: 'Name is required',
              },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          placeholder="https://example.com"
          text={'Link'}
          controllerProps={{
            name: 'link',
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          options={placementsOptions}
          controllerProps={{
            name: 'placement',
            rules: {
              required: { value: true, message: 'Placement is required' },
            },
          }}
          text={'Placement'}
        />
      </Col>
      <Col span={24}>
        <FormImage
          fileName={`desktopBanner`}
          form={form}
          allowedFormats={['image/webp']}
          maxFileSize={2}
          controllerProps={{
            name: 'images.desktopBanner',
            rules: {
              required: { value: true, message: 'Desktop banner is required' },
            },
          }}
          text={'Desktop Banner'}
        />
        {selectedPlacement !== BannerPlacement.Sider && (
          <>
            <FormImage
              fileName={`tabletBanner`}
              form={form}
              allowedFormats={['image/webp']}
              maxFileSize={2}
              controllerProps={{
                name: 'images.tabletBanner',
              }}
              text={'Tablet Banner'}
            />
            <FormImage
              fileName={`mobileBanner`}
              form={form}
              allowedFormats={['image/webp']}
              maxFileSize={2}
              controllerProps={{
                name: 'images.mobileBanner',
              }}
              text={'Mobile Banner'}
            />
          </>
        )}
      </Col>
    </Row>
  );
};
