import { FormInputNumber, FormSelect } from '@monorepo/react-components';
import { ApplicationContract, ContractType } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const ContractDrawer: FunctionComponent<{
  form: UseFormReturn<ApplicationContract>;
}> = ({ form }) => {
  const componentOptions = Object.values(ContractType).map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          placeholder="type"
          text={'Type'}
          form={form}
          options={componentOptions}
          controllerProps={{
            name: 'type',
            rules: {
              required: { value: true, message: 'type is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInputNumber
          text={'Version'}
          form={form}
          controllerProps={{
            name: 'currentVersion',
            rules: {
              required: { value: true, message: 'version is required' },
            },
          }}
        />
      </Col>
    </Row>
  );
};

export default ContractDrawer;
