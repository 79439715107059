import { Program, WithdrawRulesType } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormModalActions } from '../../form-modal/form-modal';
import WithdrawRulesForm from '../../../components/withdraw-rules-form/withdraw-rules-form';

interface Props {
  program: Program;
  onSave: (item: Program) => void;
}

const ProgramWithdrawRules: FunctionComponent<Props> = ({
  program,
  onSave,
}) => {
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] =
    program.config.withdrawRules?.map((rule) => {
      return {
        key: rule.type,
        label: rule.type,
        span: 1,
        children: rule.value,
      };
    }) || [];
  const availableFields = Object.values(WithdrawRulesType);
  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => programFormRef.current.open(program)}
        >
          {items.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {items.length > 0 && (
          <Descriptions column={6} layout="vertical" bordered items={items} />
        )}
        <WithdrawRulesForm
          availableFields={availableFields}
          name="config.withdrawRules"
          onSave={onSave}
          ref={programFormRef}
        />
      </Col>
    </Row>
  );
};

export default ProgramWithdrawRules;
