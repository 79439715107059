import { AccountStatus } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';

interface Props {
  status: AccountStatus;
}

export const AccountStatusTag: FunctionComponent<Props> = ({
  status = AccountStatus.Active,
}) => {
  const accountStatusToColor = {
    success: AccountStatus.Active === status,
    warning: AccountStatus.PendingCreation === status,
    error: AccountStatus.Disabled === status,
  };

  return (
    <Tag
      style={{ textTransform: 'capitalize' }}
      color={classNames(accountStatusToColor)}
    >
      {status}
    </Tag>
  );
};
