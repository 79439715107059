import { Banner, BannerPlacement } from '@monorepo/types';
import { FunctionComponent } from 'react';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import { ColumnsType } from 'antd/es/table';
import { BannerForm, BannersDrawer } from './application-config-banners-drawer';
import { observer } from 'mobx-react-lite';

interface Props {
  banners: Banner[];
  onSave: (formData: FormData) => void;
}

const defaultBanner = {
  name: '',
  placement: BannerPlacement.Header,
  link: '',
  images: {
    desktopBanner: null,
  },
};

const getDefaultValues = (): BannerForm => {
  return defaultBanner;
};

const Banners: FunctionComponent<Props> = ({ banners, onSave }) => {
  const form = useForm<BannerForm>({
    mode: 'all',
  });

  const {
    dataStore: { userStore, applicationConfigStore },
  } = useStore();

  const requestPage = async () => {
    banners = (await applicationConfigStore.applicationConfig.banners) || [];
    return {
      total: banners.length,
      results: banners.map((banner) => ({
        _id: banner.name,
        ...banner,
      })),
    };
  };

  const columns: ColumnsType<Banner> = [
    {
      title: 'Banner Name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Placement',
      dataIndex: 'placement',
      width: '10%',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.placement}</TextComponent>;
      },
    },
    {
      title: 'Link To',
      dataIndex: 'to',
      width: '10%',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.link}</TextComponent>;
      },
    },
    {
      title: 'Desktop URL',
      dataIndex: 'desktopUrl',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {item.images.desktopUrl}
          </TextComponent>
        );
      },
    },
    {
      title: 'Tablet URL',
      dataIndex: 'tabletUrl',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.images.tabletUrl}</TextComponent>
        );
      },
    },
    {
      title: 'Mobile URL',
      dataIndex: 'mobileUrl',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.images.mobileUrl}</TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();

    if (form.getValues().placement === BannerPlacement.Header) {
      const headerBanners = banners.filter(
        (banner) => banner.placement === BannerPlacement.Header
      );

      if (headerBanners.length > 0) {
        form.setError('placement', {
          message: 'Header banner already exists',
        });
        return false;
      }
    }

    return form.formState.isValid;
  };

  const submitForm = async () => {
    const { images, ...banner } = form.getValues();

    const formData = new FormData();

    formData.append('newBanner', JSON.stringify(banner));

    for (const [key, image] of Object.entries(images)) {
      if (image) {
        formData.append(key, image);
      }
    }

    await onSave(formData);
  };

  const onDelete = async (name: string) => {
    const index = banners.findIndex((item) => item.name === name);

    const deletedPlacement = banners[index].placement;

    if (index !== -1) {
      banners.splice(index, 1);
    }

    await applicationConfigStore.deleteBanner(banners, name, deletedPlacement);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      onSubmit={submitForm}
      columns={columns}
      drawerBody={<BannersDrawer form={form} />}
      isCreateAllowed
      onDelete={onDelete}
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};
export default observer(Banners);
