import React, { FunctionComponent } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import Login from '../../components/login/login';

const AutoLogin: FunctionComponent<{ loginUrl: string }> = ({ loginUrl }) => {
  window.location.href = loginUrl;

  return null;
};

const LoggedOut = () => {
  const {
    uiStore: { googleLoginUrl },
  } = useStore();
  const initialUrl = localStorage.getItem('initialUrl');

  if (!initialUrl) {
    localStorage.setItem('initialUrl', window.location.hash);
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<AutoLogin loginUrl={googleLoginUrl} />}
        />
        <Route path="/" element={<Login loginUrl={googleLoginUrl} />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
};

export default LoggedOut;
