import {
  FormDatePicker,
  FormInput,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import React, { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import { CreditAction, CreditType } from '@monorepo/types';
import { Col, Flex, Row } from 'antd';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import dayjs, { Dayjs } from 'dayjs';

type AddCreditPayload = {
  userId: string;
  amount: string;
  type: CreditType;
  action: CreditAction;
  notes: string;
  expirationDate: Dayjs;
};

export interface AddCreditModalRef {
  open: (item: AddCreditPayload) => void;
}

type AddCreditProps = {
  onSave: () => void;
};

const DEFAULT_CREDIT_EXPIRY = dayjs().add(3, 'month');

const AddCreditModal = forwardRef<FormModalActions, AddCreditProps>(
  ({ onSave }, ref) => {
    const {
      dataStore: { usersStore },
    } = useStore();

    const form = useForm<AddCreditPayload>({ mode: 'onChange' });
    const [selectedUserId, setSelectedUserId] = useState<string>('');

    const onUserSelect = (userId: string) => {
      setSelectedUserId(userId);
    };

    return (
      <FormModal
        title={'Add credit to user'}
        ref={ref}
        form={form}
        width={'50%'}
        onSave={async (item: AddCreditPayload) => {
          const { userId, amount, type, action, notes, expirationDate } = item;
          const expiryDate = expirationDate.toISOString();

          await usersStore.addCredit(
            userId || selectedUserId,
            amount,
            type,
            action,
            notes,
            expiryDate
          );

          onSave();
        }}
      >
        <Row gutter={[15, 15]}>
          <Col span={8}>
            <FormInput
              placeholder="100"
              text={'Amount'}
              form={form}
              controllerProps={{
                name: 'amount',
                rules: {
                  required: 'Missing Amount',
                  min: { value: 0, message: 'Minimum is 0' },
                },
              }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'action',
              }}
              text={'Action'}
              options={Object.values(CreditAction).map((action) => ({
                label: (
                  <TextComponent capitalize={true}>{action}</TextComponent>
                ),
                value: action,
              }))}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'type',
              }}
              text={'Type'}
              options={Object.values(CreditType).map((type) => ({
                label: <TextComponent capitalize={true}>{type}</TextComponent>,
                value: type,
              }))}
            />
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col span={12}>
            <FormInput
              placeholder="comment"
              text={'Comment'}
              form={form}
              controllerProps={{
                name: 'notes',
              }}
            />
          </Col>
          <Col span={12}>
            <FormDatePicker
              placeholder="expirationDate"
              text={'Expiry'}
              form={form}
              controllerProps={{
                rules: {
                  required: true,
                  validate: (date: Dayjs) => {
                    if (date.isAfter(dayjs())) {
                      return true;
                    }
                    return 'Date must be in future';
                  },
                },
                name: 'expirationDate',
                defaultValue: DEFAULT_CREDIT_EXPIRY,
              }}
            />
          </Col>
          {!form.getValues('userId') && (
            <Col span={12}>
              <Flex vertical>
                <TextComponent>Search User</TextComponent>
                <UserAutocomplete onSelect={(userId) => onUserSelect(userId)} />
              </Flex>
            </Col>
          )}
        </Row>
      </FormModal>
    );
  }
);

export default AddCreditModal;
