import 'reflect-metadata';
import 'core-js/stable/array/at';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import createStore, { Environment } from './helpers/create-store';
import { StoreProvider } from './helpers/store-provider';

import './index.scss';
import 'antd/dist/reset.css';

import 'rc-tooltip/assets/bootstrap_white.css';
import { Application } from '@monorepo/types';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';
const project = import.meta.env.VITE_PROJECT || Application.TTP;
const initApp = async () => {
  let envConfig: Environment;

  try {
    envConfig = await fetch(`/${project}/${reactEnv}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json());
  } catch {
    envConfig = await fetch(`/${project}/development.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json());
  }

  const { rootStore } = createStore({ envConfig });
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    console.warn(`Root element not found`);
    return;
  }

  const reactRoot = createRoot(rootElement);

  await rootStore.uiStore.initTheme();

  reactRoot.render(
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>
  );
};

initApp();
