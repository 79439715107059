import { AffiliationDiscount } from '@monorepo/types';
import { EntityStore } from '@monorepo/client-common';
import DiscountsService from '../../../services/entities/discounts-service';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export default class DiscountsStore extends EntityStore<
  DiscountsService,
  AffiliationDiscount
> {
  constructor() {
    const {
      apiFactory: { discountsService },
    } = getEnv();

    super(discountsService);
  }
}
