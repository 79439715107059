import BlacklistEmails from '../application-config/blacklist-emails/blacklist-emails';
import { BlacklistEmail } from '@monorepo/types';
import { message } from 'antd';
import { useStore } from '../../helpers/use-store';

const BlacklistUsers = () => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();

  const { applicationConfig } = applicationConfigStore;

  const updateBlacklistEmails = async (blacklistEmails: BlacklistEmail[]) => {
    await applicationConfigStore.updateBlacklistEmails(
      applicationConfig._id,
      blacklistEmails
    );

    message.success('Blacklist emails updated');
  };

  return (
    <BlacklistEmails
      blacklistEmails={applicationConfig.blacklistEmails || []}
      onSave={updateBlacklistEmails}
    />
  );
};

export default BlacklistUsers;
