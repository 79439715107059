import { forwardRef, useImperativeHandle, useState } from 'react';
import { Input, Modal } from 'antd';
import { Button } from '@monorepo/react-components';
import { PayoutWithUser } from './payouts';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';

enum PaymentRequestState {
  RequestCode = 'request-code',
  EnterCode = 'enter-code',
}

export interface PayoutPaymentModalActions {
  open: () => void;
}

interface Props {
  refresh: () => void;
  payout: PayoutWithUser;
}

const PayoutPaymentModal = forwardRef<PayoutPaymentModalActions, Props>(
  ({ payout, refresh }, ref) => {
    const { loadingState, updateLoadingState } = useLoading();
    const [isOpen, setOpen] = useState(false);
    const [paymentRequestState, setPaymentRequestState] =
      useState<PaymentRequestState>(PaymentRequestState.RequestCode);
    const [code, setCode] = useState('');
    const [payoutPaymentRequestId, setPayoutPaymentRequestId] = useState('');
    const {
      dataStore: { payoutStore },
    } = useStore();

    const close = () => {
      setOpen(false);
      setCode('');
      setPayoutPaymentRequestId('');
      setPaymentRequestState(PaymentRequestState.RequestCode);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          open: (item?: any) => {
            setOpen(true);
          },
        };
      },
      []
    );

    const getTitle = () => {
      if (!payout.user) {
        return '';
      }

      return `Payment For: ${payout.user.email} of amount: $${payout.metadata?.userPayoutAfterCommission}`;
    };

    const requestCode = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        const payoutPaymentRequestId = await payoutStore.initiatePayment(
          payout._id
        );

        setPayoutPaymentRequestId(payoutPaymentRequestId);
        setPaymentRequestState(PaymentRequestState.EnterCode);
      } catch (e) {
        console.error(`failed getting code`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const pay = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        await payoutStore.pay(payoutPaymentRequestId, code);

        close();
        refresh();
      } catch (e) {
        console.error(`failed payment`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const getContent = () => {
      if (paymentRequestState === PaymentRequestState.RequestCode) {
        return (
          <Button
            loading={loadingState === LoadingState.Loading}
            onClick={requestCode}
          >
            Request Code
          </Button>
        );
      }

      if (paymentRequestState === PaymentRequestState.EnterCode) {
        return (
          <>
            <Input
              onChange={(e) => setCode(e.target.value)}
              placeholder={`Enter the code you received`}
              style={{ width: '100%' }}
            />
          </>
        );
      }
    };

    return (
      <Modal
        title={getTitle()}
        width={'80%'}
        open={isOpen}
        okText={'Pay'}
        okButtonProps={{
          loading: Boolean(code && loadingState === LoadingState.Loading),
          disabled: !(
            paymentRequestState === PaymentRequestState.EnterCode &&
            code.length > 10
          ),
        }}
        onCancel={close}
        onOk={pay}
      >
        {getContent()}
      </Modal>
    );
  }
);

export default PayoutPaymentModal;
