import { FormInput, FormSelect } from '@monorepo/react-components';
import { EmailTemplate, NotificationName } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const emailTemplateTypes = Object.keys(NotificationName).map((type) => {
  return {
    value: NotificationName[type as keyof typeof NotificationName],
    label: type,
  };
});

export const EmailTemplateDrawer: FunctionComponent<{
  form: UseFormReturn<EmailTemplate>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Template Name"
          text={'Template Name'}
          form={form}
          controllerProps={{
            name: 'templateName',
            rules: {
              required: { value: true, message: 'Name is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'type',
            rules: {
              required: { value: true, message: 'Type is required' },
            },
          }}
          text={'type'}
          options={emailTemplateTypes}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Subject"
          text={'Subject'}
          form={form}
          controllerProps={{
            name: 'subject',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Description"
          text={'description'}
          form={form}
          controllerProps={{
            name: 'description',
          }}
        />
      </Col>
    </Row>
  );
};
