import {
  Order,
  PaginationRequest,
  OrdersRequestFilters,
  UpdateStatusPayload,
  PaymentData,
} from '@monorepo/types';

import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export default class OrdersStore {
  paginate(paginateRequest: PaginationRequest) {
    const {
      apiFactory: { ordersService },
    } = getEnv();
    return ordersService.paginate<Order>(paginateRequest);
  }

  searchByOrderUniqId(query: OrdersRequestFilters) {
    const {
      apiFactory: { ordersService },
    } = getEnv();
    return ordersService.searchByOrderUniqId(query);
  }

  async updateStatus(id: string, item: UpdateStatusPayload) {
    const {
      apiFactory: { ordersService },
    } = getEnv();
    return ordersService.updateStatus({ id, ...item });
  }

  async updatePaymentData(uniqId: string, paymentData: PaymentData) {
    const {
      apiFactory: { ordersService },
    } = getEnv();
    return ordersService.updatePaymentData({ uniqId, paymentData });
  }
}
