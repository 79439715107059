import { TextComponent } from '@monorepo/react-components';
import { Abilities, AccountState, ACCOUNT_STATE_TITLES } from '@monorepo/types';
import { Flex } from 'antd';
import { FunctionComponent } from 'react';
import ProtectedElement from '../../components/protected-element/protected-element';
import { EditStateModal } from './edit-state-modal/edit-state-modal';

type Props = {
  id: string;
  state: AccountState;
  onSave: () => void;
};

const AccountStateCell: FunctionComponent<Props> = ({ id, state, onSave }) => {
  const initialActiveStates = Object.keys(state || {}).filter((key) =>
    Boolean(state[key as keyof AccountState])
  );

  const activeStatesTitles = initialActiveStates
    .map((key) => ACCOUNT_STATE_TITLES[key as keyof AccountState])
    .join(', ');

  return (
    <Flex gap={10} align="center">
      <Flex style={{ width: '150px' }}>
        <TextComponent
          type="secondary"
          ellipsis={{ tooltip: activeStatesTitles }}
        >
          {activeStatesTitles}
        </TextComponent>
      </Flex>
      <ProtectedElement abilities={[Abilities.ManageAccounts]}>
        <EditStateModal id={id} state={state} onSave={onSave} />
      </ProtectedElement>
    </Flex>
  );
};

export default AccountStateCell;
