import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { AffiliationDiscount } from '@monorepo/types';
import {
  FormInput,
  FormInputNumber,
  FormRadioGroup,
} from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';

export const DiscountGroupDrawer: FunctionComponent<{
  form: UseFormReturn<AffiliationDiscount>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Affiliate Id"
          text={'Affiliate Id'}
          form={form}
          controllerProps={{
            name: 'affiliateId',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Group name"
          text={'Group Name'}
          form={form}
          controllerProps={{
            name: 'groupName',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInputNumber
          placeholder={'0'}
          props={{ addonAfter: '%' }}
          text={'Discount percentage'}
          form={form}
          controllerProps={{
            name: 'discountPercentage',
            rules: {
              min: { value: 0, message: 'Minimum discount percentage is 0%' },
              max: {
                value: 100,
                message: 'Maximum discount percentage is 100%',
              },
            },
          }}
        />
        <FormInputNumber
          placeholder={'0'}
          props={{ addonAfter: '%' }}
          text={'Commission percentage'}
          form={form}
          controllerProps={{
            name: 'commissionPercentage',
            rules: {
              min: { value: 0, message: 'Minimum commission percentage is 0%' },
              max: {
                value: 100,
                message: 'Maximum commission percentage is 100%',
              },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormRadioGroup
          form={form}
          text={'Reoccurring discount'}
          options={[
            {
              value: false,
              text: 'No',
            },
            {
              value: true,
              text: 'Yes',
            },
          ]}
          controllerProps={{
            name: 'recurring',
            defaultValue: false,
          }}
        />
      </Col>
    </Row>
  );
};
