import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import FormModal, {
  FormModalActions,
} from '../../features/form-modal/form-modal';
import { FormCheckboxGroup } from '@monorepo/react-components';
import { AddOnType } from '@monorepo/types';

interface Props {
  onSave: (item: any) => void;
}

const AddOnAppConfigForm = forwardRef<FormModalActions, Props>(
  ({ onSave }, ref) => {
    const form = useForm<any>({ mode: 'onChange' });

    const availableFields = Object.values(AddOnType).map((type) => {
      return {
        label: type,
        value: type,
      };
    });

    return (
      <FormModal
        title={'Update Add Ons'}
        ref={ref}
        form={form}
        width="40%"
        onSave={async (item) => {
          onSave(item);
        }}
      >
        <FormCheckboxGroup
          form={form}
          controllerProps={{
            name: 'addOns',
          }}
          options={availableFields}
          vertical={true}
        />
      </FormModal>
    );
  }
);

export default AddOnAppConfigForm;
