import { EntityStore } from '@monorepo/client-common';
import { PaginationResult, RoleAbility } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import RoleAbilityService from '../../../services/entities/role-ability-service';

export default class EmailTemplateStore extends EntityStore<
  RoleAbilityService,
  RoleAbility & { _id: string }
> {
  constructor() {
    const {
      apiFactory: { roleAbilityService },
    } = getEnv();

    super(roleAbilityService);
  }

  async list(): Promise<PaginationResult<any>> {
    return this.service.list();
  }
}
