import { User } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';

interface Props {
  onSelect: (userId: string) => void;
  byEmail?: boolean;
}

const UserAutocomplete: FunctionComponent<Props> = ({
  onSelect,
  byEmail = false,
}) => {
  const {
    dataStore: { usersStore },
  } = useStore();

  const searchInputChange = async (email: string) => {
    const users = (await usersStore.search({
      email,
    })) as Partial<User>[];

    return users.map((user) => ({
      label: user.email,
      value: user.userId,
    })) as Option[];
  };

  return (
    <Autocomplete
      width="200px"
      onSelect={(user) => {
        if (byEmail) {
          onSelect(user.label);
        } else {
          onSelect(user.value);
        }
      }}
      onClear={() => onSelect('')}
      searchInputChange={searchInputChange}
      placeholder={'trader@email.com'}
    />
  );
};

export default UserAutocomplete;
