import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface HttpServiceConfig {
  baseURL?: string;
}

export class HttpService {
  private token = '';
  private axiosInstance: AxiosInstance;

  constructor({ baseURL }: HttpServiceConfig) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async get<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
    const response = await this.axiosInstance.get(url, options);

    return response.data as T;
  }

  async post<T, R>(url: string, data?: T, options: AxiosRequestConfig = {}) {
    const response = await this.axiosInstance.post(url, data, {
      headers: options.headers,
    });

    return response.data as R;
  }

  async put<T, R>(url: string, data: T) {
    const response = await this.axiosInstance.put(url, data);

    return response.data as R;
  }

  async delete<R>(url: string) {
    const response = await this.axiosInstance.delete(url);

    return response.data as R;
  }

  removeToken() {
    this.token = '';
    this.axiosInstance.defaults.headers.common['Authorization'] = ``;
  }

  setToken(token: string, refreshToken: () => Promise<string>) {
    let isRetried = false;
    this.token = token;

    this.axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${this.token}`;

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
          if (isRetried) {
            return Promise.reject(error);
          }

          isRetried = true;
          const newToken = await refreshToken();

          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          this.token = newToken;
          this.axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${this.token}`;
          return await this.axiosInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }
}
