export enum PagePath {
  Root = '/',
  Login = '/login',
  Register = '/register',
  Overview = '/overview',
  Orders = '/orders',
  Profile = '/profile',
  Affiliation = '/affiliate',
  Achievements = '/achievements',
  Partners = '/partners',
  ForgotPassword = '/forgot-password',
  NewAccount = '/new-account',
  NewAddon = '/new-addon',
  Autochartist = '/autochartist',
  AssetsSpecifications = '/assets-specifications',
  HubDownloads = '/hub-downloads',
  HubVod = '/hub-vod',
  TradingPlan = '/trading-plan',
  AffiliateKit = '/affiliate-kit',
}

export enum Pages {
  Overview = 'overview',
  Orders = 'orders',
  Profile = 'profile',
  Affiliation = 'affiliation',
  Achievements = 'achievements',
  Autochartist = 'autochartist',
  AssetsSpecifications = 'assets-specifications',
  HubDownloads = 'hub-downloads',
  HubVod = 'hub-vod',
  TradingPlan = 'trading-plan',
  AffiliateKit = 'affiliate-kit',
}

export const StaticPagesUrls = {
  [Pages.Autochartist]: 'https://the5ers.com/hub-autochartist/',
  [Pages.AssetsSpecifications]:
    'https://the5ers.com/hub-assets-specifications/',
  [Pages.HubDownloads]: 'https://the5ers.com/hub-downloads/',
  [Pages.HubVod]: 'https://the5ers.com/hub-vod/',
  [Pages.TradingPlan]: 'https://the5ers.com/hub-trading-plan/',
  [Pages.AffiliateKit]: 'https://the5ers.com/affiliates-kit/',
};

export const defaultSidebar = [
  {
    page: Pages.Overview,
    label: 'Overview',
  },
  {
    page: Pages.Orders,
    label: 'Orders',
  },
  {
    page: Pages.Profile,
    label: 'Profile',
  },
  {
    page: Pages.Affiliation,
    label: 'Affiliation',
  },
];
