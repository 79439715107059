import GroupService from '../../../services/entities/group-service';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '@monorepo/client-common';
import { GroupType } from '@monorepo/types';

export class GroupStore extends EntityStore<GroupService, GroupType> {
  constructor() {
    const {
      apiFactory: { groupService },
    } = getEnv();

    super(groupService);
  }
}
