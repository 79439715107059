import { Application } from '@monorepo/types';
import Cookies from 'universal-cookie';

export enum AppCookieType {
  OldAffiliation = 'old_affiliation',
}

export enum AppCookieNames {
  TTPAffiliation = 'ttp_ref',
  The5ersAffiliation = '5ers_ref',
}

enum GoogleCookieNames {
  ClientId = '_ga',
  SessionId = '_ga_',
}

const AppCookieMap: { [key: string]: { [key: string]: AppCookieNames } } = {
  [Application.TTP]: {
    [AppCookieType.OldAffiliation]: AppCookieNames.TTPAffiliation,
  },
  [Application.THE5ERS]: {
    [AppCookieType.OldAffiliation]: AppCookieNames.The5ersAffiliation,
  },
};
export const deleteLeadDynoData = (domain: string) => {
  const cookies = new Cookies();
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach((key) => {
    if (key.toLowerCase().startsWith('ld')) {
      cookies.remove(key, { domain: `${domain}`, path: '/' });
      cookies.remove(key, { domain: `.leaddyno.com`, path: '/' });
    }
  });
};
export const getAppCookie = (
  type: AppCookieType,
  application?: Application
) => {
  const cookies = new Cookies();
  if (!application) {
    return cookies.get(type);
  }

  const applicationCookies = AppCookieMap[application];
  if (!applicationCookies) {
    return;
  }
  const cookieName = applicationCookies[type];
  const cookieValue = cookies.get(cookieName);

  if (!cookieValue) {
    return null;
  }

  const decoded = decodeURI(cookieValue);
  return decoded.replace(/,/g, '');
};

export const deleteAppCookie = (
  type: AppCookieType,
  domain: string,
  application: Application
) => {
  const cookies = new Cookies();
  const applicationCookies = AppCookieMap[application];
  if (!applicationCookies) {
    return;
  }
  const cookieName = applicationCookies[type];
  cookies.remove(cookieName, { domain: `.${domain}`, path: '/' });
};
export const getGoogleClientId = () => {
  try {
    const cookies = new Cookies();

    const googleCookie = cookies.get(GoogleCookieNames.ClientId);

    if (!googleCookie) {
      return '';
    }

    return googleCookie.split('.').slice(-2).join('.');
  } catch (e) {
    console.error(`failed parsing google client id`, e);
    return '';
  }
};

export const getGoogleSessionId = (gaId: string) => {
  if (!gaId) {
    return '';
  }

  try {
    const cookies = new Cookies();

    const slicedGaId = gaId.slice(2);
    const googleCookie = cookies.get(GoogleCookieNames.SessionId + slicedGaId);

    if (!googleCookie) {
      console.error(`Google session cookie not found for gaId: ${gaId}`);
      return '';
    }

    const sessionId = googleCookie.split('.')[2];

    return sessionId;
  } catch (e) {
    console.error(`failed parsing google session coockie`, e);
    return '';
  }
};
