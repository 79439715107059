import { Avatar, Col, Dropdown, MenuProps, Row, Space } from 'antd';
import './header.scss';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useStore } from '../../../helpers/use-store';
import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

const Header: FunctionComponent = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const userName = userStore.fullName;

  const items: MenuProps['items'] = [
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />,
      onClick: () => userStore.logout(),
    },
  ];

  return (
    <div className="header">
      <Row style={{ alignItems: 'center' }} justify="space-between">
        <Col span={4}>Hello, {userName}</Col>
        <Col span={18} className="actions">
          <Space direction="horizontal" size="middle">
            <Dropdown trigger={['click']} menu={{ items }}>
              <Avatar
                src={userStore.currentUser.profileUrl}
                style={{ cursor: 'pointer' }}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default observer(Header);
