import { EditOutlined } from '@ant-design/icons';
import {
  FormSelect,
  TextComponent,
  UserStatusTag,
} from '@monorepo/react-components';
import { Abilities, UserVerificationStatus } from '@monorepo/types';
import { App, Button, Space } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import ProtectedElement from '../../components/protected-element/protected-element';

type UpdateStatusPayload = {
  status: UserVerificationStatus;
};

type UserStatusProps = {
  userId: string;
  status: UserVerificationStatus;
  onSave: () => void;
};

const UserStatus: FunctionComponent<UserStatusProps> = ({
  userId,
  status,
  onSave,
}) => {
  const {
    dataStore: { usersStore },
  } = useStore();
  const { message } = App.useApp();

  const statusFormRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<UpdateStatusPayload>({ mode: 'onChange' });

  return (
    <ProtectedElement
      abilities={[Abilities.ManageUsers]}
      notAllowedElement={<UserStatusTag status={status} />}
    >
      <div className="user-status">
        <Space>
          <UserStatusTag status={status} />
          <Button
            size="small"
            onClick={() => statusFormRef.current.open({ status })}
            icon={<EditOutlined />}
          />
        </Space>
        <FormModal
          title={'Update user status'}
          ref={statusFormRef}
          form={form}
          width={'30%'}
          onSave={async (item: { status: UserVerificationStatus }) => {
            try {
              await usersStore.updateStatus(userId, { status: item.status });
              onSave();
            } catch (e) {
              message.error('Something went wrong');
              console.error(e);
            }
          }}
        >
          <FormSelect
            form={form}
            controllerProps={{
              name: 'status',
            }}
            text={'User status'}
            options={Object.values(UserVerificationStatus).map((status) => ({
              label: <TextComponent capitalize={true}>{status}</TextComponent>,
              value: status,
            }))}
          />
        </FormModal>
      </div>
    </ProtectedElement>
  );
};

export default UserStatus;
