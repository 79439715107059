import React, { FunctionComponent, useState } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import {
  PaymentData,
  PaymentProviderName,
  SupportedCurrencies,
} from '@monorepo/types';
import { useForm } from 'react-hook-form';
import {
  FormInput,
  FormInputNumber,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { InfoCircleTwoTone } from '@ant-design/icons';

interface Props {
  paymentData: PaymentData;
  onSave: (paymentData: PaymentData) => Promise<void>;
  uniqId: string;
}

const OrderPaymentData: FunctionComponent<Props> = ({
  uniqId,
  paymentData = {},
  onSave,
}) => {
  const [visible, setVisible] = useState(false);
  const form = useForm<PaymentData>({
    mode: 'all',
    defaultValues: paymentData,
  });
  const { loadingState, updateLoadingState } = useLoading();

  const handleSave = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      await onSave(form.getValues());
      updateLoadingState(LoadingState.Loaded);
      setVisible(false);
    } catch (e) {
      message.error('Something went wrong');
      updateLoadingState(LoadingState.Error);
    }
  };

  return (
    <>
      <Modal
        title={`Payment details for order with id: ${uniqId}`}
        open={visible}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        okButtonProps={{
          disabled: !form.formState.isValid,
          type: 'primary',
          loading: loadingState === LoadingState.Loading,
        }}
        onOk={handleSave}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormSelect
              form={form}
              controllerProps={{ name: 'currency' }}
              text={'Currency'}
              options={Object.values(SupportedCurrencies).map((currency) => ({
                label: (
                  <TextComponent capitalize={true}>{currency}</TextComponent>
                ),
                value: currency,
              }))}
            />
          </Col>
          <Col span={12}>
            <FormInputNumber
              form={form}
              text={'Amount'}
              controllerProps={{
                name: `amount`,
                rules: { min: { message: 'Must be positive', value: 0 } },
              }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              form={form}
              controllerProps={{ name: 'provider' }}
              text={'Provider'}
              options={Object.values(PaymentProviderName).map((provider) => ({
                label: (
                  <TextComponent capitalize={true}>{provider}</TextComponent>
                ),
                value: provider,
              }))}
            />
          </Col>
          <Col span={12}>
            <FormInput
              text="Transaction Id"
              form={form}
              controllerProps={{
                name: 'transactionID',
              }}
            />
          </Col>
          <Col span={12}>
            <FormInputNumber
              text="Used credits"
              form={form}
              controllerProps={{
                name: 'creditsUsed',
                rules: {
                  min: { message: 'Must be positive', value: 0 },
                },
              }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              text="Paymnet reference"
              form={form}
              controllerProps={{
                name: 'paymentReference',
              }}
            />
          </Col>
          <Col span={24}>
            <FormInput
              text="Card holder name"
              form={form}
              controllerProps={{
                name: 'cardHolderName',
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Button
        type={'text'}
        onClick={() => setVisible(true)}
        icon={<InfoCircleTwoTone />}
      />
    </>
  );
};

export default OrderPaymentData;
