import { useStore } from '../../helpers/use-store';
import { GroupType } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  TextComponent,
  EntityPageWithDrawer,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import ProgramGroupDrawer from './program-group-drawer';
import { CheckOutlined } from '@ant-design/icons';

const getDefaultValues = (group?: GroupType): Partial<GroupType> => {
  return (
    group || {
      name: '',
      subtitle: '',
      description: '',
    }
  );
};

const Groups = () => {
  const form = useForm<GroupType>({
    mode: 'all',
  });
  const {
    dataStore: { groupStore, userStore },
  } = useStore();

  const requestPage = async ({
    filters,
    page,
    limit,
  }: {
    filters?: any;
    page: number;
    limit: number;
  }) => {
    try {
      return await groupStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading groups for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<GroupType> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.subtitle}</TextComponent>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.description}</TextComponent>
        );
      },
    },
    {
      title: 'Ribbon Text',
      dataIndex: ['ribbon', 'html'],
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.ribbon?.html}</TextComponent>
        );
      },
    },
    {
      title: 'Ribbon Color',
      dataIndex: ['ribbon', 'color'],
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.ribbon?.color}</TextComponent>
        );
      },
    },
    {
      title: 'Display Order',
      dataIndex: 'displayOrder',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>{item.displayOrder}</TextComponent>
        );
      },
    },
    {
      title: 'Advised',
      dataIndex: 'advised',
      render: (_, item) => {
        if (item.advised) {
          return <CheckOutlined />;
        }

        return null;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitGroupForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      await groupStore.create(form.getValues());
    } else {
      await groupStore.update(form.getValues()._id, form.getValues());
    }
  };

  return (
    <EntityPageWithDrawer
      form={form}
      columns={columns as ColumnsType}
      onDelete={(id: string) => groupStore.delete(id)}
      isEditAllowed
      isCreateAllowed
      initialLimit={30}
      requestNextPage={requestPage}
      onSubmit={submitGroupForm}
      validateForm={validateForm}
      getDefaultValues={getDefaultValues}
      drawerBody={<ProgramGroupDrawer form={form} />}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default Groups;
