import { FormInputNumber } from '@monorepo/react-components';
import { WithdrawRulesType } from '@monorepo/types';
import { Button, Col, Row, Space } from 'antd';
import { capitalize } from 'lodash';
import { forwardRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import FormModal, {
  FormModalActions,
} from '../../features/form-modal/form-modal';

interface Props {
  name: string;
  onSave: (item: any) => void;
  availableFields: WithdrawRulesType[];
}

const WithdrawRulesForm = forwardRef<FormModalActions, Props>(
  ({ name, onSave, availableFields }, ref) => {
    const form = useForm<any>({ mode: 'onChange' });
    const { fields, append, remove } = useFieldArray<any, any, any>({
      control: form.control,
      name,
    });

    const fieldsToAdd = availableFields.filter((type) => {
      return !fields.some((field) => field.type === type);
    });

    return (
      <FormModal
        title={'Update Withdraw Rules'}
        ref={ref}
        form={form}
        width="40%"
        onSave={async (item) => {
          onSave(item);
        }}
      >
        <Space direction="vertical">
          {fieldsToAdd.map((type) => (
            <Row gutter={[25, 5]} key={type}>
              <Col span={18}>{capitalize(type)}</Col>
              <Col span={4}>
                <Button onClick={() => append({ type, value: 0 })}>Add</Button>
              </Col>
            </Row>
          ))}
          {fields.map((field, index) => (
            <Row gutter={[25, 15]} key={field.type}>
              <Col span={18}>
                <FormInputNumber
                  form={form}
                  placeholder="0"
                  text={capitalize(field.type)}
                  controllerProps={{
                    name: `${name}.${index}.value`,
                    rules: { required: true },
                  }}
                />
              </Col>
              <Col span={4}>
                <Button onClick={() => remove(index)}>Remove</Button>
              </Col>
            </Row>
          ))}
        </Space>
      </FormModal>
    );
  }
);

export default WithdrawRulesForm;
