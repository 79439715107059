import {
  Abilities,
  AccountStatus,
  AccountType,
  DashboardLayouts,
  Program,
  ThemeColors,
  TradingSystem,
} from '@monorepo/types';
import { useForm } from 'react-hook-form';
import React, { forwardRef, useEffect } from 'react';
import { Col, message, Modal, Row } from 'antd';
import {
  Button,
  Divider,
  Flex,
  FormCheckbox,
  FormInput,
  FormInputNumber,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';
import { noop } from 'lodash';
import { useStore } from '../../../helpers/use-store';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import ProtectedElement from '../../../components/protected-element/protected-element';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

interface Props {
  onSave?: () => void;
}

const ProgramOverviewForm = forwardRef<FormModalActions, Props>(
  ({ onSave = noop }, ref) => {
    const {
      dataStore: { programStore, groupStore },
    } = useStore();
    const navigate = useNavigate();

    const form = useForm<Program>({ mode: 'onChange' });
    const programName = form.watch('name');
    const programId = form.watch('_id');

    const groupSelectOptions = groupStore.items.map((group) => ({
      label: group.name,
      value: group.name,
    }));

    const colorOptions = Object.entries(ThemeColors).map(([key, value]) => ({
      value: value,
      label: key,
    }));

    const onDeleteClick = async () => {
      if (!programId) {
        return;
      }

      confirm({
        title: null,
        icon: null,
        okText: 'Delete',
        cancelButtonProps: {
          type: 'primary',
        },
        okButtonProps: {
          type: 'default',
        },
        content: (
          <Flex gap={14} align="center" vertical style={{ width: '100%' }}>
            <WarningOutlined
              style={{ fontSize: 86, color: 'red', marginBottom: 12 }}
            />
            <TextComponent
              textAlign="center"
              style={{ fontSize: 30, fontWeight: 600 }}
            >
              Are you sure?
            </TextComponent>
            <TextComponent textAlign="center">
              {`Are you sure you want to delete program ${programName}. This
              action cannot be undone.`}
            </TextComponent>
          </Flex>
        ),
        async onOk() {
          try {
            await programStore.delete(programId);

            message.success('Program deleted successfully');

            navigate('/programs');
          } catch {
            message.error('Failed to delete program');
          }
        },
      });
    };

    useEffect(() => {
      const init = async () => {
        await groupStore.fetchAll();
      };
      init();
    }, []);

    return (
      <FormModal
        okText="Save Changes"
        destroyOnClose={true}
        footer={(ModalFooter) => (
          <Flex
            justify="space-between"
            align="center"
            style={{ width: '100%' }}
          >
            <ProtectedElement abilities={[Abilities.DeletePrograms]}>
              <Button
                onClick={onDeleteClick}
                disabled={!programId}
                icon={<DeleteOutlined />}
                type="default"
              >
                Delete Program
              </Button>
            </ProtectedElement>
            <Flex align="center">{ModalFooter}</Flex>
          </Flex>
        )}
        title={programId ? `Update Program: ${programName}` : 'Create Program'}
        ref={ref}
        form={form}
        onSave={async (item: Program) => {
          if (item._id) {
            await programStore.update(item._id, item);
          } else {
            await programStore.create(item);
          }

          onSave();
        }}
        style={{
          maxWidth: 1132,
        }}
      >
        <Flex vertical gap={24}>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <FormInput
                form={form}
                text="Program Name"
                placeholder="Program X"
                controllerProps={{
                  rules: { required: 'Missing Name' },
                  name: 'name',
                }}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                form={form}
                controllerProps={{
                  name: 'accountType',
                  rules: { required: 'Missing Account Type' },
                }}
                text={'Account Type'}
                placeholder={'Demo'}
                options={Object.values(AccountType).map((accountType) => ({
                  label: (
                    <TextComponent capitalize={true}>
                      {accountType}
                    </TextComponent>
                  ),
                  value: accountType,
                }))}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                form={form}
                allowClear={true}
                text="Group Name"
                placeholder="High Stakes..."
                controllerProps={{
                  rules: { required: false },
                  name: 'groupName',
                }}
                options={groupSelectOptions}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                form={form}
                placeholder="Active"
                controllerProps={{
                  name: 'accountStatus',
                  rules: { required: true },
                }}
                text={'Account Status'}
                options={Object.values(AccountStatus)
                  .filter(
                    (accountStatus) =>
                      ![AccountStatus.PendingCreation].includes(
                        accountStatus as AccountStatus
                      )
                  )
                  .map((accountStatus) => ({
                    label: (
                      <TextComponent capitalize={true}>
                        {accountStatus}
                      </TextComponent>
                    ),
                    value: accountStatus,
                  }))}
              />
            </Col>
            <Col span={8}>
              <FormInput
                form={form}
                text="Next Program"
                placeholder="program _id"
                controllerProps={{
                  rules: { required: false },
                  name: 'nextProgramId',
                }}
              />
            </Col>

            <Col span={4}>
              <Flex>
                <FormCheckbox
                  form={form}
                  checkboxText="Sellable"
                  controllerProps={{
                    rules: { required: false },
                    defaultValue: false,
                    name: 'sellable',
                  }}
                />
                <FormCheckbox
                  form={form}
                  checkboxText="Enabled"
                  controllerProps={{
                    rules: { required: false },
                    defaultValue: false,
                    name: 'enabled',
                  }}
                />
              </Flex>
            </Col>
            <Col span={4} />
          </Row>
          <Divider style={{ margin: 0 }} />
          <TextComponent weight={700}>Program Management</TextComponent>
          <Row gutter={[24, 24]}>
            <Col span={6}>
              <FormInput
                form={form}
                text="Group"
                placeholder={'5PERMINI'}
                controllerProps={{ rules: { required: true }, name: 'groupId' }}
              />
            </Col>

            <Col span={6}>
              <FormInput
                form={form}
                text="Program Initials"
                placeholder={'HS'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.programInitials',
                }}
              />
            </Col>

            <Col span={6}>
              <FormInput
                form={form}
                text="Termination Group"
                placeholder={'TERMINATION'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.terminationGroupId',
                }}
              />
            </Col>
            <Col span={6}>
              <FormSelect
                form={form}
                controllerProps={{
                  name: 'tradingSystem',
                  rules: { required: true },
                }}
                text={'Trading System'}
                placeholder={'Match Trader'}
                options={Object.values(TradingSystem).map((accountType) => ({
                  label: (
                    <TextComponent capitalize={true}>
                      {accountType}
                    </TextComponent>
                  ),
                  value: accountType,
                }))}
              />
            </Col>

            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Price"
                placeholder={'100,000'}
                controllerProps={{ rules: { required: true }, name: 'price' }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="User Profit Split"
                placeholder={'80%'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.userPayoutPercentage',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Inactivity Days"
                placeholder={'1'}
                controllerProps={{
                  rules: {
                    required: false,
                    min: { value: 0, message: 'Minimum 0' },
                  },
                  name: 'config.inactivityDays',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Expiry Days"
                placeholder={'1'}
                controllerProps={{
                  rules: {
                    required: false,
                    min: { value: 1, message: 'Minimum 1' },
                  },
                  name: 'expiryDays',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Buying Power"
                placeholder={'$20,000'}
                controllerProps={{
                  rules: { required: true },
                  name: 'config.buyingPower',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Daily Pause"
                placeholder={'$300'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.dailyPause',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Daily Pause Percentage"
                placeholder={'5%'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.dailyPausePercentage',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Daily Loss Percentage"
                placeholder={'$300'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.dailyLossPercentage',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInput
                form={form}
                text="Program Eligibility _id"
                placeholder="Program Eligibility _id"
                controllerProps={{
                  rules: { required: false },
                  name: 'config.programEligibility',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Max Loss"
                placeholder={'$900'}
                controllerProps={{
                  rules: { required: true },
                  name: 'config.maxLoss',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Leverage"
                placeholder={'10'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.leverage',
                }}
              />
            </Col>
            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Withdraw Target"
                placeholder={'$10,000'}
                controllerProps={{
                  rules: { required: false },
                  name: 'config.withdrawTarget',
                }}
              />
            </Col>

            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Refundable"
                placeholder={'100%'}
                controllerProps={{
                  rules: {
                    required: false,
                    min: {
                      value: 0,
                      message: 'refund percent must be greater or equal to 0%',
                    },
                    max: {
                      value: 100,
                      message: 'refund percent must be at most 100%',
                    },
                  },
                  name: 'config.refundable',
                }}
              />
            </Col>

            <Col span={6}>
              <FormInputNumber
                form={form}
                text="Target"
                placeholder={'$10,000'}
                controllerProps={{
                  rules: { required: true },
                  name: 'config.target',
                }}
              />
            </Col>
            <Col span={6}>
              <FormSelect
                form={form}
                controllerProps={{ name: 'config.dashboardLayout' }}
                text={'Dashboard Layout'}
                placeholder={'TTP'}
                options={Object.values(DashboardLayouts).map((layout) => ({
                  label: (
                    <TextComponent capitalize={true}>{layout}</TextComponent>
                  ),
                  value: layout,
                }))}
              />
            </Col>
            <Col span={6} />
            <Col span={24}>
              <TextComponent>Restrictions</TextComponent>
              <Row>
                <Col span={4}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Enable News"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'config.enableNews',
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Disable Night Trade"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'config.disableNightTrade',
                    }}
                  />
                </Col>
                <Col span={4}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Advised"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'advised',
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <TextComponent>Next Level</TextComponent>
              <Row>
                <Col span={4}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Transfer Funds"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'config.transferFunds',
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Ignore Profit Split On Target"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'config.ignoreProfitSplitOnTarget',
                    }}
                  />
                </Col>
                <Col span={4}>
                  <FormCheckbox
                    form={form}
                    checkboxText="New User"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'newUser',
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FormCheckbox
                    form={form}
                    checkboxText="Transfer Funds Subtract Base Balance"
                    controllerProps={{
                      rules: { required: false },
                      defaultValue: false,
                      name: 'config.transferFundsSubtractBaseBalance',
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider style={{ margin: 0 }} />
          <TextComponent weight={700}>Ribbon Management</TextComponent>
          <Row gutter={[24, 24]}>
            <Col span={6}>
              <FormInput
                form={form}
                text={'Ribbon Text (use <b></b> for bold)'}
                controllerProps={{
                  rules: { required: false },
                  name: 'ribbon.html',
                }}
              />
            </Col>
            <Col span={6}>
              <FormSelect
                form={form}
                text="Ribbon Color"
                controllerProps={{
                  rules: { required: false },
                  name: 'ribbon.color',
                  defaultValue: ThemeColors.colorPrimary,
                }}
                options={colorOptions}
              />
            </Col>
          </Row>
        </Flex>
      </FormModal>
    );
  }
);

export default ProgramOverviewForm;
