import {
  EntityPageBaseRef,
  Flex,
  TextComponent,
} from '@monorepo/react-components';
import { ColumnsType } from 'antd/es/table';

import { EntityPageWithDrawer } from '@monorepo/react-components';
import { BlacklistEmail } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { BlacklistEmailDrawer } from '../../blacklist-email-drawer/blacklist-email-drawer';
import { useStore } from '../../../helpers/use-store';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Autocomplete from '../../../components/autocomplete/autocomplete';

const getDefaultValues = (
  blacklistEmail?: BlacklistEmail
): Partial<BlacklistEmail> => {
  if (blacklistEmail) {
    return blacklistEmail;
  }
  return {
    email: '',
    reason: '',
  };
};

interface Props {
  blacklistEmails: BlacklistEmail[];
  onSave: (blacklistEmails: BlacklistEmail[]) => void;
}

const BlacklistEmails: FunctionComponent<Props> = ({
  blacklistEmails,
  onSave,
}) => {
  const entityPageRef = useRef({} as EntityPageBaseRef);
  const form = useForm<BlacklistEmail>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const requestPage = async ({ filters = {} }: { filters?: any }) => {
    let emails = blacklistEmails;

    if (filters?.filteredEmail) {
      emails = blacklistEmails.filter(
        (item) => item.email === filters.filteredEmail
      );
    }

    return {
      total: emails.length,
      results: emails.map((blacklistEmail) => ({
        _id: blacklistEmail.email,
        ...blacklistEmail,
      })),
    };
  };

  const columns: ColumnsType<BlacklistEmail> = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.email}</TextComponent>;
      },
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      width: '15%',
      render: (_, item) => {
        return <TextComponent>{item.reason}</TextComponent>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent>
            {dayjs(item.date).format('DD/MM/YYYY HH:mm')}
          </TextComponent>
        );
      },
    },
  ];
  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async () => {
    const blacklistEmail = form.getValues();

    blacklistEmail.date = new Date().toISOString();

    const index = blacklistEmails.findIndex(
      (item) => item.email === blacklistEmail.email
    );

    if (index === -1) {
      blacklistEmails.push(blacklistEmail);
    } else {
      blacklistEmails[index] = blacklistEmail;
    }

    onSave(blacklistEmails);
  };

  const onDelete = async (email: string) => {
    const index = blacklistEmails.findIndex((item) => item.email === email);

    if (index !== -1) {
      blacklistEmails.splice(index, 1);
      onSave(blacklistEmails);
    }
  };

  const options = blacklistEmails.map((blacklistItem) => ({
    label: blacklistItem.email,
    value: blacklistItem.email,
  }));

  return (
    <>
      <Flex justify="space-between">
        <Autocomplete
          searchInputChange={async (value) => {
            return options.filter((option) =>
              option.value.toLowerCase().includes(value.toLowerCase())
            );
          }}
          width="200px"
          options={options}
          onSelect={(user) => {
            entityPageRef.current.reFetch({ filteredEmail: user.value });
          }}
          onClear={() => {
            entityPageRef.current.reFetch({ filteredEmail: '' });
          }}
          placeholder={'trader@email.com'}
        />
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            if (entityPageRef.current?.openDrawer) {
              entityPageRef?.current?.openDrawer(null);
            }
          }}
          type={'primary'}
          style={{ float: 'right' }}
        >
          Create
        </Button>
      </Flex>
      <EntityPageWithDrawer
        ref={entityPageRef}
        getDefaultValues={getDefaultValues}
        form={form}
        validateForm={validateForm}
        drawerBody={<BlacklistEmailDrawer form={form} />}
        onSubmit={submitForm}
        columns={columns}
        onDelete={onDelete}
        isCreateAllowed={false}
        isEditAllowed
        requestNextPage={requestPage}
        abilities={userStore.currentUser.abilities}
      />
    </>
  );
};

export default BlacklistEmails;
