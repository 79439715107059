import {
  CurrencyFilterType as FilterType,
  CurrencyFilter as CurrencyFilterType,
} from '@monorepo/types';
import { Flex, Space, Tag } from 'antd';
import { FunctionComponent } from 'react';

export type Option = {
  value: string;
  label: string;
};

interface Props {
  currencyFilter: CurrencyFilterType | undefined;
}

const CURRENCY_FILTER_TYPES_TO_COLOR = {
  [FilterType.Exclude]: 'red',
  [FilterType.Include]: 'blue',
};

const CurrencyFilter: FunctionComponent<Props> = ({ currencyFilter }) => {
  return currencyFilter?.currencies.length ? (
    <Space>
      <Tag color={CURRENCY_FILTER_TYPES_TO_COLOR[currencyFilter.type]}>
        {currencyFilter.type}
      </Tag>
      <Flex>
        {currencyFilter.currencies.map((currency) => (
          <Tag color="green">{currency}</Tag>
        ))}
      </Flex>
    </Space>
  ) : null;
};

export default CurrencyFilter;
