import { BaseResponse, CrudService } from '@monorepo/client-common';
import { CreditAction, CreditType, UsersRequestFilters } from '@monorepo/types';
import { User } from '@sentry/react';

export default class UsersService extends CrudService {
  static override route = 'users';

  async fetchById(userId: string): Promise<User> {
    const response = await this.httpService.get<BaseResponse<User>>(
      `${this.path}/by-id/${userId}`
    );

    return response.data;
  }

  async search(filters: UsersRequestFilters): Promise<User> {
    const response = await this.httpService.get<BaseResponse<User>>(
      `${this.path}/search`,
      {
        params: filters,
      }
    );

    return response.data;
  }

  async resendConfirmation(id: string) {
    const tokenData = await this.httpService.post<
      { id: string },
      { data: string }
    >(`${this.path}/resend-confirmation`, {
      id,
    });

    return tokenData.data;
  }

  async deleteUserInformation(userId: string) {
    const tokenData = await this.httpService.post<
      { userId: string },
      { data: string }
    >(`${this.path}/delete-user`, {
      userId,
    });

    return tokenData.data;
  }

  async updateStatus(data: {
    id: string;
    verificationData: Record<string, unknown>;
  }) {
    const response = await this.httpService.post<
      { id: string; verificationData: Record<string, unknown> },
      { data: string }
    >(`${this.path}/update-status`, data);

    return response.data;
  }

  async requestKYC(userId: string, email: string) {
    const response = await this.httpService.post<
      { userId: string; email: string },
      { data: string }
    >(`${this.path}/request-kyc`, { userId, email });

    return response.data;
  }

  async addCredit(
    userId: string,
    amount: number,
    type: CreditType,
    action: CreditAction,
    notes: string,
    expirationDate: string
  ) {
    const response = await this.httpService.post<
      {
        userId: string;
        amount: number;
        action: string;
        type: string;
        notes: string;
        expirationDate: string;
      },
      { data: string }
    >(`${this.path}/add-credit`, {
      userId,
      amount,
      action,
      type,
      notes,
      expirationDate,
    });

    return response.data;
  }
}
