import { EntityStore } from '@monorepo/client-common';
import { Coupon } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import CouponService from '../../../services/entities/coupon-service';

export default class CouponStore extends EntityStore<CouponService, Coupon> {
  constructor() {
    const {
      apiFactory: { couponService },
    } = getEnv();

    super(couponService);
  }
}
