import { FormInput } from '@monorepo/react-components';
import { BlacklistEmail } from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const BlacklistEmailDrawer: FunctionComponent<{
  form: UseFormReturn<BlacklistEmail>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Email"
          text={'Email'}
          form={form}
          controllerProps={{
            name: 'email',
            rules: {
              required: { value: true, message: 'Email is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          controllerProps={{
            name: 'reason',
            rules: {
              required: { value: true, message: 'Reason is required' },
            },
          }}
          text={'reason'}
        />
      </Col>
    </Row>
  );
};
