import { Select, SelectProps, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { FunctionComponent, useMemo, useRef, useState } from 'react';

export type Option = {
  value: string;
  label: string;
};

interface Props extends SelectProps {
  searchInputChange: (value: string) => Promise<Option[]>;
  allowTermSearch?: boolean;
  width?: string;
}

const Autocomplete: FunctionComponent<Props> = ({
  onSelect,
  onClear,
  onDeselect,
  searchInputChange,
  allowTermSearch = false,
  placeholder,
  width = '200px',
  mode,
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const items = await searchInputChange(value);

      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      if (allowTermSearch && value) {
        items.unshift({ label: value, value });
      }

      setOptions(items);
      setFetching(false);
    };

    return debounce(loadOptions, 400);
  }, []);

  const clearOptions = () => {
    setOptions([]);
  };

  const onDeselectWrapper = (option: Option) => {
    if (onDeselect) {
      onDeselect(option.value, option);
    }
    clearOptions();
  };

  return (
    <Select
      mode={mode}
      style={{ width }}
      labelInValue
      onSelect={onSelect}
      onClear={onClear}
      onBlur={clearOptions}
      onDeselect={onDeselectWrapper}
      showSearch={true}
      filterOption={false}
      placeholder={placeholder}
      allowClear={true}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  );
};

export default Autocomplete;
