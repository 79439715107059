import { CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TextComponent, Statistic } from '@monorepo/react-components';
import { Program } from '@monorepo/types';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { FormModalActions } from '../../form-modal/form-modal';
import NextProgram from '../next-program/next-program';
import ProgramOverviewForm from '../program-overview/program-overview-form';
import { observer } from 'mobx-react-lite';
import ProgramEligibility from '../program-eligibility/program-eligibility';

interface Props {
  program: Program;
  onSave: () => void;
}

const ProgramOverview: FunctionComponent<Props> = ({ program, onSave }) => {
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Name',
      children: program.name,
    },
    {
      key: 'next-program',
      label: 'Next Program',
      children: <NextProgram program={program} />,
    },
    {
      key: '2',
      label: 'Account Type',
      span: 1,
      children: (
        <TextComponent capitalize={true}>{program.accountType}</TextComponent>
      ),
    },
    {
      key: '3',
      label: 'Initial Account Status',
      span: 1,
      children: (
        <TextComponent capitalize={true}>{program.accountStatus}</TextComponent>
      ),
    },
    {
      key: '4',
      label: 'Group',
      span: 1,
      children: <TextComponent>{program.groupId}</TextComponent>,
    },
    {
      key: 'programInitials',
      label: 'Program Initials',
      span: 1,
      children: <TextComponent>{program.config.programInitials}</TextComponent>,
    },
    {
      key: 'tgroup',
      label: 'Termination Group',
      span: 1,
      children: (
        <TextComponent>{program.config.terminationGroupId}</TextComponent>
      ),
    },
    {
      key: 'ts',
      label: 'Trading System',
      span: 1,
      children: (
        <TextComponent capitalize={true}>{program.tradingSystem}</TextComponent>
      ),
    },
    {
      key: 'price',
      label: 'Price',
      span: 1,
      children: <Statistic value={program.price} prefix={'$'} />,
    },
    {
      key: 'profit-split',
      label: 'Profit Split',
      children: program.config.userPayoutPercentage || 0,
    },
    {
      key: '5',
      label: 'Sellable',
      children: program.sellable ? <CheckOutlined /> : null,
    },
    {
      key: '6',
      label: 'Enabled',
      children: program.enabled ? <CheckOutlined /> : null,
    },
    {
      key: 'new-user',
      label: 'New User',
      children: program.newUser ? <CheckOutlined /> : null,
    },
    {
      key: 'expiry-days',
      label: 'Expiry Days',
      children: program.expiryDays ? program.expiryDays : '-----',
    },
    {
      key: 'transfer-fund',
      label: 'Transfer Funds',
      children: program.config.transferFunds ? (
        <CheckOutlined />
      ) : (
        <MinusCircleOutlined />
      ),
    },
    {
      key: 'transferFundsSubtractBaseBalance',
      label: 'Subtract Base Balance When Transferring funds',
      children: program.config.transferFundsSubtractBaseBalance ? (
        <CheckOutlined />
      ) : (
        <MinusCircleOutlined />
      ),
    },
    {
      key: 'ignoreProfitSplitOnTarget',
      label: 'Ignore Profit Split On Target',
      children: program.config.ignoreProfitSplitOnTarget ? (
        <CheckOutlined />
      ) : (
        <MinusCircleOutlined />
      ),
    },
    {
      key: 'disableNightTrade',
      label: 'Disable Night Trade',
      children: program.config.disableNightTrade ? (
        <CheckOutlined />
      ) : (
        <MinusCircleOutlined />
      ),
    },
    {
      key: 'layout',
      label: 'Layout',
      children: program.config.dashboardLayout ? (
        <TextComponent capitalize={true}>
          {program.config.dashboardLayout}
        </TextComponent>
      ) : (
        'Application Default'
      ),
    },
    {
      key: 'bp',
      label: 'Buying Power',
      children: <Statistic value={program.config.buyingPower} />,
    },
    {
      key: 'dl-pause',
      label: 'Daily Pause',
      children: <Statistic value={program.config.dailyPause} />,
    },
    {
      key: 'dl-pause-per',
      label: 'Daily Pause Percentage',
      children: <Statistic value={program.config.dailyPausePercentage} />,
    },
    {
      key: 'dl-pause-er',
      label: 'Daily Loss Percentage',
      children: <Statistic value={program.config.dailyLossPercentage} />,
    },
    {
      key: 'max-loss',
      label: 'Max Loss',
      children: <Statistic value={program.config.maxLoss} />,
    },
    {
      key: 'target',
      label: 'Target',
      children: <Statistic value={program.config.target} />,
    },
    {
      key: 'w-target',
      label: 'Withdraw Target',
      children: <Statistic value={program.config.withdrawTarget} />,
    },
    {
      key: 'leverage',
      label: 'Leverage',
      children: <Statistic value={program.config.leverage} />,
    },
    {
      key: 'groupName',
      label: 'Group Name',
      span: 1,
      children: <TextComponent>{program?.groupName || '-'}</TextComponent>,
    },
    {
      key: 'advised',
      label: 'Advised',
      span: 1,
      children: program.advised ? <CheckOutlined /> : <MinusCircleOutlined />,
    },
    {
      key: 'inactivityDays',
      label: 'Consecutive Inactivity Days',
      children: (
        <TextComponent>{program.config.inactivityDays || '-'}</TextComponent>
      ),
    },
    {
      key: 'enableNews',
      label: 'Enable News',
      children: program.config.enableNews ? (
        <CheckOutlined />
      ) : (
        <MinusCircleOutlined />
      ),
    },
    {
      key: 'ribbonText',
      label: 'Ribbon Text',
      children: <TextComponent>{program.ribbon?.html || '-'}</TextComponent>,
    },
    {
      key: 'ribbonColor',
      label: 'Ribbon Color',
      children: <TextComponent>{program.ribbon?.color || '-'}</TextComponent>,
    },
    {
      key: 'refundable',
      label: 'Refundable',
      span: 1,
      children: (
        <Statistic value={program.config.refundable || 0} suffix={'%'} />
      ),
    },
    {
      key: 'programEligibility',
      label: 'Program Eligibility',
      children: <ProgramEligibility program={program} />,
    },
  ];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => programFormRef.current.open(program)}
        >
          Edit
        </Button>
      </Col>
      <Col span={24}>
        <Descriptions column={6} layout="vertical" bordered items={items} />
        <ProgramOverviewForm onSave={onSave} ref={programFormRef} />
      </Col>
    </Row>
  );
};

export default observer(ProgramOverview);
