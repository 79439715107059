import { FormInputNumber } from '@monorepo/react-components';
import { PartnerType, Program } from '@monorepo/types';
import { Col, Row } from 'antd';
import { capitalize, noop } from 'lodash';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  onSave?: () => void;
}

const ProgramBoostersForm = forwardRef<FormModalActions, Props>(
  ({ onSave = noop }, ref) => {
    const {
      dataStore: { programStore },
    } = useStore();
    const form = useForm<Program>({ mode: 'onChange' });

    return (
      <FormModal
        title={'Update Boosters'}
        ref={ref}
        form={form}
        width="30%"
        onSave={async (item: Program) => {
          await programStore.updateBoosters(
            item._id,
            item.config.boosters as Record<PartnerType, number>
          );
          onSave();
        }}
      >
        <Row gutter={[25, 5]}>
          {Object.values(PartnerType).map((partnerType) => (
            <Col span={8} key={partnerType}>
              <FormInputNumber
                form={form}
                text={capitalize(partnerType)}
                placeholder="0"
                controllerProps={{
                  name: `config.boosters.${partnerType}`,
                  rules: { required: true },
                }}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
    );
  }
);

export default ProgramBoostersForm;
