import { ApplicationConfig, WithdrawRulesType } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormModalActions } from '../../form-modal/form-modal';
import WithdrawRulesForm from '../../../components/withdraw-rules-form/withdraw-rules-form';

interface Props {
  applicationConfig: ApplicationConfig;
  onSave: (item: ApplicationConfig) => void;
}

const ApplicationConfigAffiliateWithdrawRules: FunctionComponent<Props> = ({
  applicationConfig,
  onSave,
}) => {
  const applicationConfigFormRef = useRef<FormModalActions>(
    {} as FormModalActions
  );

  const items: DescriptionsProps['items'] =
    applicationConfig.affiliateWithdrawRules?.map((rule) => {
      return {
        key: rule.type,
        label: rule.type,
        span: 1,
        children: rule.value,
      };
    }) || [];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() =>
            applicationConfigFormRef.current.open(applicationConfig)
          }
        >
          {items.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {items.length > 0 && (
          <Descriptions column={6} layout="vertical" bordered items={items} />
        )}
        <WithdrawRulesForm
          availableFields={[WithdrawRulesType.MinimumAmount]}
          name="affiliateWithdrawRules"
          onSave={onSave}
          ref={applicationConfigFormRef}
        />
      </Col>
    </Row>
  );
};

export default ApplicationConfigAffiliateWithdrawRules;
