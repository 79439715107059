import { AddOn, AddOnType } from '@monorepo/types';
import { useForm } from 'react-hook-form';
import React, { forwardRef } from 'react';
import { Col, Flex, Row } from 'antd';
import {
  FormInput,
  FormInputNumber,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';
import { useStore } from '../../../helpers/use-store';
import { noop } from 'lodash';
import ProgramAutocomplete from '../../../components/program-autocomplete/program-autocomplete';

interface Props {
  addOn?: AddOn;
  onSave?: () => void;
}

export const AddOnOverviewForm = forwardRef<FormModalActions, Props>(
  ({ addOn, onSave = noop }, ref) => {
    const {
      dataStore: { addOnStore },
    } = useStore();

    const form = useForm<AddOn>({ mode: 'onChange' });

    const typeOptions = Object.values(AddOnType).map((addOnType) => ({
      label: <TextComponent>{addOnType}</TextComponent>,
      value: addOnType,
    }));

    const onDeselectClick = (item: { label: string; value: string }) => {
      const newValue = new Set(form.getValues('programIds') || []);
      newValue.delete(item.value);
      form.setValue('programIds', Array.from(newValue), { shouldDirty: true });
    };

    const onSearchClick = (item: { label: string; value: string }) => {
      const newValue = new Set(form.getValues('programIds') || []);
      newValue.add(item.value);
      form.setValue('programIds', Array.from(newValue), { shouldDirty: true });
    };

    const onClearClick = () => {
      form.setValue('programIds', [], { shouldDirty: true });
    };

    return (
      <FormModal
        title={addOn ? `Update Add On: ${addOn.name}` : 'Create Add On'}
        ref={ref}
        form={form}
        onSave={async (item: AddOn) => {
          if (item._id) {
            await addOnStore.update(item._id, item);
          } else {
            await addOnStore.create(item);
          }

          onSave();
        }}
      >
        <Row gutter={[25, 5]}>
          <Col span={8}>
            <FormInput
              form={form}
              text="Name"
              placeholder="Add On X"
              controllerProps={{
                rules: { required: 'Missing Name' },
                name: 'name',
              }}
            />
          </Col>

          <Col span={8}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'type',
                rules: { required: 'Missing Add On Type' },
              }}
              text={'Add On Type'}
              placeholder={'Renew'}
              options={typeOptions}
            />
          </Col>

          <Col span={8}>
            <FormInputNumber
              form={form}
              text="Price"
              placeholder={'100,000'}
              controllerProps={{ rules: { required: true }, name: 'price' }}
            />
          </Col>
          <Col span={10}>
            <FormInput
              form={form}
              placeholder="Description"
              controllerProps={{
                name: 'description',
                rules: { required: false },
              }}
              text={'Description'}
            />
          </Col>
          <Col span={10}>
            <Flex vertical>
              <TextComponent> Select Program</TextComponent>
              <ProgramAutocomplete
                onDeselect={onDeselectClick}
                mode={'multiple'}
                onSelect={onSearchClick}
                onClear={onClearClick}
              />
            </Flex>
          </Col>
        </Row>
      </FormModal>
    );
  }
);

export default AddOnOverviewForm;
