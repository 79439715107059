import { FormInputNumber } from '@monorepo/react-components';
import {
  ConsistencyRules,
  ConsistentRulesConfig,
  Program,
} from '@monorepo/types';
import { Button, Col, InputNumber, Row, Space } from 'antd';
import { capitalize, noop } from 'lodash';
import { forwardRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  onSave?: () => void;
}

const ProgramConsistencyRulesForm = forwardRef<FormModalActions, Props>(
  ({ onSave = noop }, ref) => {
    const {
      dataStore: { programStore },
    } = useStore();
    const form = useForm<Program>({ mode: 'onChange' });
    const [ruleValue, setRuleValue] = useState<number>(0);
    const { fields, append, remove } = useFieldArray({
      control: form.control,
      name: 'config.consistencyRules',
    });

    const fieldsToAdd = Object.values(ConsistencyRules).filter((rule) => {
      return !fields.some((field) => field.rule === rule);
    });

    const onAddClick = (rule: ConsistencyRules) => {
      append({ rule, value: ruleValue });
      setRuleValue(0);
    };

    return (
      <FormModal
        title={'Update Consistency Rules'}
        ref={ref}
        form={form}
        width="50%"
        onSave={async (item: Program) => {
          await programStore.updateConsistencyRules(
            item._id,
            item.config.consistencyRules as ConsistentRulesConfig[]
          );

          onSave();
        }}
      >
        <Space direction="vertical">
          {fieldsToAdd.map((rule) => (
            <Row gutter={[25, 5]} key={rule}>
              <Col span={18}>
                <div>{capitalize(rule)}</div>
                <InputNumber
                  defaultValue={0}
                  onChange={(value) => setRuleValue(parseFloat(`${value}`))}
                />
              </Col>
              <Col span={4}>
                <Button onClick={() => onAddClick(rule)}>Add</Button>
              </Col>
            </Row>
          ))}
          {fields.map((field, index) => (
            <Row gutter={[25, 15]} key={field.rule}>
              <Col span={18}>
                <FormInputNumber
                  form={form}
                  placeholder="0"
                  text={capitalize(field.rule)}
                  controllerProps={{
                    name: `config.consistencyRules.${index}.value`,
                    rules: { required: true },
                  }}
                />
              </Col>
              <Col span={4}>
                <Button onClick={() => remove(index)}>Remove</Button>
              </Col>
            </Row>
          ))}
        </Space>
      </FormModal>
    );
  }
);

export default ProgramConsistencyRulesForm;
