import { AccountState } from '../account/account';
import {
  AccountIdentifier,
  BaseIdentifiers,
  UserIdentifier,
} from '../common/identifiers';

export type AccountEvent = BaseIdentifiers &
  UserIdentifier &
  AccountIdentifier & {
    event: AccountEventItem;
    metadata?: AccountEventMetadata;
  };

export enum AccountEventItem {
  Created = 'created',
  Delete = 'delete',
  DailyPause = 'daily-pause',
  ResetDailyPause = 'reset-daily-pause',
  DailyLoss = 'daily-loss',
  Termination = 'termination',
  Target = 'target',
  Expired = 'expired',
  Swing = 'swing',
  SwingReset = 'swing-reset',
  ManualEnable = 'enable',
  ManualDisable = 'disable',
  BypassDailyPause = 'bypass-daily-pause',
  ExpirationExtend = 'expiration-extended',
  InactivityDays = 'inactivity-days',
}

export interface AccountEventGeneral {
  eventByUserId: string;
  eventByUserEmail?: string;
}

export interface AccountEventStatus extends AccountEventGeneral {
  state: AccountState;
  prevState: AccountState;
}

export type AccountEventMetadata = AccountEventGeneral &
  Partial<AccountEventStatus>;

export const enum EventColor {
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
  Gray = 'gray',
}

export const eventColorMap: Record<EventColor, AccountEventItem[]> = {
  [EventColor.Green]: [AccountEventItem.Created, AccountEventItem.Target],
  [EventColor.Blue]: [],
  [EventColor.Red]: [
    AccountEventItem.Delete,
    AccountEventItem.DailyPause,
    AccountEventItem.DailyLoss,
    AccountEventItem.Termination,
    AccountEventItem.Expired,
    AccountEventItem.SwingReset,
  ],
  [EventColor.Gray]: [],
};
