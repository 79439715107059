import { EditOutlined } from '@ant-design/icons';
import { TextComponent } from '@monorepo/react-components';
import { App, Button, Space } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import ProtectedElement from '../../components/protected-element/protected-element';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';

type UpdateUserPayload = {
  userId: string;
};

type Props = {
  id: string;
  email: string;
  userId: string;
  onSave: () => void;
};

const AccountUser: FunctionComponent<Props> = ({
  id,
  email,
  userId,
  onSave,
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const { message } = App.useApp();
  const formRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<UpdateUserPayload>({ mode: 'onChange' });

  const onSelectUser = async (userId: string) => {
    form.setValue('userId', userId, { shouldDirty: true });
  };

  const updateUser = async (item: UpdateUserPayload) => {
    if (!item.userId) {
      message.error('Please select a user');
      return;
    }
    try {
      await accountStore.updateUser(id, item.userId);
      onSave();
    } catch (e) {
      message.error('Something went wrong');
      console.error(e);
    }
  };

  return (
    <ProtectedElement
      abilities={[]}
      notAllowedElement={
        <TextComponent ellipsis={true} type="secondary">
          {email || 'N/A'}
        </TextComponent>
      }
    >
      <div className="account-user">
        <Space>
          <TextComponent ellipsis={true} type="secondary">
            {email || 'N/A'}
          </TextComponent>
          <Button
            size="small"
            onClick={() => formRef.current.open({ userId })}
            icon={<EditOutlined />}
          />
        </Space>
        <FormModal
          title={'Update account user'}
          ref={formRef}
          form={form}
          width={'30%'}
          onSave={updateUser}
        >
          <UserAutocomplete onSelect={(userId) => onSelectUser(userId)} />
        </FormModal>
      </div>
    </ProtectedElement>
  );
};

export default AccountUser;
