import { ExclamationCircleFilled } from '@ant-design/icons';
import { LoadingState, useLoading } from '@monorepo/client-common';
import {
  CreditEvents,
  Program,
  ProgramTradingSystemConfig,
  WithdrawRule,
} from '@monorepo/types';
import {
  Button,
  Col,
  Modal,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Typography,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import ProgramBoosters from './program-boosters/program-boosters';
import ProgramConsistencyRules from './program-consistency-rules/program-consistency-rules';
import ProgramOverview from './program-overview/program-overview';
import ProgramPurchaseLimits from './program-purchase-limits/program-purchase-limits';
import ProgramWithdrawRules from './program-withdraw-rules/program-withdraw-rules';
import ProgramCreditEvents from './program-credit-events/program-credit-events';
import ProgramTradingSystems from './program-trading-systems/program-trading-systems';

const { confirm } = Modal;

const ProgramComponent = () => {
  const navigate = useNavigate();
  const {
    dataStore: { programStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [program, setProgram] = useState<Program | null>();
  const { id } = useParams<{ id: string }>();

  const init = async () => {
    try {
      if (!id) {
        throw new Error(`Missing id: ${id}`);
      }

      updateLoadingState(LoadingState.Loading);

      const program = await programStore.fetch(id);

      setProgram(program);

      updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      updateLoadingState(LoadingState.Error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  if (loadingState === LoadingState.Error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    );
  }

  const onDuplicateClick = async () => {
    if (!program) {
      return;
    }

    confirm({
      title: 'Do you want to duplicate program?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          await programStore.duplicate(program._id);

          message.success('Program duplicated successfully');
        } catch {
          message.error('Failed to duplicate program');
        }
      },
    });
  };

  const updateWithdrawRules = async (item: Program) => {
    await programStore.updateWithdrawRules(
      item._id,
      item.config.withdrawRules as WithdrawRule[]
    );

    message.success('Withdraw rules updated');

    init();
  };

  const updateCreditEvents = async (creditEvents: CreditEvents) => {
    if (!program) {
      return;
    }

    await programStore.updateCreditEvents(
      program._id,
      creditEvents as CreditEvents
    );

    message.success('Credit events updated');

    init();
  };

  const updateTradingSystems = async (
    tradingSystems: ProgramTradingSystemConfig[]
  ) => {
    if (!program) {
      return;
    }

    await programStore.updateTradingSystems(program._id, tradingSystems);

    message.success('Trading Systems updated');

    init();
  };

  if (!program) {
    return null;
  }

  return (
    <Row gutter={[15, 15]} className="admin-program">
      <Col span={12}>
        <Typography.Title style={{ marginBottom: '15px' }} level={4}>
          {program.name}
        </Typography.Title>
      </Col>
      <Col push={8} span={4} style={{ textAlign: 'end' }}>
        <Space>
          <Button key="duplicate-btn" onClick={onDuplicateClick}>
            Duplicate
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Tabs
          tabPosition="left"
          renderTabBar={(paneProps, DefaultTabBar) => (
            <DefaultTabBar {...paneProps} mobile={true} />
          )}
          items={[
            {
              label: `Overview`,
              key: 'overview',
              children: <ProgramOverview program={program} onSave={init} />,
            },
            {
              label: `Purchase Limits`,
              key: 'purchase-amount',
              children: (
                <ProgramPurchaseLimits program={program} onSave={init} />
              ),
            },
            {
              label: `Boosters`,
              key: 'boosters',
              children: <ProgramBoosters program={program} onSave={init} />,
            },
            {
              label: `Consistency Rules`,
              key: 'consistency-rules',
              children: (
                <ProgramConsistencyRules program={program} onSave={init} />
              ),
            },
            {
              label: `Withdraw Rules`,
              key: 'withdraw-rules',
              children: (
                <ProgramWithdrawRules
                  program={program}
                  onSave={updateWithdrawRules}
                />
              ),
            },
            {
              label: `Credit Events`,
              key: 'credit-events',
              children: (
                <ProgramCreditEvents
                  creditEvents={program.config.creditEvents}
                  onSave={updateCreditEvents}
                />
              ),
            },
            {
              label: `Trading Systems`,
              key: 'trading-systems',
              children: (
                <ProgramTradingSystems
                  tradingSystems={program.config.tradingSystems || []}
                  onSave={updateTradingSystems}
                />
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ProgramComponent;
