import {
  EntityPageBase,
  EntityPageBaseRef,
  TextComponent,
  Statistic,
} from '@monorepo/react-components';
import {
  CreditAction,
  CreditTransaction,
  CreditType,
  ModifiedUserResponseType,
} from '@monorepo/types';
import { Button, Col, Row, Space, message, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FunctionComponent, useRef, useState } from 'react';
import { useStore } from '../../helpers/use-store';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { FormModalActions } from '../form-modal/form-modal';
import AddCreditModal from './add-credit-modal';

type Filters = {
  userId?: string;
};

type CreditTransactionWithUsers = CreditTransaction & {
  user: any;
  createdBy: ModifiedUserResponseType;
};

const Credits: FunctionComponent = () => {
  const {
    dataStore: { creditStore, userStore },
  } = useStore();

  const [filters, setFilters] = useState<Filters>({});
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);
  const addCreditModalRef = useRef<FormModalActions>({} as FormModalActions);

  const requestPage = async ({
    filters,
    page,
    limit,
  }: {
    filters?: any;
    page: number;
    limit: number;
  }) => {
    try {
      return await creditStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading credits for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onSearchChange = (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }

    setFilters(filters);
    refresh();
  };

  const columns: ColumnsType<CreditTransactionWithUsers> = [
    {
      title: 'Credit ID',
      dataIndex: '_id',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'User Name',
      dataIndex: 'email',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.user.firstName} {item.user.lastName}
          </TextComponent>
        );
      },
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.user.email}
          </TextComponent>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return <Statistic value={item.amount} />;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.type}
          </TextComponent>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.action}
          </TextComponent>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.notes}
          </TextComponent>
        );
      },
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.expirationDate}
          </TextComponent>
        );
      },
    },
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.orderId}
          </TextComponent>
        );
      },
    },
    {
      title: 'Created By User Name',
      dataIndex: 'createdByName',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.createdBy?.firstName} {item.createdBy?.lastName}
          </TextComponent>
        );
      },
    },
    {
      title: 'Created By User Email',
      dataIndex: 'createdByEmail',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.createdBy?.email}
          </TextComponent>
        );
      },
    },
  ];

  return (
    <Space direction="vertical">
      <Row align={'bottom'} justify={'space-between'} gutter={[15, 15]}>
        <Col span={12}>
          <div>Search by email:</div>
          <UserAutocomplete onSelect={(userId) => onSearchChange(userId)} />
        </Col>
        <Col span={12}>
          <Flex align={'end'} justify={'end'}>
            <Button
              key="currency-converter-btn"
              onClick={() =>
                addCreditModalRef.current?.open({
                  userId: '',
                  amount: '',
                  notes: '',
                  type: CreditType.Payment,
                  action: CreditAction.Add,
                })
              }
            >
              Add Credit
            </Button>
          </Flex>
        </Col>
      </Row>
      <EntityPageBase
        columns={columns}
        requestNextPage={requestPage}
        ref={entityPageRef}
        abilities={userStore.currentUser.abilities}
      />
      <AddCreditModal
        ref={addCreditModalRef}
        onSave={() => message.success('Credit added')}
      />
    </Space>
  );
};

export default Credits;
