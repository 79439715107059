import {
  BaseIdentifiers,
  ProductIdentifier,
  TradingSystemIdentifier,
  UserIdentifier,
} from '../common/identifiers';
import { UserInformation } from '../user/user';
import { PaymentProviderName } from '../payment';
import { ProductMetaData } from '../product/product';
import dayjs from 'dayjs';

export enum OrderStatus {
  Pending = 'pending',
  Paid = 'paid',
  Declined = 'declined',
  Processing = 'processing',
  Refunded = 'refunded',
}

export const OrderStatusCompleted = [OrderStatus.Paid, OrderStatus.Refunded];

export interface PaymentData {
  provider: PaymentProviderName;
  transactionID: string;
  currency: string;
  amount: number;
  paymentReference: string;
  cardHolderName: string;
  useCredits?: boolean;
  creditsUsed?: number;
  fee?: number;
  discountPercentage?: number;
  updatedAt: Date;

  [key: string]: any;
}

export interface OrderAdditionalInformation {
  agreedToTerms: boolean;
  declaredInformationIsCorrect: boolean;
  isCompany: boolean;
  companyName: string;
  companyRegistrationNumber: string;
  googleClientId: string;
  sessionId: string;
}

export type Order = BaseIdentifiers &
  UserIdentifier &
  ProductIdentifier &
  TradingSystemIdentifier & {
    information: Exclude<UserInformation, 'userId'> &
      OrderAdditionalInformation;
    amount: number;
    originalAmount: number;
    uniqId: string;
    paymentData: PaymentData;
    status: OrderStatus;
    affiliateId?: string;
    productMetaData?: ProductMetaData;
    groupName?: string;
    coupon?: string;
  };

export type UpdateStatusPayload = {
  status: OrderStatus;
  disableAccount?: boolean;
};

export const checkOrderExpiration = (createdAt: string) => {
  const diffInHours = Math.abs(dayjs().diff(createdAt, 'hours'));
  return diffInHours > 24;
};
