import { Abilities, PayoutSource } from '@monorepo/types';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
} from 'react-router-dom';
import Layout from '../../components/layout/layout';
import NotAllowed from '../../components/not-allowed/not-allowed';
import ProtectedRoute from '../../components/protected-route/protected-route';
import Accounts from '../../features/accounts/accounts';
import ApplicationConfig from '../../features/application-config/application-config';
import { Discounts } from '../../features/discounts/discounts';
import Orders from '../../features/orders/orders';
import Partners from '../../features/partners/partners';
import Payouts from '../../features/payouts/payouts';
import Program from '../../features/programs/program';
import Programs from '../../features/programs/programs';
import Groups from '../../features/groups/groups';
import AddOns from '../../features/add-ons/add-ons';
import AddOn from '../../features/add-ons/add-on';
import Users from '../../features/users/users';
import AdminUsers from '../../features/admin-users/admin-users';
import MainRouter from '../main-router/main-router';
import { useStore } from '../../helpers/use-store';
import {
  init,
  browserTracingIntegration,
  replayIntegration,
  setUser,
} from '@sentry/react';
import EmailTemplates from '../../features/email-templates/email-templates';
import Credits from '../../features/credits/credits';
import RolesAbilities from '../../features/roles-abilities/roles-abilities';
import TradingSystems from '../../features/trading-systems/trading-systems';
import { AffiliationCommissions } from '../../features/affiliation-commission/affiliation-commission';
import Organizations from '../../features/organization/organizations';
import Coupons from '../../features/coupons/coupons';
import BlacklistUsers from '../../features/blacklist-users/blacklist-users';

const isProduction = import.meta.env.VITE_APP_ENV === 'production';

if (isProduction) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const LoggedIn = () => {
  const {
    dataStore: { applicationConfigStore, userStore },
  } = useStore();
  const [isAppReady, setAppReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      await Promise.all([applicationConfigStore.get()]);
      isProduction && setUser({ email: userStore.currentUser.email });
      setAppReady(true);
    };

    init();
  }, []);

  useEffect(() => {
    localStorage.removeItem('initialUrl');
  }, []);

  return isAppReady ? (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<MainRouter />} />
          <Route path="/not-allowed" element={<NotAllowed />} />
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route
            path="/affiliate-groups"
            element={
              <ProtectedRoute abilities={[Abilities.ViewAffiliates]}>
                <Discounts />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            path="/affiliate-commissions"
            element={
              <ProtectedRoute abilities={[Abilities.ViewAffiliates]}>
                <AffiliationCommissions />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            path="/affiliate-payouts"
            element={
              <ProtectedRoute abilities={[Abilities.ViewAffiliates]}>
                <Payouts key="affiliate-payout" type={PayoutSource.Affiliate} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-payouts"
            element={
              <ProtectedRoute abilities={[Abilities.ViewPayouts]}>
                <Payouts key="user-payout" type={PayoutSource.Account} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/credit-transactions"
            element={
              <ProtectedRoute abilities={[Abilities.ViewCredits]}>
                <Credits />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partners"
            element={
              <ProtectedRoute abilities={[Abilities.ManagePartners]}>
                <Partners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/application-config"
            element={
              <ProtectedRoute abilities={[Abilities.ManageApplicationConfig]}>
                <ApplicationConfig />
              </ProtectedRoute>
            }
          />
          <Route
            path="/blacklist-users"
            element={
              <ProtectedRoute abilities={[Abilities.BlacklistUsers]}>
                <BlacklistUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-users"
            element={
              <ProtectedRoute abilities={[Abilities.ManageApplicationConfig]}>
                <AdminUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/roles"
            element={
              <ProtectedRoute abilities={[Abilities.ManageRolesAbilities]}>
                <RolesAbilities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/emails"
            element={
              <ProtectedRoute abilities={[Abilities.ManageApplicationConfig]}>
                <EmailTemplates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/programs"
            element={
              <ProtectedRoute abilities={[Abilities.ViewPrograms]}>
                <Programs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups"
            element={
              <ProtectedRoute abilities={[Abilities.ViewPrograms]}>
                <Groups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/programs/:id"
            element={
              <ProtectedRoute abilities={[Abilities.ManagePrograms]}>
                <Program />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-ons"
            element={
              <ProtectedRoute abilities={[Abilities.ViewAddOns]}>
                <AddOns />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-ons/:id"
            element={
              <ProtectedRoute abilities={[Abilities.ManageAddOns]}>
                <AddOn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute abilities={[Abilities.ViewUsers]}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts"
            element={
              <ProtectedRoute abilities={[Abilities.ViewAccounts]}>
                <Accounts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute abilities={[Abilities.ViewOrders]}>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/trading-systems"
            element={
              <ProtectedRoute abilities={[Abilities.ManageTradingSystem]}>
                <TradingSystems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samples-coupons"
            element={
              <ProtectedRoute abilities={[Abilities.ManageSamples]}>
                <Coupons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samples-organizations"
            element={
              <ProtectedRoute abilities={[Abilities.ManageSamples]}>
                <Organizations />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  ) : null;
};

export default observer(LoggedIn);
