import { Button, Checkbox, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { Space } from '@monorepo/react-components';
import { useStore } from '../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';

const validColumns = [
  'email',
  'programId',
  'groupId',
  'orderId',
  'tradingSystem',
  'initialBalance',
  'credits',
  'firstName',
  'lastName',
  'createOnlyInHub',
  'userLogin',
  'userExternalId',
  'accountStatus',
  'tsAccount',
  'tsAccountId',
  'isTerminated',
  'isDailyPause',
  'isDailyLoss',
  'oldPassword',
  'investorPassword',
  'passwordEncrypted',
];

const UploadAccounts = () => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const [isValid, setIsValid] = useState(false);
  const [isParallel, setIsParallel] = useState(true);
  const [fileList, setFile] = useState<UploadFile[]>([]);
  const [accountsResult, setAccountsResult] = useState<any[]>([]);
  const { loadingState, updateLoadingState } = useLoading();

  const props: UploadProps = {
    fileList,
    beforeUpload: (file) => {
      setFile([file]);

      const reader = new FileReader();

      reader.onload = (data) => {
        console.log(data?.target?.result);
      };

      Papa.parse(file, {
        complete: (res: ParseResult<any>) => {
          const columns: string[] = res.data[0];
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, ...accounts] = res.data;

          const isColumnsValid = columns.every((column) => {
            console.log(column, validColumns.includes(column));
            return validColumns.includes(column);
          });

          console.log(isValid, columns, accounts);

          if (!isColumnsValid) {
            setIsValid(false);
            return;
          }

          const accountsResult: any[] = [];

          accounts.forEach((account) => {
            const accountObj: any = {};

            columns.forEach((col, index) => {
              accountObj[col] = account[index];
            });

            if (accountObj.email) {
              accountsResult.push(accountObj);
            }
          });

          setIsValid(true);
          setAccountsResult(accountsResult);
        },
      });

      return false;
    },
  };

  const uploadAccounts = async () => {
    if (accountsResult.length > 0 && isValid) {
      try {
        updateLoadingState(LoadingState.Loading);
        await accountStore.createAccounts(accountsResult, isParallel);
      } catch (e) {
        console.error(`Failed creating accounts`, accountsResult, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  console.log(accountsResult);

  return (
    <div className="upload-accounts">
      <Space direction={'vertical'}>
        <Checkbox
          onChange={(e) => setIsParallel(e.target.checked)}
          checked={isParallel}
        >
          Parallel
        </Checkbox>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Button
          loading={isLoading}
          onClick={uploadAccounts}
          disabled={!isValid || isLoading}
          type="primary"
        >
          Upload Accounts
        </Button>
      </Space>
    </div>
  );
};

export default UploadAccounts;
