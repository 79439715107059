import { Program } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormModalActions } from '../../form-modal/form-modal';
import ProgramPurchaseLimitsForm from './program-purchase-limits-form';

interface Props {
  program: Program;
  onSave: () => void;
}

const ProgramPurchaseLimits: FunctionComponent<Props> = ({
  program,
  onSave,
}) => {
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Limit Amount',
      span: 3,
      children: program.config.purchaseAmount?.amount || 0,
    },
    {
      key: '1',
      label: 'Include Disabled Accounts',
      span: 3,
      children: program.config.purchaseAmount?.includeDisabledAccounts
        ? 'Yes'
        : 'No',
    },
  ];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => programFormRef.current.open(program)}
        >
          Edit
        </Button>
      </Col>
      <Col span={24}>
        <Descriptions column={6} layout="vertical" bordered items={items} />
        <ProgramPurchaseLimitsForm onSave={onSave} ref={programFormRef} />
      </Col>
    </Row>
  );
};

export default ProgramPurchaseLimits;
