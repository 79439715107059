import {
  EntityPageBase,
  EntityPageBaseRef,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { User, UserVerificationStatus } from '@monorepo/types';
import { Checkbox, Col, Row, message, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useStore } from '../../helpers/use-store';
import UserActions from './user-actions';
import UserStatus from './user-status';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { CheckOutlined } from '@ant-design/icons';

type Filters = {
  'verificationData.status'?: string[];
  userId?: string;
};

const Users: FunctionComponent = () => {
  const {
    dataStore: { usersStore, userStore },
  } = useStore();

  const [filters, setFilters] = useState<Filters>({});

  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);

  const requestPage = async ({
    filters,
    page,
    limit,
  }: {
    filters?: any;
    page: number;
    limit: number;
  }) => {
    try {
      return await usersStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading users for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onUserStatusSave = () => {
    refresh();

    message.success('User status updated');
  };

  const columns: ColumnsType<User> = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return (
          <TextComponent type="secondary" copyable={{ text: item.userId }} />
        );
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'CreatedAt',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
          </TextComponent>
        );
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.firstName}
          </TextComponent>
        );
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.lastName}
          </TextComponent>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.email}
          </TextComponent>
        );
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">{item.phoneNumber}</TextComponent>
        );
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary">{item.country}</TextComponent>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, item) => {
        return (
          <UserStatus
            userId={item._id}
            status={item.verificationData?.status}
            onSave={onUserStatusSave}
          />
        );
      },
    },
    {
      title: 'Credit',
      dataIndex: 'creditBalance',
      align: 'center',
      render: (_, item) => {
        return (
          <Statistic prefix="$" value={item.creditBalance} precision={2} />
        );
      },
    },
    {
      title: 'User Deleted',
      dataIndex: 'creditBalance',
      align: 'center',
      render: (_, item) => {
        return item.deleted && <CheckOutlined />;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, item) => {
        return <UserActions user={item} onActionSuccess={refresh} />;
      },
    },
  ];

  const onSearchChange = (userId: string) => {
    if (userId) {
      filters.userId = userId;
    } else {
      delete filters.userId;
    }

    setFilters(filters);
    refresh();
  };

  const toggleKYC = (e: CheckboxChangeEvent) => {
    const value = e?.target?.checked;
    if (value) {
      filters['verificationData.status'] = [
        UserVerificationStatus.InProgress,
        UserVerificationStatus.Pending,
      ] as any;
    } else {
      delete filters['verificationData.status'];
    }

    setFilters(filters);

    refresh();
  };

  return (
    <>
      <Row align={'middle'} justify={'space-between'} gutter={[15, 15]}>
        <Col span={12}>
          <div>Filter by email:</div>
          <UserAutocomplete onSelect={(userId) => onSearchChange(userId)} />
        </Col>

        <Col span={12}>
          <Flex justify={'end'} gap={10} vertical={false} align={'center'}>
            <span>
              <Checkbox onChange={toggleKYC} />
            </span>
            <span>Show Only Required KYC</span>
          </Flex>
        </Col>
      </Row>
      <EntityPageBase
        columns={columns}
        requestNextPage={requestPage}
        filters={filters}
        ref={entityPageRef}
        abilities={userStore.currentUser.abilities}
      />
    </>
  );
};

export default Users;
