import { useStore } from '../../../helpers/use-store';
import { FunctionComponent, useEffect } from 'react';
import { Program } from '@monorepo/types';
import { observer } from 'mobx-react-lite';
import { TextComponent } from '@monorepo/react-components';

interface Props {
  program: Program;
}

const ProgramEligibility: FunctionComponent<Props> = ({ program }) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const programId = program.config.programEligibility;
  const nextProgram = programStore.get(programId || '');

  useEffect(() => {
    if (programId && !nextProgram) {
      programStore.fetch(programId);
    }
  }, [program]);

  return (
    <TextComponent ellipsis={true}>
      {programId ? nextProgram?.name : '-----'}
    </TextComponent>
  );
};

export default observer(ProgramEligibility);
