import { BaseResponse, CrudService } from '@monorepo/client-common';
import { AddOn } from '@monorepo/types';

export default class AddOnService extends CrudService {
  static override route = 'add-on';

  async search(filters: any): Promise<AddOn[]> {
    const response = await this.httpService.get<BaseResponse<AddOn[]>>(
      `${this.path}/search`,
      {
        params: filters,
      }
    );

    return response.data;
  }

  async duplicate(data: { id: string }) {
    const response = await this.httpService.post<
      { id: string },
      { data: string }
    >(`${this.path}/duplicate`, data);

    return response.data;
  }
}
