import {
  IdIdentifier,
  TimeIdentifiers,
  UserIdentifier,
} from '../common/identifiers';

export enum Role {
  None = 'none',
  OuterAgent = 'outer-agent',
  InHouseAgent = 'in-house-agent',
  Risk = 'risk',
  Marketing = 'marketing',
  Payments = 'payments',
  Admin = 'admin',
  SuperUser = 'super-user',
}

export enum Abilities {
  JumpHigh = 'jump-high', // Default :)))
  ViewPayments = 'view-payments',
  ViewAffiliates = 'view-affiliates',
  ManageAffiliates = 'manage-affiliates',
  ViewPayouts = 'view-payouts',
  ManagePayouts = 'manage-payouts',
  ViewUsers = 'view-users',
  ViewPrograms = 'view-programs',
  ViewCredits = 'view-credits',
  AddCredits = 'add-credits',
  ResendUserConfirmation = 'resend-user-confirmation',
  RequestKYC = 'request-kyc',
  ViewAccounts = 'view-accounts',
  CreateAccounts = 'create-accounts',
  DeleteAccount = 'delete-accounts',
  ViewOrders = 'view-orders',
  ManageOrders = 'manage-orders',
  CreateAdminUsers = 'create-admin-users',
  ManagePartners = 'manage-partners',
  ManageSamples = 'manage-samples',
  ManagePrograms = 'manage-programs',
  ManageGroups = 'manage-groups',
  DeletePrograms = 'delete-programs',
  ManageUsers = 'manage-users',
  ManageAccounts = 'manage-accounts',
  ManageProfitableDays = 'manage-profitable-days',
  ManageEmailTemplates = 'manage-email-templates',
  ManagePaymentProviders = 'manage-payment-providers',
  ManageApplicationConfig = 'manage-applicaction-config',
  ManageAdminUsers = 'manage-admin-users',
  ManageRolesAbilities = 'manage-roles-abilitites',
  ImpersonateAccount = 'impersonate-account',
  ViewAddOns = 'view-add-ons',
  ManageAddOns = 'manage-add-ons',
  DeleteAddOns = 'delete-add-ons',
  ManageTradingSystem = 'manage-trading-system',
  CreatePendingAccounts = 'create-pending-accounts',
  UpdateAccountExpireDate = 'update-account-expire-date',
  ViewSingle = 'view-single',
  DeletePositions = 'delete-positions',
  BlacklistUsers = 'blacklist-users',
}

export type AdminRole = IdIdentifier &
  TimeIdentifiers &
  UserIdentifier & {
    role: Role;
    abilities: Abilities[];
  };

export const isUserAllowed = ({
  role,
  userAbilities,
  abilityRequired,
}: {
  role: Role;
  abilityRequired: Abilities;
  userAbilities: Abilities[];
}) => {
  if (role === Role.SuperUser) {
    return true;
  }

  return userAbilities.includes(abilityRequired);
};
