import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { GroupType, ThemeColors } from '@monorepo/types';
import {
  FormCheckbox,
  FormInput,
  FormInputNumber,
  FormSelect,
  FormTextArea,
} from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';
import { IconsNames } from '@monorepo/icons';

const ProgramGroupDrawer: FunctionComponent<{
  form: UseFormReturn<GroupType>;
}> = ({ form }) => {
  const iconSelectOptions = Object.entries(IconsNames).map(([key, value]) => ({
    value: value,
    label: key,
  }));

  const colorOptions = Object.entries(ThemeColors).map(([key, value]) => ({
    value: value,
    label: key,
  }));

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="..."
          text={'Group Name'}
          form={form}
          controllerProps={{
            name: 'name',
            rules: {
              required: true,
              min: 3,
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="..."
          text={'Subtitle'}
          form={form}
          controllerProps={{
            name: 'subtitle',
          }}
        />
      </Col>
      <Col span={24}>
        <FormTextArea
          placeholder="..."
          text={'Description'}
          form={form}
          controllerProps={{
            name: 'description',
          }}
          textareaProps={{
            required: true,
            autoSize: { minRows: 5, maxRows: 6 },
            style: { marginBottom: 10 },
            maxLength: 250,
            showCount: true,
          }}
        />
      </Col>
      <Col span={12}>
        <FormSelect
          form={form}
          allowClear={true}
          text="Icon"
          controllerProps={{
            rules: { required: true },
            name: 'icon',
          }}
          options={iconSelectOptions}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          text="Ribbon Text (use <b></b> tags to make text bold)"
          controllerProps={{
            name: 'ribbon.html',
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          text="Ribbon Color"
          controllerProps={{
            defaultValue: ThemeColors.colorPrimary,
            name: 'ribbon.color',
          }}
          options={colorOptions}
        />
      </Col>
      <Col span={12}>
        <FormInputNumber
          placeholder="1"
          text={'Display order'}
          form={form}
          controllerProps={{
            name: 'displayOrder',
            rules: {
              required: { value: true, message: 'order is required' },
              min: { value: 1, message: 'order must be greater than 0' },
            },
          }}
        />
      </Col>
      <Col span={12}>
        <FormInputNumber
          placeholder="1"
          text={'Group purchase limit'}
          form={form}
          controllerProps={{
            name: 'config.purchaseLimit',
            rules: {
              required: false,
              min: { value: 1, message: 'purchase limit must be more then 1' },
            },
          }}
        />
      </Col>
      <Col span={12}>
        <FormInputNumber
          placeholder="1"
          text={'Group buying power limit'}
          form={form}
          controllerProps={{
            name: 'config.buyingPowerLimit',
            rules: {
              required: false,
              min: { value: 1, message: 'purchase limit must be more then 1' },
            },
          }}
        />
      </Col>
      <Col span={12}>
        <FormCheckbox
          form={form}
          text="Advised"
          controllerProps={{
            rules: { required: false },
            defaultValue: false,
            name: 'advised',
          }}
        />
      </Col>
    </Row>
  );
};
export default ProgramGroupDrawer;
