import { TextComponent } from '@monorepo/react-components';
import { AddOn } from '@monorepo/types';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { FormModalActions } from '../../form-modal/form-modal';
import AddOnConfigForm from './add-on-config-form';

interface Props {
  addOn: AddOn;
  onSave: () => void;
}

const AddOnConfig: FunctionComponent<Props> = ({ addOn, onSave }) => {
  const addOnFormRef = useRef<FormModalActions>({} as FormModalActions);

  const { config } = addOn;

  const configItems = {
    renew: {
      key: '1',
      label: 'Renew Days',
      span: 1,
      children: <TextComponent>{config?.renewLength}</TextComponent>,
    },
  };

  const items: DescriptionsProps['items'] = [
    configItems[addOn.type as keyof typeof configItems],
  ];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => addOnFormRef.current.open(addOn)}
        >
          Edit
        </Button>
      </Col>

      <Col span={24}>
        <Descriptions column={6} layout="vertical" bordered items={items} />
        <AddOnConfigForm onSave={onSave} ref={addOnFormRef} addOn={addOn} />
      </Col>
    </Row>
  );
};

export default AddOnConfig;
