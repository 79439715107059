import { CrudService } from '@monorepo/client-common';
import { TradingSystem } from '@monorepo/types';

export default class TradingSystemService extends CrudService {
  static override route = 'trading-system';

  async syncClosedPositions(payload: {
    startDate: string;
    endDate: string;
    tradingSystem: TradingSystem;
  }) {
    try {
      await this.httpService.post(
        `${this.path}/sync-closed-positions`,
        payload
      );

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deletePositionByExternalId(
    tradingSystem: TradingSystem,
    externalId: string
  ) {
    try {
      const response: { data: boolean } = await this.httpService.delete(
        `${this.path}/${tradingSystem}/positions/${externalId}`
      );

      return response.data;
    } catch (e) {
      console.error(
        `Failed deleting position ${externalId} for ${tradingSystem}`,
        e
      );
      throw e;
    }
  }
}
