import { TextComponent } from '@monorepo/react-components';
import { ColumnsType } from 'antd/es/table';

import { CheckOutlined } from '@ant-design/icons';
import { EntityPageWithDrawer } from '@monorepo/react-components';
import { CountryFilterType, PaymentProvider } from '@monorepo/types';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import CountryFilter from '../../components/country-filter/country-filter';
import { PaymentProviderDrawer } from '../payment-provider-drawer/payment-provider-drawer';
import CurrencyFilter from '../../components/currency-filter/currency-filter';

const getDefaultValues = (
  paymentProvider?: PaymentProvider
): Partial<PaymentProvider> => {
  if (paymentProvider) {
    return paymentProvider;
  }
  return {
    name: undefined,
    enabled: false,
    countryFilter: {
      type: CountryFilterType.Exclude,
      countries: [],
    },
  };
};

const PaymentProviders = () => {
  const {
    dataStore: { paymentProvider, userStore },
  } = useStore();

  const form = useForm<PaymentProvider>({
    mode: 'all',
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await paymentProvider.list();
    } catch (e) {
      console.error(
        `Failed loading payment providers for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<PaymentProvider> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      width: '5%',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Country Filter',
      dataIndex: 'countryFilter',
      width: '15%',
      render: (_, item) => {
        return <CountryFilter countryFilter={item.countryFilter} />;
      },
    },
    {
      title: 'Currency Filter',
      dataIndex: 'currencyFilter',
      width: '15%',
      render: (_, item) => {
        return <CurrencyFilter currencyFilter={item.currencyFilter} />;
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      width: '10%',
      render: (_, item) => {
        return item.enabled ? <CheckOutlined /> : null;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async (isEdit?: boolean) => {
    try {
      if (isEdit) {
        await paymentProvider.update(form.getValues()._id, form.getValues());
        message.info('Payment provider updated successfully');
      } else {
        await paymentProvider.create(form.getValues());
        message.info('Payment provider created successfully');
      }
    } catch (error) {
      message.error(
        `Failed to ${isEdit ? 'update' : 'create'} payment provider`
      );
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<PaymentProviderDrawer form={form} />}
      onSubmit={submitForm}
      columns={columns}
      isCreateAllowed
      isEditAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default PaymentProviders;
