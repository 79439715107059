import {
  AffiliateCommissionStatus,
  AffiliationCommission,
} from '@monorepo/types';
import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import AffiliationCommissionService from '../../../services/entities/affiliation-commission-service';

export default class AffiliateCommissionsStore extends EntityStore<
  AffiliationCommissionService,
  AffiliationCommission
> {
  constructor() {
    const {
      apiFactory: { affiliationCommissionService },
    } = getEnv();

    super(affiliationCommissionService);
  }

  async updateBulkStatus(
    externalIds: string[],
    status: AffiliateCommissionStatus,
    currentPage: number,
    userId: string
  ) {
    await this.service.updateBulkStatus(
      externalIds,
      status,
      currentPage,
      userId
    );
  }
}
