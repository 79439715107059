export interface CurrencyFilter {
  type: CurrencyFilterType;
  currencies: string[];
}

export enum CurrencyFilterType {
  Include = 'include',
  Exclude = 'exclude',
}

export const isValidCCurrency = (
  currencyCode: string,
  currencyFilter: CurrencyFilter
) => {
  const isCurrencyFilterIncludedInList =
    currencyFilter.currencies.includes(currencyCode);

  if (currencyFilter.type === CurrencyFilterType.Include) {
    return isCurrencyFilterIncludedInList;
  }
  if (currencyFilter.type === CurrencyFilterType.Exclude) {
    return !isCurrencyFilterIncludedInList;
  }

  return false;
};
