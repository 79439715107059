import React, { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Checkbox, CheckboxOptionType, CheckboxProps, Flex } from 'antd';

export const FormCheckboxGroup: FunctionComponent<
  Omit<FormFieldProps, 'render'> &
    CheckboxProps & {
      options: CheckboxOptionType[];
      vertical?: boolean;
    }
> = ({ ...formFieldProps }) => {
  const { options, vertical } = formFieldProps;

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Checkbox.Group {...field}>
            <Flex vertical={vertical}>
              {options.map((option) => (
                <Checkbox key={option.value as string} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
        );
      }}
    />
  );
};
