import {
  ConfirmAccountJob,
  ConfirmAccountPayload,
  CreateAccountJob,
  CreateAccountPayload,
  CreatePendingAccountsJob,
  OrderPaidJob,
  OrderPaidPayload,
  ReachedDailyPauseJob,
  ReachedDailyPauseJobPayload,
  RecordAffiliationPurchaseJob,
  RecordAffiliationPurchasePayload,
  SchedulerJob,
  TerminationJob,
  TerminationJobPayload,
  TradingSystemJob,
  TradingSystemPayload,
} from './hub-jobs';
import {
  ExecuteAddOnJob,
  ExecuteAddOnPayload,
} from './hub-jobs/execute-add-on-job';
import {
  StockMarketJobsJob,
  StockMarketJobsPayload,
} from './hub-jobs/market-jobs';
import { JobsOptions } from 'bullmq/dist/esm/types';
import {
  CreateAchievementJob,
  CreateAchievementJobPayload,
} from './hub-jobs/achievement-jobs';
import {
  ResetDailyPauseJob,
  ResetDailyPauseJobsPayload,
} from './hub-jobs/reset-daily-pause-job';
import {
  ReachedDailyLossJob,
  ReachedDailyLossJobPayload,
} from './hub-jobs/daily-loss';

export enum HubQueues {
  HubQueue = 'hub-queue',
}

export enum HubQueueJobType {
  CreatePendingAccounts = 'create-pending-accounts',
  CreateAccount = 'create-account',
  OrderPaid = 'order-paid',
  ConfirmAccount = 'confirm-account',
  ReachedTarget = 'reached-target',
  ExecuteAddOn = 'execute-add-on',
  RecordAffiliationPurchase = 'record-affiliation-purchase',
  ResetDailyPause = 'reset-daily-pause',
  ReachedExpiration = 'reached-expiration',
  CleanOrders = 'clean-orders',
  CleanAdmin = 'clean-admin',
  ReachedDailyPause = 'reached-daily-pause',
  ReachedDailyLoss = 'reached-daily-loss',
  Terminated = 'terminated',
  RestrictEarnings = 'restrict-earnings',
  ClearEarnings = 'clear-earnings',
  Swing = 'swing',
  SwingReset = 'swing-reset',
  AboutToExpire = 'about-to-expire',
  CreateAchievement = 'create-achievement',
  CreditExpiration = 'credit-expiration',
  StockMarketJobs = 'stock-market-jobs',
  InactivityDays = 'inactivity-days',
}

export type HubJobsPayload =
  | CreateAccountPayload
  | SchedulerJob
  | TradingSystemPayload
  | ConfirmAccountPayload
  | OrderPaidPayload
  | ExecuteAddOnPayload
  | StockMarketJobsPayload
  | ResetDailyPauseJobsPayload
  | CreateAchievementJobPayload
  | ReachedDailyLossJobPayload
  | ReachedDailyPauseJobPayload
  | TerminationJobPayload
  | RecordAffiliationPurchasePayload
  | undefined;

export type HubJobs = (
  | CreateAccountJob
  | CreatePendingAccountsJob
  | OrderPaidJob
  | TradingSystemJob
  | ConfirmAccountJob
  | ExecuteAddOnJob
  | StockMarketJobsJob
  | ResetDailyPauseJob
  | CreateAchievementJob
  | ReachedDailyLossJob
  | ReachedDailyPauseJob
  | TerminationJob
  | RecordAffiliationPurchaseJob
  | { type: HubQueueJobType; data?: any }
) & { options?: JobsOptions };
