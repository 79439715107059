import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

const { TextArea } = Input;

type FormTextAreaProps = Omit<FormFieldProps, 'render'> & {
  textareaProps?: TextAreaProps;
};

export const FormTextArea: FunctionComponent<FormTextAreaProps> = ({
  textareaProps,
  ...props
}) => {
  return (
    <FormField
      {...props}
      render={({ field }) => {
        return <TextArea {...field} {...textareaProps} />;
      }}
    />
  );
};
