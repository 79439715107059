import { useStore } from '../../helpers/use-store';
import { Program } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  EntityPageBase,
  EntityPageBaseRef,
  TextComponent,
  Statistic,
} from '@monorepo/react-components';
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import NextProgram from './next-program/next-program';
import { useRef, useState } from 'react';
import ProgramOverviewForm from './program-overview/program-overview-form';
import { FormModalActions } from '../form-modal/form-modal';
import ProgramAutocomplete from '../../components/program-autocomplete/program-autocomplete';

type Filters = {
  _id?: string[];
};

const Programs = () => {
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);
  const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);
  const {
    dataStore: { programStore, userStore },
  } = useStore();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({});

  const requestPage = async ({
    filters,
    page,
    limit,
  }: {
    filters?: any;
    page: number;
    limit: number;
  }) => {
    try {
      return await programStore.paginate({
        filters,
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading programs for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<Program> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Next Program',
      dataIndex: 'next-name',
      render: (_, item) => {
        return <NextProgram program={item} />;
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.groupName}</TextComponent>;
      },
    },
    {
      title: 'Group',
      dataIndex: 'groupId',
      render: (_, item) => {
        return <TextComponent ellipsis={true}>{item.groupId}</TextComponent>;
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      render: (_, item) => {
        return <Statistic value={item.config.buyingPower || 0} />;
      },
    },
    {
      title: 'Type',
      dataIndex: 'accountType',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} capitalize={true}>
            {item.accountType}
          </TextComponent>
        );
      },
    },
    {
      title: 'Initial Status',
      dataIndex: 'accountStatus',
      render: (_, item) => {
        return (
          <TextComponent capitalize={true}>{item.accountStatus}</TextComponent>
        );
      },
    },
    {
      title: 'Initial Status',
      dataIndex: 'accountStatus',
      render: (_, item) => {
        return (
          <TextComponent capitalize={true}>{item.accountStatus}</TextComponent>
        );
      },
    },
    {
      title: 'Sellable',
      dataIndex: 'sellable',
      align: 'center',
      render: (_, item) => {
        if (item.sellable) {
          return <CheckOutlined />;
        }

        return null;
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      align: 'center',
      render: (_, item) => {
        return item.enabled ? <CheckOutlined /> : null;
      },
    },
    {
      title: 'Advised',
      dataIndex: 'advised',
      align: 'center',
      render: (_, item) => {
        return item.advised ? <CheckOutlined /> : null;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      align: 'center',
      render: () => {
        return <ArrowRightOutlined />;
      },
    },
  ];

  const refresh = () => {
    entityPageRef.current.reFetch(filters);
  };

  const onCreateClick = () => {
    programFormRef.current.open();
  };

  const onSearchClick = async (item: { label: string; value: string }) => {
    const { label, value } = item;
    if (label === value) {
      // selected regex and not specific name
      const programs = (await programStore.search({
        name: value,
      })) as Partial<Program>[];

      const ids = programs.map((program) => program._id);
      filters._id = ids as string[];
    } else {
      filters._id = [value];
    }

    setFilters(filters);
    refresh();
  };

  const onClearClick = async () => {
    delete filters._id;
    setFilters(filters);
    refresh();
  };

  return (
    <>
      <Row align={'bottom'}>
        <Col span={12}>
          <div>Filter by program name:</div>
          <ProgramAutocomplete
            onSelect={onSearchClick}
            onClear={onClearClick}
          />
        </Col>
        <Col span={12}>
          <Flex justify={'end'}>
            <Button key="create-btn" onClick={onCreateClick}>
              Create
            </Button>
          </Flex>
        </Col>

        <ProgramOverviewForm
          onSave={() => entityPageRef.current.reFetch()}
          ref={programFormRef}
        />
      </Row>
      <EntityPageBase
        initialLimit={50}
        ref={entityPageRef}
        onRowClick={(program: Program) => navigate(`/programs/${program._id}`)}
        columns={columns}
        requestNextPage={requestPage}
        filters={filters}
        abilities={userStore.currentUser.abilities}
      />
    </>
  );
};

export default Programs;
