import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { AddOn } from '@monorepo/types';
import AddOnService from '../../../services/entities/add-on-service';
import { EntityStore } from '@monorepo/client-common';

export type AddOnWithProgramName = AddOn & { programNames?: string[] };

export default class AddOnStore extends EntityStore<
  AddOnService,
  AddOnWithProgramName
> {
  constructor() {
    const {
      apiFactory: { addOnService },
    } = getEnv();

    super(addOnService);
  }

  search(query: any) {
    return this.service.search(query);
  }

  duplicate(id: string) {
    return this.service.duplicate({ id });
  }
}
