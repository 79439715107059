import { ExclamationCircleFilled } from '@ant-design/icons';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Abilities } from '@monorepo/types';
import {
  Button,
  Col,
  message,
  Modal,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProtectedElement from '../../components/protected-element/protected-element';
import { useStore } from '../../helpers/use-store';
import AddOnOverview from './add-on-overview/add-on-overview';
import AddOnConfig from './add-on-config/add-on-config';
import { AddOnWithProgramName } from '../../stores/data/add-on/add-on-store';

const { confirm } = Modal;

const AddOnComponent = () => {
  const navigate = useNavigate();
  const {
    dataStore: { addOnStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [addOn, setAddOn] = useState<AddOnWithProgramName | null>();
  const { id } = useParams<{ id: string }>();

  const init = async () => {
    try {
      if (!id) {
        throw new Error(`Missing id: ${id}`);
      }

      updateLoadingState(LoadingState.Loading);

      const addOn = await addOnStore.fetch(id);

      setAddOn(addOn);

      updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      updateLoadingState(LoadingState.Error);
      message.error('Something went wrong.');
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  if (loadingState === LoadingState.Error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    );
  }

  const onDuplicateClick = async () => {
    if (!addOn) {
      return;
    }

    confirm({
      title: 'Do you want to duplicate add on?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          await addOnStore.duplicate(addOn._id);

          message.success('Add on duplicated successfully');
        } catch {
          message.error('Failed to duplicate add on');
        }
      },
    });
  };

  const onDeleteClick = async () => {
    if (!addOn) {
      return;
    }

    confirm({
      title: 'Do you want to delete add on?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          await addOnStore.delete(addOn._id);

          message.success('Add on deleted successfully');

          navigate('/add-ons');
        } catch {
          message.error('Failed to delete add on');
        }
      },
    });
  };

  if (!addOn) {
    return null;
  }

  const addOnItemsMap = {
    renew: [
      {
        label: `Overview`,
        key: 'overview',
        children: <AddOnOverview addOn={addOn} onSave={init} />,
      },
      {
        label: `Config`,
        key: 'config',
        children: <AddOnConfig addOn={addOn} onSave={init} />,
      },
    ],
    'bypass-daily-pause': [
      {
        label: `Overview`,
        key: 'overview',
        children: <AddOnOverview addOn={addOn} onSave={init} />,
      },
    ],
  };

  return (
    <Row gutter={[15, 15]}>
      <Col span={12}>
        <Typography.Title style={{ marginBottom: '15px' }} level={4}>
          {addOn.name}
        </Typography.Title>
      </Col>
      <Col push={8} span={4} style={{ textAlign: 'end' }}>
        <Space>
          <Button key="duplicate-btn" onClick={onDuplicateClick}>
            Duplicate
          </Button>
          <ProtectedElement abilities={[Abilities.DeleteAddOns]}>
            <Button key="delete-btn" onClick={onDeleteClick}>
              Delete
            </Button>
          </ProtectedElement>
        </Space>
      </Col>
      <Col span={24}>
        <Tabs tabPosition="left" items={addOnItemsMap[addOn.type]} />
      </Col>
    </Row>
  );
};

export default AddOnComponent;
