import {
  Abilities,
  AdminLoginResponse,
  AdminUser,
  isUserAllowed,
} from '@monorepo/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { getEnv, getRoot } from '../../../helpers/mobx-easy-wrapper';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';

const prefix = reactEnv === 'production' ? '' : `${reactEnv.slice(0, 3)}-`;

export const TOKEN_KEY = `${prefix}admin`;
export const REFRESH_TOKEN_KEY = `${prefix}refresh-admin`;
export const USER_ID = `${prefix}admin-user-id`;

export default class UserStore {
  @observable
  isLoggedIn: boolean = false;

  @observable
  currentUser: AdminUser = {} as AdminUser;

  constructor() {
    makeObservable(this);
  }

  logout() {
    this.setCurrentUser({} as AdminUser);
    this.setLoginState(false);
    const { apiFactory } = getEnv();
    const {
      uiStore: { getLogoutUrl },
    } = getRoot();

    apiFactory.removeToken();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem('initialUrl');

    window.location.href = getLogoutUrl;
  }

  async login(
    email?: string,
    password?: string
  ): Promise<AdminLoginResponse | undefined> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const user = await userService.login(email, password);

      const { token, refreshToken, ...rest } = user as AdminLoginResponse;

      if (token && refreshToken) {
        this.saveToken(token, refreshToken);
      }

      this.setCurrentUser(rest);
      this.setLoginState(true);
      localStorage.setItem(USER_ID, rest.userId);

      return user;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  saveToken(token: string, refreshToken: string) {
    const { apiFactory } = getEnv();
    apiFactory.saveToken(token, this.refreshToken);
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  async refreshToken() {
    const {
      apiFactory: { userService },
    } = getEnv();
    const userId = localStorage.getItem(USER_ID);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!refreshToken || !userId) {
      return '';
    }

    const newToken = await userService.refreshToken(refreshToken, userId);
    localStorage.setItem(TOKEN_KEY, newToken);
    return newToken;
  }

  isUserAllowed(currentUser: AdminUser, abilities: Abilities[]) {
    return abilities.some((ability) =>
      isUserAllowed({
        abilityRequired: ability,
        userAbilities: currentUser.abilities,
        role: currentUser.role,
      })
    );
  }

  @action
  setCurrentUser(data: AdminUser) {
    this.currentUser = data;
  }

  @action
  setLoginState(loginState: boolean) {
    this.isLoggedIn = loginState;
  }

  @computed
  get fullName() {
    return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
  }
}
