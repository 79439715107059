import {
  FormCheckbox,
  FormCountry,
  FormCurrency,
  FormSelect,
} from '@monorepo/react-components';
import {
  CountryFilterType,
  PaymentProvider,
  PaymentProviderName,
} from '@monorepo/types';
import { Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

const countryFilterTypes = Object.values(CountryFilterType).map((type) => {
  return { value: type, label: type };
});

const providerNames = Object.values(PaymentProviderName).map((type) => {
  return { value: type, label: type };
});

export const PaymentProviderDrawer: FunctionComponent<{
  form: UseFormReturn<PaymentProvider>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          placeholder="Name"
          text={'Name'}
          form={form}
          controllerProps={{
            name: 'name',
            rules: {
              required: { value: true, message: 'Name is required' },
            },
          }}
          options={providerNames}
        />
      </Col>
      <Col span={24}>
        <FormCheckbox
          placeholder="Enabled"
          text={'Enabled'}
          form={form}
          controllerProps={{
            name: 'enabled',
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          placeholder="Country Filter Type"
          text={'Country Filter Type'}
          form={form}
          controllerProps={{
            name: 'countryFilter.type',
            rules: {
              required: { value: true, message: 'Filter type is required' },
            },
          }}
          options={countryFilterTypes}
        />
      </Col>
      <Col span={24}>
        <FormCountry
          form={form}
          mode="tags"
          controllerProps={{
            name: 'countryFilter.countries',
          }}
        />
      </Col>

      <Col span={24}>
        <FormSelect
          placeholder="Currency Filter Type"
          text={'Currency Filter Type'}
          form={form}
          controllerProps={{
            name: 'currencyFilter.type',
            rules: {
              required: { value: true, message: 'Filter type is required' },
            },
          }}
          options={countryFilterTypes}
        />
      </Col>
      <Col span={24}>
        <FormCurrency
          form={form}
          mode="tags"
          controllerProps={{
            name: 'currencyFilter.currencies',
          }}
        />
      </Col>
    </Row>
  );
};
