import {
  FormInput,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { ProgramTradingSystemConfig, TradingSystem } from '@monorepo/types';
import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const ProgramTradingSystemDrawer: FunctionComponent<{
  form: UseFormReturn<ProgramTradingSystemConfig>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'tradingSystem',
            rules: { required: true },
          }}
          text={'Trading System'}
          placeholder={'Select Trading System'}
          options={[
            {
              label: 'Select Trading System',
              value: '',
              disabled: true,
            },
            ...Object.values(TradingSystem).map((accountType) => ({
              label: (
                <TextComponent capitalize={true}>{accountType}</TextComponent>
              ),
              value: accountType,
            })),
          ]}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          text="Group ID"
          controllerProps={{
            rules: { required: 'Missing Group ID' },
            name: 'groupId',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          text="Termination Group ID"
          controllerProps={{
            rules: { required: false },
            name: 'terminationGroupId',
          }}
        />
      </Col>
    </Row>
  );
};
