import {
  CountryFilter as CountryFilterType,
  CountryFilterType as FilterType,
} from '@monorepo/types';
import { Flex, Space, Tag } from 'antd';
import { FunctionComponent } from 'react';

export type Option = {
  value: string;
  label: string;
};

interface Props {
  countryFilter: CountryFilterType | undefined;
}

const COUNTRY_FILTER_TYPES_TO_COLOR = {
  [FilterType.Exclude]: 'red',
  [FilterType.Include]: 'blue',
};

const CountryFilter: FunctionComponent<Props> = ({ countryFilter }) => {
  return countryFilter?.countries.length ? (
    <Space>
      <Tag color={COUNTRY_FILTER_TYPES_TO_COLOR[countryFilter.type]}>
        {countryFilter.type}
      </Tag>
      <Flex>
        {countryFilter.countries.map((country) => (
          <Tag color="green">{country}</Tag>
        ))}
      </Flex>
    </Space>
  ) : null;
};

export default CountryFilter;
