import { Application, TradingSystem } from '@monorepo/types';
import { useState } from 'react';
import { Col, Row, Select } from 'antd';
import { Space } from '@monorepo/react-components';
import SyncClosedPositionsModal from './sync-closed-positions-modal';
import DeletePositionModal from './delete-position-modal';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const TradingSystemsByProject = {
  [Application.TTP]: [TradingSystem.TraderEvolution],
  [Application.THE5ERS]: [TradingSystem.MatchTrader],
};

const TradingSystems = () => {
  const [selectedTradingSystem, selectTradingSystem] = useState();

  return (
    <Space direction="vertical" size={'middle'}>
      <Select
        placeholder={'Trading System'}
        style={{ width: '150px' }}
        onSelect={(ts) => selectTradingSystem(ts)}
      >
        {TradingSystemsByProject[project].map((ts) => {
          return (
            <Select.Option key={ts} value={ts}>
              {ts}
            </Select.Option>
          );
        })}
      </Select>

      {selectedTradingSystem && (
        <Row gutter={[15, 15]}>
          <Col>
            <SyncClosedPositionsModal tradingSystem={selectedTradingSystem} />
          </Col>

          <Col>
            <DeletePositionModal tradingSystem={selectedTradingSystem} />
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default TradingSystems;
