import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { ColumnsType } from 'antd/es/table';
import { ProgramTradingSystemConfig, TradingSystem } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import { ProgramTradingSystemDrawer } from './program-trading-system-drawer';
import { observer } from 'mobx-react-lite';

const getDefaultValues = (
  programTradingSystemConfig?: ProgramTradingSystemConfig
): Partial<ProgramTradingSystemConfig> => {
  if (programTradingSystemConfig) {
    return programTradingSystemConfig;
  }
  return {
    groupId: '',
    terminationGroupId: '',
    tradingSystem: '' as TradingSystem,
  };
};

interface Props {
  tradingSystems: ProgramTradingSystemConfig[];
  onSave: (tradingSystems: ProgramTradingSystemConfig[]) => void;
}

const ProgramTradingSystems: FunctionComponent<Props> = ({
  tradingSystems,
  onSave,
}) => {
  const form = useForm<ProgramTradingSystemConfig>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const requestPage = async () => {
    return {
      total: tradingSystems.length,
      results: tradingSystems.map((ProgramTradingSystemConfig) => ({
        _id: ProgramTradingSystemConfig.tradingSystem,
        ...ProgramTradingSystemConfig,
      })),
    };
  };

  const columns: ColumnsType<ProgramTradingSystemConfig> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.tradingSystem}</TextComponent>;
      },
    },
    {
      title: 'Group ID',
      dataIndex: 'groupId',
      width: '15%',
      render: (_, item) => {
        return <TextComponent>{item.groupId}</TextComponent>;
      },
    },
    {
      title: 'Termination Group ID',
      dataIndex: 'terminationGroupId',
      width: '15%',
      render: (_, item) => {
        return <TextComponent>{item.terminationGroupId}</TextComponent>;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async () => {
    const ProgramTradingSystemConfig = form.getValues();

    const index = tradingSystems.findIndex(
      (item) => item.tradingSystem === ProgramTradingSystemConfig.tradingSystem
    );

    if (index === -1) {
      tradingSystems.push(ProgramTradingSystemConfig);
    } else {
      tradingSystems[index] = ProgramTradingSystemConfig;
    }

    onSave(tradingSystems);
  };

  const onDelete = async (tradingSystem: string) => {
    const index = tradingSystems.findIndex(
      (item) => item.tradingSystem === tradingSystem
    );

    if (index !== -1) {
      tradingSystems.splice(index, 1);
      onSave(tradingSystems);
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<ProgramTradingSystemDrawer form={form} />}
      onSubmit={submitForm}
      columns={columns}
      onDelete={onDelete}
      isCreateAllowed
      isEditAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default observer(ProgramTradingSystems);
