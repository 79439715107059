import { AccountStateKey, CreditEvents } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Flex, Row } from 'antd';
import { TextComponent, Statistic } from '@monorepo/react-components';
import ProgramCreditEventsForm from './credit-events-form';
import { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  creditEvents: CreditEvents | undefined;
  onSave: (item: CreditEvents) => void;
}

const ProgramCreditEvents: FunctionComponent<Props> = ({
  creditEvents,
  onSave,
}) => {
  const creditEventsKeys = Object.values(AccountStateKey);
  const creditEventsFormRef = useRef<FormModalActions>({} as FormModalActions);

  const creditEventValue = (event: string) => {
    let value = { amount: 0, expirationInDays: 0 };
    if (
      creditEvents &&
      creditEvents[event as keyof CreditEvents] &&
      creditEvents[event as keyof CreditEvents].amount > 0
    ) {
      value = creditEvents[event as keyof CreditEvents];
    }

    return value.amount > 0 ? (
      <Flex gap={25}>
        <TextComponent>{value.expirationInDays} Expiration Days</TextComponent>
        <Statistic prefix="$" value={value.amount} />
      </Flex>
    ) : (
      '-'
    );
  };

  const items: DescriptionsProps['items'] = creditEventsKeys.map(
    (event: string) => {
      return {
        key: event,
        label: <TextComponent capitalize={true}>{event}</TextComponent>,
        children: creditEventValue(event),
      };
    }
  );

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => creditEventsFormRef.current.open(creditEvents)}
        >
          {creditEventsKeys.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {creditEventsKeys.length > 0 && (
          <Descriptions column={3} layout="vertical" bordered items={items} />
        )}
        <ProgramCreditEventsForm onSave={onSave} ref={creditEventsFormRef} />
      </Col>
    </Row>
  );
};

export default ProgramCreditEvents;
