import { Typography } from 'antd';
import { useStore } from '../../helpers/use-store';
import { Coupon } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { CouponsDrawer } from '../coupons-drawer/coupons-drawer';

const { Link, Text } = Typography;

const getDefaultValues = (coupon?: Coupon): Partial<Coupon> => {
  if (coupon) {
    return {
      ...coupon,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      expirationDate: dayjs(coupon.expirationDate),
    };
  }
  return {
    amount: 0,
    comment: '',
    name: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    expirationDate: dayjs().add(14, 'day'),
    items: [],
    link: '',
    organizationCode: '',
    createdAt: '',
    updatedAt: '',
  };
};

const Coupons = () => {
  const {
    dataStore: { couponStore, userStore },
  } = useStore();

  const form = useForm<Coupon>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await couponStore.paginate({
        page,
        limit,
      });
    } catch (e) {
      console.error(
        `Failed loading partners for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<Coupon> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <Text type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.name}
          </TextComponent>
        );
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      align: 'center',
      render: (_, item) => {
        return (
          <Link ellipsis={true} type="secondary">
            {item.link}
          </Link>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.amount}
          </TextComponent>
        );
      },
    },
    {
      title: 'Used',
      dataIndex: 'used',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.used}
          </TextComponent>
        );
      },
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {dayjs(item.expirationDate).format('DD-MM-YYYY')}
          </TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitPartnerForm = async (isEdit?: boolean) => {
    if (!isEdit) {
      await couponStore.create(form.getValues());
    } else {
      await couponStore.update(form.getValues()._id, form.getValues());
    }
  };

  const onDelete = async (id: string) => {
    await couponStore.delete(id);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<CouponsDrawer form={form} />}
      onSubmit={submitPartnerForm}
      columns={columns}
      isEditAllowed
      onDelete={onDelete}
      isCreateAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default Coupons;
