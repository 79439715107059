import { getEnv } from '../../helpers/mobx-easy-wrapper';
import { action, makeObservable, observable } from 'mobx';
import { Application, Theme, ThemeAlgorithm } from '@monorepo/types';
import { theme, ThemeConfig } from 'antd';

const project =
  (import.meta.env.VITE_PROJECT as Application) || Application.TTP;

class UiStore {
  initialUrl?: string;

  @observable
  currentTheme: Theme = Theme.Default;

  themeConfigurations: { [key in Theme]?: ThemeConfig } = {};

  constructor() {
    const initialUrl = localStorage.getItem('initialUrl');

    this.initialUrl = initialUrl?.replace('#', '');

    makeObservable(this);
  }

  get googleLoginUrl() {
    const {
      envConfig: {
        api_end_point: serverUrl,
        cognito_pool_name: poolName,
        aws_region: awsRegion,
        aws_client_id: clientId,
      },
    } = getEnv();

    return `https://${poolName}.auth.${awsRegion}.amazoncognito.com/oauth2/authorize?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${serverUrl}/user/auth/redirect`;
  }

  get getLogoutUrl() {
    const {
      envConfig: {
        cognito_pool_name: poolName,
        aws_region: awsRegion,
        aws_client_id: clientId,
      },
    } = getEnv();

    return `https://${poolName}.auth.${awsRegion}.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${window.location.origin}`;
  }

  getThemeToken(algorithm: string) {
    if (algorithm === ThemeAlgorithm.DarkAlgorithm) {
      return theme.darkAlgorithm;
    }
    return theme.defaultAlgorithm;
  }

  async getThemeConfig(theme: Theme) {
    const {
      envConfig: { cdn_base_url },
    } = getEnv();

    if (this.themeConfigurations[theme]) {
      return this.themeConfigurations[theme];
    }

    const themeConfig = await fetch(
      `https://${cdn_base_url}/style-configurations/${theme}.json`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    ).then((res) => res.json());

    this.themeConfigurations[theme] = {
      ...themeConfig,
      algorithm: this.getThemeToken(themeConfig.algorithm),
    };

    return themeConfig;
  }

  async switchTheme(theme: Theme) {
    await this.getThemeConfig(theme);
    localStorage.setItem(this.getThemeKey, theme);
    this.setTheme(theme);
  }

  async initTheme() {
    const currentTheme = localStorage.getItem(this.getThemeKey);

    const themeExists = Object.values(Theme).includes(currentTheme as Theme);

    if (themeExists) {
      this.currentTheme = currentTheme as Theme;
    }

    await this.getThemeConfig(this.currentTheme as Theme);
  }

  get getThemeKey() {
    return `${project}-theme`;
  }

  @action
  setTheme(theme: Theme) {
    this.currentTheme = theme;
  }
}

export default UiStore;
