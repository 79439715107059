import { Card } from 'antd';
import { Helmet } from 'react-helmet';
import './login.scss';
import { FunctionComponent } from 'react';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

const Login: FunctionComponent<{ loginUrl: string }> = ({ loginUrl }) => {
  const onGoogleLogin = () => {
    window.location.href = loginUrl;
  };

  return (
    <div className="login-screen">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Card className="login-card">
        <Button onClick={onGoogleLogin} className={'google-login-button'}>
          <GoogleOutlined />
          Login with Google
        </Button>
      </Card>
    </div>
  );
};

export default Login;
