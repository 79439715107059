import React, { FunctionComponent, Suspense, lazy } from 'react';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const Burn = lazy(() => import('./icons/burn.icon.svg?react'));
const CoinBag = lazy(() => import('./icons/coin-bag.icon.svg?react'));
const Person = lazy(() => import('./icons/person.icon.svg?react'));
const Compass = lazy(() => import('./icons/compass.icon.svg?react'));
const Badge = lazy(() => import('./icons/badge.icon.svg?react'));
const Bolt = lazy(() => import('./icons/bolt.icon.svg?react'));
const People = lazy(() => import('./icons/people.icon.svg?react'));
const MenuBurger = lazy(() => import('./icons/menu-burger.icon.svg?react'));
const MenuBurgerClose = lazy(
  () => import('./icons/menu-burger-close.icon.svg?react')
);
const PCredit = lazy(() => import('./icons/p-credit.icon.svg?react'));
const Youtube = lazy(() => import('./icons/youtube.icon.svg?react'));
const Discord = lazy(() => import('./icons/discord.icon.svg?react'));
const Bell = lazy(() => import('./icons/bell.icon.svg?react'));
const ChartAverage = lazy(() => import('./icons/chart-average.icon.svg?react'));
const Dpad = lazy(() => import('./icons/dpad.icon.svg?react'));
const Camera = lazy(() => import('./icons/camera.icon.svg?react'));
const GroupAccount = lazy(() => import('./icons/group-account.icon.svg?react'));
const Event = lazy(() => import('./icons/event.icon.svg?react'));
const Play = lazy(() => import('./icons/play.icon.svg?react'));
const BiDownload = lazy(() => import('./icons/bi-download.icon.svg?react'));
const Gauge = lazy(() => import('./icons/gauge.icon.svg?react'));
const Sun = lazy(() => import('./icons/sun.icon.svg?react'));
const FiversCredit = lazy(() => import('./icons/fivers-credit.icon.svg?react'));
const CertificateCheck = lazy(
  () => import('./icons/certificate-check.icon.svg?react')
);
const CheckCircle = lazy(() => import('./icons/check-circle.icon.svg?react'));
const CreditCardTwoBack = lazy(
  () => import('./icons/credit-card-two-back.icon.svg?react')
);
const Check = lazy(() => import('./icons/check.icon.svg?react'));
const CheckTwo = lazy(() => import('./icons/check-two.icon.svg?react'));
const MountainTop = lazy(() => import('./icons/mountain-top.icon.svg?react'));
const ChevronRight = lazy(() => import('./icons/chevron-right.icon.svg?react'));
const ChevronUp = lazy(() => import('./icons/chevron-up.icon.svg?react'));
const ChevronDown = lazy(() => import('./icons/chevron-down.icon.svg?react'));
const PersonTwo = lazy(() => import('./icons/person-two.icon.svg?react'));
const ChartStepper = lazy(() => import('./icons/chart-stepper.icon.svg?react'));
const CloudAuditing = lazy(
  () => import('./icons/cloud-auditing.icon.svg?react')
);
const Trophy = lazy(() => import('./icons/trophy.icon.svg?react'));
const Umbrella = lazy(() => import('./icons/umbrella.icon.svg?react'));
const Room = lazy(() => import('./icons/room.icon.svg?react'));
const Plug = lazy(() => import('./icons/plug.icon.svg?react'));
const Close = lazy(() => import('./icons/close.icon.svg?react'));
const Snow = lazy(() => import('./icons/snow.icon.svg?react'));
const Refresh = lazy(() => import('./icons/refresh.icon.svg?react'));
const Alarm = lazy(() => import('./icons/alarm.icon.svg?react'));
const BxRefresh = lazy(() => import('./icons/bx-refresh.icon.svg?react'));
const Rocket = lazy(() => import('./icons/rocket.icon.svg?react'));
const Bank = lazy(() => import('./icons/bank.icon.svg?react'));
const Recycle = lazy(() => import('./icons/recycle.icon.svg?react'));
const ClockOutline = lazy(() => import('./icons/clock-outline.icon.svg?react'));
const InvoiceOutline = lazy(
  () => import('./icons/invoice-outline.icon.svg?react')
);
const Exchange = lazy(() => import('./icons/exchange.icon.svg?react'));
const QuestionCircle = lazy(
  () => import('./icons/question-circle.icon.svg?react')
);
const Lock = lazy(() => import('./icons/lock.icon.svg?react'));
const CaretUp = lazy(() => import('./icons/bx-caret-up.icon.svg?react'));
const CaretDown = lazy(() => import('./icons/bx-caret-down.icon.svg?react'));
const Password = lazy(() => import('./icons/password.icon.svg?react'));
const Plus = lazy(() => import('./icons/plus.icon.svg?react'));
const ChartClusterBar = lazy(
  () => import('./icons/chart-cluster-bar.icon.svg?react')
);
const MoonStars = lazy(() => import('./icons/moon-stars.icon.svg?react'));
const Chess = lazy(() => import('./icons/chess.icon.svg?react'));
const Validation = lazy(() => import('./icons/validation.icon.svg?react'));
const Windows = lazy(() => import('./icons/windows.icon.svg?react'));
const Linux = lazy(() => import('./icons/linux.icon.svg?react'));
const iOS = lazy(() => import('./icons/mac.icon.svg?react'));
const Web = lazy(() => import('./icons/web.icon.svg?react'));
const Mac = lazy(() => import('./icons/mac.icon.svg?react'));
const Android = lazy(() => import('./icons/android.icon.svg?react'));
const Eye = lazy(() => import('./icons/eye.icon.svg?react'));
const Cash = lazy(() => import('./icons/cash.icon.svg?react'));
const Instagram = lazy(() => import('./icons/instagram.icon.svg?react'));
const Twitter = lazy(() => import('./icons/twitter.icon.svg?react'));
const Facebook = lazy(() => import('./icons/facebook.icon.svg?react'));
const ChevronLeft = lazy(() => import('./icons/chevron-left.icon.svg?react'));
const Download = lazy(() => import('./icons/download.icon.svg?react'));
const Whatsapp = lazy(() => import('./icons/whatsapp.icon.svg?react'));
const ModalPopup = lazy(() => import('./icons/modal-popup.icon.svg?react'));
const Union = lazy(() => import('./icons/union.icon.svg?react'));

type CustomIconComponentProps = Omit<IconComponentProps, 'ref'>;

export enum IconsNames {
  Burn = 'burn',
  CoinBag = 'coin-bag',
  Person = 'person',
  Compass = 'compass',
  Badge = 'badge',
  Bolt = 'bolt',
  People = 'people',
  MenuBurger = 'menu-burger',
  MenuBurgerClose = 'menu-burger-close',
  PCredit = 'p-credit',
  Youtube = 'youtube',
  Discord = 'discord',
  Bell = 'bell',
  ChartAverage = 'chart-average',
  Dpad = 'dpad',
  Camera = 'camera',
  GroupAccount = 'groupAccount',
  Event = 'event',
  Play = 'play',
  BiDownload = 'bi-download',
  Gauge = 'gauge',
  Sun = 'sun',
  FiversCredit = 'fivers-credit',
  CertificateCheck = 'certificate-check',
  CheckCircle = 'check-circle',
  CreditCardTwoBack = 'credit-card-two-back',
  Check = 'check',
  MountainTop = 'mountain-top',
  CheckTwo = 'check-two',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ChevronDown = 'chevron-down',
  PersonTwo = 'person-two',
  ChartStepper = 'chart-stepper',
  CloudAuditing = 'cloud-auditing',
  Trophy = 'trophy',
  Umbrella = 'umbrella',
  Room = 'room',
  Plug = 'plug',
  Close = 'close',
  Snow = 'snow',
  Refresh = 'refresh',
  Alarm = 'alarm',
  QuestionCircle = 'question-circle',
  BxRefresh = 'bx-refresh',
  Rocket = 'rocket',
  Bank = 'bank',
  Recycle = 'recycle',
  ClockOutline = 'clock-outline',
  InvoiceOutline = 'invoice-outline',
  Exchange = 'exchange',
  Lock = 'lock',
  CaretUp = 'caret-up',
  CaretDown = 'caret-down',
  Password = 'password',
  Plus = 'plus',
  ChartClusterBar = 'chart-cluster-bar',
  MoonStars = 'moon-stars',
  Chess = 'chess',
  Validation = 'validation',
  Windows = 'windows',
  Linux = 'linux',
  iOS = 'ios',
  Web = 'web',
  Mac = 'mac',
  Android = 'android',
  Eye = 'eye',
  Cash = 'cash',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Facebook = 'facebook',
  ChevronLeft = 'chevron-left',
  Download = 'download',
  Whatsapp = 'whatsapp',
  ModalPopup = 'modal-popup',
  Union = 'union',
}

const IconsMap = {
  [IconsNames.Burn]: Burn,
  [IconsNames.CoinBag]: CoinBag,
  [IconsNames.Person]: Person,
  [IconsNames.Compass]: Compass,
  [IconsNames.Badge]: Badge,
  [IconsNames.Bolt]: Bolt,
  [IconsNames.People]: People,
  [IconsNames.MenuBurger]: MenuBurger,
  [IconsNames.MenuBurgerClose]: MenuBurgerClose,
  [IconsNames.PCredit]: PCredit,
  [IconsNames.Youtube]: Youtube,
  [IconsNames.Discord]: Discord,
  [IconsNames.Bell]: Bell,
  [IconsNames.ChartAverage]: ChartAverage,
  [IconsNames.Dpad]: Dpad,
  [IconsNames.Camera]: Camera,
  [IconsNames.GroupAccount]: GroupAccount,
  [IconsNames.Event]: Event,
  [IconsNames.Play]: Play,
  [IconsNames.BiDownload]: BiDownload,
  [IconsNames.Gauge]: Gauge,
  [IconsNames.Sun]: Sun,
  [IconsNames.FiversCredit]: FiversCredit,
  [IconsNames.CertificateCheck]: CertificateCheck,
  [IconsNames.CheckCircle]: CheckCircle,
  [IconsNames.CreditCardTwoBack]: CreditCardTwoBack,
  [IconsNames.Check]: Check,
  [IconsNames.MountainTop]: MountainTop,
  [IconsNames.CheckTwo]: CheckTwo,
  [IconsNames.ChevronRight]: ChevronRight,
  [IconsNames.ChevronUp]: ChevronUp,
  [IconsNames.ChevronDown]: ChevronDown,
  [IconsNames.PersonTwo]: PersonTwo,
  [IconsNames.ChartStepper]: ChartStepper,
  [IconsNames.CloudAuditing]: CloudAuditing,
  [IconsNames.Trophy]: Trophy,
  [IconsNames.Umbrella]: Umbrella,
  [IconsNames.Room]: Room,
  [IconsNames.Plug]: Plug,
  [IconsNames.Close]: Close,
  [IconsNames.Snow]: Snow,
  [IconsNames.Refresh]: Refresh,
  [IconsNames.Alarm]: Alarm,
  [IconsNames.QuestionCircle]: QuestionCircle,
  [IconsNames.BxRefresh]: BxRefresh,
  [IconsNames.Rocket]: Rocket,
  [IconsNames.Bank]: Bank,
  [IconsNames.Recycle]: Recycle,
  [IconsNames.ClockOutline]: ClockOutline,
  [IconsNames.InvoiceOutline]: InvoiceOutline,
  [IconsNames.Exchange]: Exchange,
  [IconsNames.Lock]: Lock,
  [IconsNames.CaretUp]: CaretUp,
  [IconsNames.CaretDown]: CaretDown,
  [IconsNames.Password]: Password,
  [IconsNames.Plus]: Plus,
  [IconsNames.ChartClusterBar]: ChartClusterBar,
  [IconsNames.MoonStars]: MoonStars,
  [IconsNames.Chess]: Chess,
  [IconsNames.Validation]: Validation,
  [IconsNames.Windows]: Windows,
  [IconsNames.Linux]: Linux,
  [IconsNames.iOS]: iOS,
  [IconsNames.Web]: Web,
  [IconsNames.Mac]: Mac,
  [IconsNames.Android]: Android,
  [IconsNames.Eye]: Eye,
  [IconsNames.Cash]: Cash,
  [IconsNames.Instagram]: Instagram,
  [IconsNames.Twitter]: Twitter,
  [IconsNames.Facebook]: Facebook,
  [IconsNames.ChevronLeft]: ChevronLeft,
  [IconsNames.Download]: Download,
  [IconsNames.Whatsapp]: Whatsapp,
  [IconsNames.ModalPopup]: ModalPopup,
  [IconsNames.Union]: Union,
};

interface Props extends CustomIconComponentProps {
  iconName: IconsNames;
}

export const Icons: FunctionComponent<Props> = ({
  iconName,
  color,
  ...props
}) => {
  const iconComponent = IconsMap[iconName];
  return (
    <Suspense>
      <Icon
        style={{
          color: color,
        }}
        component={iconComponent}
        {...props}
      />
    </Suspense>
  );
};
