import {
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import {
  PaymentMethod,
  Payout,
  PayoutSource,
  PayoutStatus,
  ReasonToDecline,
} from '@monorepo/types';
import { Col, message, Row, Space } from 'antd';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import AccountAutocomplete from '../../components/account-autocomplete/account-autocomplete';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';
import { useStore } from '../../helpers/use-store';

const statuses = Object.values(PayoutStatus).map((status) => {
  return { value: status, label: status };
});

const paymentMethods = Object.values(PaymentMethod).map((paymentMethod) => {
  return { value: paymentMethod, label: paymentMethod };
});

const reasonToDeclines = Object.values(ReasonToDecline).map(
  (reasonToDecline) => {
    return { value: reasonToDecline, label: reasonToDecline };
  }
);

export const PayoutDrawer: FunctionComponent<{
  form: UseFormReturn<Payout>;
  type: PayoutSource;
}> = ({ form, type }) => {
  const {
    dataStore: { usersStore },
  } = useStore();

  const isEdit = !!form.getValues()._id;
  const currentStatus = form.watch('status');
  const showSendPayoutEmailCheckBox =
    form.formState.dirtyFields.status && currentStatus === PayoutStatus.Paid;

  const onUserSelect = async (userId: string) => {
    if (type === PayoutSource.Affiliate) {
      if (!userId) {
        return;
      }

      const user = await usersStore.fetchById(userId);
      if (!user.affiliateId) {
        message.error('User is not yet affiliate');
        return;
      }
      form.setValue('sourceId', user.affiliateId);
    }
    form.setValue('userId', userId);
  };

  const onAccountSelect = (accountId: string) => {
    form.setValue('sourceId', accountId);
  };

  return (
    <Row gutter={16}>
      {!isEdit && (
        <Space direction="vertical" style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Space direction="vertical">
              <TextComponent>User</TextComponent>
              <UserAutocomplete onSelect={(userId) => onUserSelect(userId)} />
            </Space>
          </Col>
          {type === PayoutSource.Account && (
            <Col span={24}>
              <Space direction="vertical">
                <TextComponent>Account</TextComponent>
                <AccountAutocomplete
                  onSelect={(accountId) => onAccountSelect(accountId)}
                />
              </Space>
            </Col>
          )}
        </Space>
      )}
      <Col span={24}>
        <FormInputNumber
          form={form}
          controllerProps={{
            name: 'amount',
            rules: {
              required: { value: true, message: 'Amount is required' },
            },
          }}
          text={'amount'}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'status',
            rules: {
              required: { value: true, message: 'Status is required' },
            },
          }}
          text={'status'}
          options={statuses}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'paymentMethod',
            rules: {
              required: { value: true, message: 'PaymentMethod is required' },
            },
          }}
          text={'paymentMethod'}
          options={paymentMethods}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          controllerProps={{
            name: 'transactionId',
          }}
          text={'transactionId'}
        />
      </Col>
      <Col span={24}>
        <FormInput
          form={form}
          controllerProps={{
            name: 'comment',
          }}
          text={'comment'}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'reasonToDecline',
          }}
          text={'reasonToDecline'}
          options={reasonToDeclines}
        />
      </Col>
      <Col span={24}>
        <FormDatePicker
          placeholder="Completed At"
          text={'Completed At'}
          form={form}
          controllerProps={{
            rules: {
              required: false,
            },
            name: 'completedAt',
          }}
        />
      </Col>
      {type === PayoutSource.Account && (
        <>
          <Col span={24}>
            <FormInputNumber
              form={form}
              controllerProps={{
                name: 'metadata.companyShare',
                rules: {
                  required: {
                    value: true,
                    message: 'Company share is required',
                  },
                },
              }}
              text={'companyShare'}
            />
          </Col>
          <Col span={24}>
            <FormInputNumber
              form={form}
              controllerProps={{
                name: 'metadata.userShare',
                rules: {
                  required: { value: true, message: 'User share is required' },
                },
              }}
              text={'userShare'}
            />
          </Col>
          <Col span={24}>
            <FormInputNumber
              form={form}
              controllerProps={{
                name: 'metadata.balanceAmount',
                rules: {
                  required: {
                    value: true,
                    message: 'Balance amount is required',
                  },
                },
              }}
              text={'balanceAmount'}
            />
          </Col>
          <Col span={24}>
            <FormInputNumber
              form={form}
              controllerProps={{
                name: 'metadata.creditAmount',
              }}
              text={'creditAmount'}
            />
          </Col>
        </>
      )}
      <Col span={24}>
        <FormInputNumber
          form={form}
          controllerProps={{
            name: 'metadata.userPayoutAfterCommission',
            rules: {
              required: {
                value: true,
                message: 'User payout after commission is required',
              },
            },
          }}
          text={'userPayoutAfterCommission'}
        />
      </Col>
      {showSendPayoutEmailCheckBox && (
        <Col span={24}>
          <FormCheckbox
            form={form}
            controllerProps={{
              name: 'sendPayoutEmail',
            }}
            text={'sendPayoutEmail'}
          />
        </Col>
      )}
    </Row>
  );
};
