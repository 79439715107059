import {
  FormInputNumber,
  TextComponent,
  TitleComponent,
} from '@monorepo/react-components';
import { AccountStateKey, CreditEvents } from '@monorepo/types';
import { Col, Row } from 'antd';
import { capitalize } from 'lodash';
import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  onSave: (item: CreditEvents) => void;
}

const ProgramCreditEventsForm = forwardRef<FormModalActions, Props>(
  ({ onSave }, ref) => {
    const form = useForm<CreditEvents>({ mode: 'onChange' });

    return (
      <FormModal
        title={'Update Credit Events'}
        ref={ref}
        destroyOnClose
        form={form}
        width="30%"
        onSave={async (item: CreditEvents) => {
          const itemToSave: any = {};
          Object.entries(item).forEach(([key, val]) => {
            if (val.amount) {
              itemToSave[key] = {
                amount: val?.amount,
                expirationInDays: val?.expirationInDays || 90,
              };
            }

            if (!val.amount && val.expirationInDays) {
              form.setError(`${key}.amount` as any, {
                message: 'Amount is required',
              });
              throw new Error('Amount is required');
            }
          });
          onSave(itemToSave);
        }}
      >
        {Object.values(AccountStateKey).map((event) => (
          <div key={event}>
            <TitleComponent level={5}>{capitalize(event)}</TitleComponent>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <TextComponent>Amount</TextComponent>
                <FormInputNumber
                  form={form}
                  placeholder="0"
                  controllerProps={{
                    name: `${event}.amount`,
                    rules: { required: false, min: 0 },
                  }}
                />
              </Col>
              <Col span={12}>
                <TextComponent>Expiry</TextComponent>
                <FormInputNumber
                  placeholder="Days to expire"
                  form={form}
                  controllerProps={{
                    rules: {
                      min: 0,
                      required: false,
                    },
                    name: `${event}.expirationInDays`,
                  }}
                />
              </Col>
            </Row>
          </div>
        ))}
      </FormModal>
    );
  }
);

export default ProgramCreditEventsForm;
