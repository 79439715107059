import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import { Button, Col, Row, message } from 'antd';
import { FormInput } from '@monorepo/react-components';
import { TradingSystem } from '@monorepo/types';

type DeletePositionPayload = {
  externalId: string;
};

type DeletePositionProps = {
  tradingSystem: TradingSystem;
};

const DeletePositionModal: FunctionComponent<DeletePositionProps> = ({
  tradingSystem,
}) => {
  const {
    dataStore: { tradingSystemStore },
  } = useStore();

  const formModalRef = useRef<FormModalActions>({} as FormModalActions);

  const form = useForm<DeletePositionPayload>({ mode: 'onChange' });

  const deletePosition = async (item: DeletePositionPayload) => {
    try {
      const response = await tradingSystemStore.deletePositionByExternalId(
        tradingSystem,
        item.externalId
      );

      if (!response) {
        message.error('Position not found');
        return;
      }

      message.success('Position deleted successfully');
    } catch (e) {
      console.error(e);
      message.error('Something went wrong');
    }
  };

  return (
    <>
      <Button onClick={() => formModalRef.current.open()}>
        Delete Position
      </Button>

      <FormModal
        title={'Delete Position'}
        ref={formModalRef}
        form={form}
        width={'30%'}
        onSave={deletePosition}
      >
        <Row>
          <Col span={10}>
            <FormInput
              text={'Position ID'}
              controllerProps={{
                name: 'externalId',
                rules: { required: 'Position ID is required' },
              }}
              form={form}
            />
          </Col>
        </Row>
      </FormModal>
    </>
  );
};

export default DeletePositionModal;
