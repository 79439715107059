import React, { FunctionComponent } from 'react';
import { Tag as AntdTag, TagProps } from 'antd';
import { generateColor } from 'antd/es/color-picker/util';
import { ThemeColors } from '@monorepo/types';
import classNames from 'classnames';

export type { TagProps };

export const Tag: FunctionComponent<TagProps> = (props) => {
  return <AntdTag className="the5ers-tag" {...props} />;
};

type Props = Omit<TagProps, 'color'> & {
  color?: keyof typeof ThemeColors;
  fill?: boolean;
};

export const Label: FunctionComponent<Props> = ({
  color,
  bordered = true,
  fill,
  style,
  className,
  ...props
}) => {
  const getColorProperties = (): React.CSSProperties => {
    if (!color) {
      return {};
    }

    const bodyStyle = getComputedStyle(document.body);
    const cssEnv = bodyStyle.getPropertyValue(`--${ThemeColors[color]}`);
    if (!cssEnv) {
      return {};
    }

    const rgbColors = generateColor(cssEnv).toRgb();
    const rgbColorJoin = `${rgbColors.r}, ${rgbColors.g}, ${rgbColors.b}`;

    const cssProperties: React.CSSProperties = {
      backgroundColor: `rgba(${rgbColorJoin}, 0.1)`,
      borderColor: bordered ? `rgba(${rgbColorJoin}, 0.4)` : 'transparent',
      color: `rgba(${rgbColorJoin})`,
    };

    if (fill) {
      cssProperties.backgroundColor = `rgba(${rgbColorJoin}, 0.8)`;
      cssProperties.color = `white`;
      cssProperties.borderColor = `rgba(${rgbColorJoin}, 0.8)`;
    }

    return cssProperties;
  };

  return (
    <AntdTag
      className={classNames('the5ers-tag', className)}
      style={{ ...getColorProperties(), ...style }}
      {...props}
    />
  );
};
