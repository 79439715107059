import { EditOutlined } from '@ant-design/icons';
import {
  FormSelect,
  TextComponent,
  FormTextArea,
  AccountStatusTag,
  FormCheckbox,
} from '@monorepo/react-components';
import {
  Abilities,
  AccountStatus,
  UpdateAccountStatus,
  ACCOUNT_STATE_TITLES,
  AccountStateKey,
  TerminationReason,
} from '@monorepo/types';
import { App, Button, Flex, Space, Tooltip } from 'antd';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import ProtectedElement from '../../components/protected-element/protected-element';

type UpdateStatusPayload = Omit<UpdateAccountStatus, 'id'>;

type Props = {
  id: string;
  status: UpdateStatusPayload['status'];
  disableReason?: UpdateStatusPayload['disableReason'];
  onSave: () => void;
};

const CHANGEABLE_STATUSES = [AccountStatus.Active, AccountStatus.Disabled];

const AccountStatusCell: FunctionComponent<Props> = ({
  id,
  status,
  onSave,
  disableReason = '',
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const { message } = App.useApp();
  const statusFormRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<UpdateStatusPayload>({ mode: 'onChange' });

  const currentStatus = form.watch('status');
  const currentState = form.watch('state');

  const isSelectedTerminatedStatus =
    currentState === AccountStateKey.Terminated;
  const isSelectedDisabledStatus = currentStatus === AccountStatus.Disabled;

  const updateStatus = async (item: UpdateStatusPayload) => {
    try {
      await accountStore.updateStatus({
        id,
        ...item,
      });
      onSave();
    } catch (e) {
      message.error('Something went wrong');
      console.error(e);
    }
  };

  const showSendRiskEmailCheckBox =
    form.formState.dirtyFields.status && currentStatus === AccountStatus.Active;

  useEffect(() => {
    if (!isSelectedTerminatedStatus) {
      form.resetField('terminationReason');
    }

    if (!isSelectedDisabledStatus) {
      form.resetField('disableReason');
      form.resetField('state');
      form.resetField('terminationReason');
    }
  }, [currentState, currentStatus]);

  return (
    <ProtectedElement
      abilities={[Abilities.ManageAccounts]}
      notAllowedElement={<AccountStatusTag status={status} />}
    >
      <div className="account-status">
        <Space>
          <Tooltip title={disableReason}>
            <AccountStatusTag status={status} />
            <Button
              size="small"
              onClick={() => statusFormRef.current.open({ status })}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </Space>
        <FormModal
          title={'Update account status'}
          ref={statusFormRef}
          width={'30%'}
          onSave={updateStatus}
          form={form}
          destroyOnClose={true}
        >
          <FormSelect
            form={form}
            controllerProps={{
              name: 'status',
            }}
            text={'Account status'}
            options={Object.values(CHANGEABLE_STATUSES).map((status) => ({
              label: <TextComponent capitalize={true}>{status}</TextComponent>,
              value: status,
            }))}
          />
          {isSelectedDisabledStatus && (
            <>
              <FormSelect
                form={form}
                controllerProps={{
                  name: 'state',
                  rules: { required: 'Please select state' },
                }}
                text={'Account state'}
                options={Object.entries(ACCOUNT_STATE_TITLES).map(
                  ([key, state]) => ({
                    label: (
                      <TextComponent capitalize={true}>{state}</TextComponent>
                    ),
                    value: key,
                  })
                )}
              />
              {isSelectedTerminatedStatus && (
                <FormSelect
                  controllerProps={{
                    name: 'terminationReason',
                  }}
                  form={form}
                  options={Object.entries(TerminationReason).map(
                    ([key, reason]) => ({
                      label: (
                        <TextComponent capitalize={true}>
                          {reason}
                        </TextComponent>
                      ),
                      value: key,
                    })
                  )}
                  text={'Termination reason'}
                />
              )}
              <FormTextArea
                controllerProps={{
                  name: 'disableReason',
                }}
                textareaProps={{
                  required: true,
                  autoSize: { minRows: 2, maxRows: 6 },
                  style: { marginBottom: 10 },
                  maxLength: 200,
                  showCount: true,
                }}
                form={form}
                placeholder="..."
                text={'Disable reason'}
              />
            </>
          )}
          {showSendRiskEmailCheckBox && (
            <Flex gap={20}>
              <span>Send risk email</span>
              <FormCheckbox
                form={form}
                controllerProps={{ name: 'sendRiskEmail' }}
              />
            </Flex>
          )}
        </FormModal>
      </div>
    </ProtectedElement>
  );
};

export default AccountStatusCell;
