import { useStore } from '../../../helpers/use-store';
import { FunctionComponent, useEffect } from 'react';
import { Program } from '@monorepo/types';
import { observer } from 'mobx-react-lite';
import { TextComponent } from '@monorepo/react-components';

interface Props {
  program: Program;
}

const NextProgram: FunctionComponent<Props> = ({ program }) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const nextProgramId = program.nextProgramId;
  const nextProgram = programStore.get(nextProgramId || '');

  useEffect(() => {
    if (nextProgramId && !nextProgram) {
      programStore.fetch(nextProgramId);
    }
  }, [program]);

  return (
    <TextComponent ellipsis={true}>
      {nextProgramId ? nextProgram?.name : '-----'}
    </TextComponent>
  );
};

export default observer(NextProgram);
