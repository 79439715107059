import { Program } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import Autocomplete, { Option } from '../autocomplete/autocomplete';

interface Props {
  onSelect: (item: Option) => void;
  onDeselect?: (item: Option) => void;
  onClear: () => void;
  mode?: 'multiple' | 'tags';
}

const ProgramAutocomplete: FunctionComponent<Props> = ({
  onSelect,
  onClear,
  onDeselect,
  mode,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const searchInputChange = async (name: string) => {
    const programs = (await programStore.search({
      name,
    })) as Partial<Program>[];

    return programs.map((program) => ({
      label: program.name,
      value: program._id,
    })) as Option[];
  };

  return (
    <Autocomplete
      mode={mode}
      onSelect={onSelect}
      onClear={onClear}
      onDeselect={onDeselect}
      searchInputChange={searchInputChange}
      allowTermSearch
      placeholder="select program"
      width="300px"
    />
  );
};

export default ProgramAutocomplete;
