import { EntityStore } from '@monorepo/client-common';
import { PaginationRequest, Payout, User } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import PayoutsService from '../../../services/entities/payouts-service';

export type PayoutWithUser = Payout & { user: User };

export default class PayoutStore extends EntityStore<PayoutsService, Payout> {
  constructor() {
    const {
      apiFactory: { payoutsService },
    } = getEnv();

    super(payoutsService);
  }

  paginate(paginateRequest: PaginationRequest) {
    return this.service.paginate<PayoutWithUser>(paginateRequest);
  }

  fetchCurrenciesRates() {
    return this.service.fetchCurrenciesRates();
  }
  initiatePayment(payoutId: string) {
    return this.service.initiatePayment(payoutId);
  }
  pay(payoutId: string, code: string) {
    return this.service.pay(payoutId, code);
  }
}
