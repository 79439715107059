import { EditOutlined } from '@ant-design/icons';
import {
  FormCheckbox,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { Abilities, OrderStatus, UpdateStatusPayload } from '@monorepo/types';
import { App, Button, Space } from 'antd';
import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import FormModal, { FormModalActions } from '../form-modal/form-modal';
import ProtectedElement from '../../components/protected-element/protected-element';
import { OrderStatusTag } from '@monorepo/react-components';

type Props = {
  id: string;
  status: OrderStatus;
  onSave: () => void;
};

const OrderStatusCell: FunctionComponent<Props> = ({ id, status, onSave }) => {
  const {
    dataStore: { orderStore },
  } = useStore();

  const { message } = App.useApp();

  const statusFormRef = useRef<FormModalActions>({} as FormModalActions);

  const form = useForm<UpdateStatusPayload>({ mode: 'onChange' });

  const updateStatus = async (item: UpdateStatusPayload) => {
    try {
      await orderStore.updateStatus(id, item);
      onSave();
    } catch (e) {
      message.error('Something went wrong');
      console.error(e);
    }
  };

  const selectedStatus = form.watch('status');

  const showRefundCheckbox =
    status !== OrderStatus.Refunded && selectedStatus === OrderStatus.Refunded;

  return (
    <ProtectedElement
      abilities={[Abilities.ManageOrders]}
      notAllowedElement={<OrderStatusTag status={status} />}
    >
      <div className="order-status">
        <Space>
          <OrderStatusTag status={status} />
          <Button
            size="small"
            onClick={() => statusFormRef.current.open({ status })}
            icon={<EditOutlined />}
          />
        </Space>
        <FormModal
          title={'Update order status'}
          ref={statusFormRef}
          form={form}
          width={'30%'}
          onSave={updateStatus}
        >
          <FormSelect
            form={form}
            controllerProps={{
              name: 'status',
            }}
            text={'Order status'}
            options={Object.values(OrderStatus).map((status) => ({
              label: <TextComponent capitalize={true}>{status}</TextComponent>,
              value: status,
            }))}
          />
          {showRefundCheckbox && (
            <FormCheckbox
              form={form}
              text={'Disable account'}
              controllerProps={{
                name: 'disableAccount',
              }}
            />
          )}
        </FormModal>
      </div>
    </ProtectedElement>
  );
};

export default OrderStatusCell;
