import { BaseResponse, CrudService } from '@monorepo/client-common';

export default class PayoutsService extends CrudService {
  static override route = 'payout';

  async fetchCurrenciesRates() {
    try {
      return await fetch('https://cdn.moneyconvert.net/api/latest.json').then(
        (res) => res.json()
      );
    } catch (e) {
      console.error('Failed fetching currencies rates', e);
      throw e;
    }
  }
  async initiatePayment(payoutId: string) {
    const response = await this.httpService.post<null, BaseResponse<string>>(
      `${this.path}/${payoutId}/initiate-payment`
    );

    return response.data;
  }
  async pay(payoutPaymentRequestId: string, code: string) {
    const response = await this.httpService.post<
      { code: string },
      BaseResponse<boolean>
    >(`${this.path}/${payoutPaymentRequestId}/pay`, {
      code,
    });

    return response.data;
  }
}
