import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { Abilities, RoleAbility } from '@monorepo/types';
import { message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import { RoleAbilityDrawer } from '../role-ability-drawer/role-ability-drawer';

const getDefaultValues = (roleAbility?: RoleAbility): Partial<RoleAbility> => {
  if (roleAbility) {
    return roleAbility;
  }
  return {
    role: undefined,
    abilities: [Abilities.JumpHigh],
  };
};

const RolesAbilities = () => {
  const {
    dataStore: { rolesAbilitiesStore, userStore },
  } = useStore();
  const form = useForm<RoleAbility & { _id: string }>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await rolesAbilitiesStore.paginate({ page, limit });
    } catch (e) {
      console.error(
        `Failed loading role abilities for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<RoleAbility & { _id: string }> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary" copyable={{ text: item._id }} />;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      align: 'center',
      render: (_, item) => {
        return <TextComponent type="secondary">{item.role}</TextComponent>;
      },
    },
    {
      title: 'Abilities',
      dataIndex: 'abilities',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary">
            {item.abilities.join(',')}
          </TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async (isEdit?: boolean) => {
    try {
      if (isEdit) {
        await rolesAbilitiesStore.update(
          form.getValues()._id,
          form.getValues()
        );
        message.info('Update role ability successfully');
      } else {
        await rolesAbilitiesStore.create(form.getValues());
        message.info('Create role ability successfully');
      }
    } catch (error) {
      message.error(`Failed to ${isEdit ? 'update' : 'create'} role ability`);
    }
  };

  const onDelete = async (id: string) => {
    try {
      await rolesAbilitiesStore.delete(id);
      message.info('Delete role ability successfully');
    } catch (error) {
      message.error('Failed to delete role ability');
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<RoleAbilityDrawer form={form} />}
      onSubmit={submitForm}
      columns={columns}
      isEditAllowed
      onDelete={onDelete}
      isCreateAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default RolesAbilities;
