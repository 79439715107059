import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import {
  AffiliateCommissionStatus,
  AffiliationCommission,
  AutomaticStatuses,
} from '@monorepo/types';
import {
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';
import UserAutocomplete from '../../components/user-autocomplete/user-autocomplete';

export const AffiliationCommissionDrawer: FunctionComponent<{
  form: UseFormReturn<AffiliationCommission>;
}> = ({ form }) => {
  const isEditMode = Boolean(form.getValues('affiliateId'));

  const onSelectUser = async (userId: string) => {
    form.setValue('userId', userId, { shouldDirty: true });
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        {!isEditMode ? (
          <>
            <div>Email:</div>
            <UserAutocomplete onSelect={(userId) => onSelectUser(userId)} />
          </>
        ) : (
          <FormInput
            placeholder="Affiliate Email"
            text={'Affiliate Email'}
            form={form}
            props={{ disabled: true }}
            controllerProps={{
              name: 'affiliateEmail',
            }}
          />
        )}
      </Col>
      <Col span={24}></Col>
      {!isEditMode && (
        <Col span={24}>
          <FormInputNumber
            placeholder={'0'}
            text={'Commission amount'}
            form={form}
            controllerProps={{
              name: 'amount',
              rules: {
                min: { value: 0, message: 'Minimum commission is 0' },
              },
            }}
          />
        </Col>
      )}
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'status',
          }}
          text={'Commission status'}
          options={Object.values(AffiliateCommissionStatus)
            .filter((status) => !AutomaticStatuses.includes(status))
            .map((status) => ({
              label: <TextComponent capitalize={true}>{status}</TextComponent>,
              value: status,
            }))}
        />
      </Col>
      {!isEditMode && (
        <Col>
          <FormDatePicker
            placeholder="due at"
            text={'Due at'}
            form={form}
            controllerProps={{
              rules: {
                required: true,
              },
              name: 'dueAt',
            }}
          />
        </Col>
      )}
    </Row>
  );
};
