import { BaseResponse, CrudService } from '@monorepo/client-common';
import { Organization, OrganizationRequestFilters } from '@monorepo/types';

export default class OrganizationService extends CrudService {
  static override route = 'organization';

  async search(filters: OrganizationRequestFilters): Promise<Organization[]> {
    const response = await this.httpService.get<BaseResponse<Organization[]>>(
      `${this.path}/search`,
      {
        params: filters,
      }
    );

    return response.data;
  }
}
