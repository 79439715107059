import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { Abilities, AdminUser, Role } from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'react-hook-form';
import { useStore } from '../../helpers/use-store';
import { AdminUserDrawer } from '../admin-user-drawer/admin-user-drawer';
import { message } from 'antd';
import { AdminUserWithId } from '../../stores/data/admin-users/admin-users-store';

const getDefaultValues = (adminUser?: AdminUser): Partial<AdminUser> => {
  if (adminUser) {
    return adminUser;
  }
  return {
    email: '',
    firstName: '',
    lastName: '',
    role: Role.None,
    abilities: [Abilities.JumpHigh], // add some fun :)
  };
};

const AdminUsers = () => {
  const {
    dataStore: { adminUsersStore, userStore },
  } = useStore();
  const form = useForm<AdminUser & { _id: string }>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  });

  const requestPage = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      return await adminUsersStore.list();
    } catch (e) {
      console.error(
        `Failed loading admin users for page: ${page}, limit: ${limit}`,
        e
      );
      return { total: 0, results: [] };
    }
  };

  const columns: ColumnsType<AdminUser> = [
    {
      title: 'ID',
      dataIndex: '_id',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent type="secondary" copyable={{ text: item.userId }} />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.firstName} {item.lastName}
          </TextComponent>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.email}
          </TextComponent>
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.role}
          </TextComponent>
        );
      },
    },
    {
      title: 'Abilities',
      dataIndex: 'abilities',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true} type="secondary">
            {item.abilities.join(',')}
          </TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async (isEdit?: boolean) => {
    try {
      if (isEdit) {
        await adminUsersStore.update(form.getValues()._id, form.getValues());
        message.info('Update user successfully');
      } else {
        await adminUsersStore.create(form.getValues());
        message.info('Create user successfully');
      }
    } catch (error) {
      message.error(`Failed to ${isEdit ? 'update' : 'create'} admin user`);
    }
  };

  const onDelete = async (userId: string) => {
    try {
      const item = adminUsersStore.listUsers.find((item: AdminUserWithId) => {
        return item._id === userId;
      });

      if (!item) {
        throw new Error(`can't find user sub: ${userId}`);
      }

      await adminUsersStore.delete(item.email);
      message.info('Delete user successfully');
    } catch (error) {
      message.error('Failed to delete user');
    }
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      drawerBody={<AdminUserDrawer form={form} />}
      onSubmit={submitForm}
      columns={columns}
      isEditAllowed
      onDelete={onDelete}
      isCreateAllowed
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default AdminUsers;
