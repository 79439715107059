import { FormCheckboxGroup } from '@monorepo/react-components';
import {
  AccountState,
  AccountStateKey,
  ACCOUNT_STATE_TITLES,
} from '@monorepo/types';
import { Button, message } from 'antd';
import { FunctionComponent, useRef } from 'react';
import FormModal, { FormModalActions } from '../../form-modal/form-modal';
import { useForm } from 'react-hook-form';
import { EditOutlined } from '@ant-design/icons';
import { useStore } from '../../../helpers/use-store';
import { useResponsiveness } from '@monorepo/client-common';

const stateModel = Object.values(AccountStateKey);

type EditStateModalProps = {
  id: string;
  state: AccountState;
  onSave: () => void;
};

type EditStateForm = {
  state: [keyof AccountState];
};

export const EditStateModal: FunctionComponent<EditStateModalProps> = ({
  id,
  state,
  onSave,
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();

  const formRef = useRef<FormModalActions>({} as FormModalActions);
  const form = useForm<EditStateForm>();

  const { isMobile } = useResponsiveness();

  const updateState = async () => {
    try {
      const selectedStates = form.getValues().state;

      for (const key of stateModel) {
        const isSelected = selectedStates.includes(key as keyof AccountState);

        state[key as keyof AccountState] = isSelected;
      }

      await accountStore.updateState(id, state);

      onSave();
    } catch (e) {
      console.error(e);
      message.error('Something went wrong.');
    }
  };

  const stateOptions = Object.entries(ACCOUNT_STATE_TITLES).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  );

  const getDefaultValues = () => {
    const activeStates = Object.keys(state || {}).filter((key) =>
      Boolean(state[key as keyof AccountState])
    );
    return { state: activeStates as [keyof AccountState] };
  };

  return (
    <>
      <Button
        size="small"
        onClick={() => formRef.current.open()}
        icon={<EditOutlined />}
      />
      <FormModal
        title={'Update account state'}
        ref={formRef}
        form={form}
        width={isMobile ? '' : '30%'}
        onSave={updateState}
        getDefaultValues={getDefaultValues}
      >
        <FormCheckboxGroup
          form={form}
          controllerProps={{
            name: 'state',
          }}
          options={stateOptions}
          vertical={true}
        />
      </FormModal>
    </>
  );
};
