import {
  FormCheckbox,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { ApplicationTradingSystem, TradingSystem } from '@monorepo/types';
import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const ApplicationTradingSystemDrawer: FunctionComponent<{
  form: UseFormReturn<ApplicationTradingSystem>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'tradingSystem',
            rules: { required: true },
          }}
          text={'Trading System'}
          placeholder={'Select Trading System'}
          options={[
            {
              label: 'Select Trading System',
              value: '',
              disabled: true,
            },
            ...Object.values(TradingSystem).map((accountType) => ({
              label: (
                <TextComponent capitalize={true}>{accountType}</TextComponent>
              ),
              value: accountType,
            })),
          ]}
        />
      </Col>
      <Col span={12}>
        <FormCheckbox
          checkboxText={`Sellable`}
          form={form}
          controllerProps={{
            name: 'sellable',
            rules: { required: false },
          }}
        />
      </Col>
    </Row>
  );
};
