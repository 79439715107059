import { PartnerType, Program } from '@monorepo/types';
import { FunctionComponent, useRef } from 'react';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { TextComponent } from '@monorepo/react-components';
import ProgramBoostersForm from './program-boosters-form';
import { FormModalActions } from '../../form-modal/form-modal';

interface Props {
  program: Program;
  onSave: () => void;
}

const ProgramBoosters: FunctionComponent<Props> = ({ program, onSave }) => {
  const currentBoosters = program.config.boosters;
  const currentBoostersKeys = Object.keys(program.config.boosters || {});
  const programFormRef = useRef<FormModalActions>({} as FormModalActions);

  const items: DescriptionsProps['items'] = currentBoostersKeys.map(
    (booster: string) => {
      return {
        key: booster,
        label: <TextComponent capitalize={true}>{booster}</TextComponent>,
        children: currentBoosters ? currentBoosters[booster as PartnerType] : 0,
      };
    }
  );

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() => programFormRef.current.open(program)}
        >
          {currentBoostersKeys.length ? 'Edit' : 'Create'}
        </Button>
      </Col>
      <Col span={24}>
        {currentBoostersKeys.length > 0 && (
          <Descriptions column={2} layout="vertical" bordered items={items} />
        )}
        <ProgramBoostersForm onSave={onSave} ref={programFormRef} />
      </Col>
    </Row>
  );
};

export default ProgramBoosters;
