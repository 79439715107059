import {
  AdminNotification as AdminNotificationType,
  NotificationName,
} from '@monorepo/types';
import { FunctionComponent } from 'react';
import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../helpers/use-store';
import { ColumnsType } from 'antd/es/table';
import { AdminNotificationDrawer } from './application-config-admin-notification-drawer';

interface Props {
  adminNotifications: AdminNotificationType[];
  onSave: (adminNotification: AdminNotificationType[]) => void;
}

const getEventName = (name: NotificationName) => {
  for (const key in NotificationName) {
    if (NotificationName[key as keyof typeof NotificationName] === name) {
      return key;
    }
  }
  return name;
};

const getDefaultValues = (
  adminNotification?: AdminNotificationType
): AdminNotificationType => {
  if (adminNotification) {
    return adminNotification;
  }

  return {
    name: NotificationName.AdminAccountPayout,
    recipients: [],
  };
};

const AdminNotification: FunctionComponent<Props> = ({
  adminNotifications,
  onSave,
}) => {
  const form = useForm<AdminNotificationType>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const requestPage = async () => {
    return {
      total: adminNotifications.length,
      results: adminNotifications.map((adminNotification) => ({
        _id: adminNotification.name,
        ...adminNotification,
      })),
    };
  };

  const columns: ColumnsType<AdminNotificationType> = [
    {
      title: 'Event name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {getEventName(item.name)}
          </TextComponent>
        );
      },
    },
    {
      title: 'Recipients',
      dataIndex: 'subject',
      width: '10%',
      render: (_, item) => {
        return (
          <TextComponent ellipsis={true}>
            {item.recipients.join(', ')}
          </TextComponent>
        );
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async () => {
    const adminNotification = form.getValues();

    const index = adminNotifications.findIndex(
      (item) => item.name === adminNotification.name
    );

    if (index === -1) {
      adminNotifications.push(adminNotification);
    } else {
      adminNotifications[index] = adminNotification;
    }

    onSave(adminNotifications);
  };

  const onDelete = (name: string) => {
    const index = adminNotifications.findIndex((item) => item.name === name);

    if (index !== -1) {
      adminNotifications.splice(index, 1);
    }
    onSave(adminNotifications);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      onSubmit={submitForm}
      columns={columns}
      drawerBody={<AdminNotificationDrawer form={form} />}
      isEditAllowed
      isCreateAllowed
      onDelete={onDelete}
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};
export default AdminNotification;
