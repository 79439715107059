import {
  EntityPageWithDrawer,
  TextComponent,
} from '@monorepo/react-components';
import {
  Abilities,
  ApplicationScheduledJob,
  DEFAULT_TIME_ZONE,
} from '@monorepo/types';
import { ColumnsType } from 'antd/es/table';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicationScheduledJobDrawer } from './application-scheduled-job-drawer';
import { Button, message } from 'antd';
import ProtectedElement from '../../../components/protected-element/protected-element';
import { useStore } from '../../../helpers/use-store';

const getDefaultValues = (
  entity?: ApplicationScheduledJob
): Partial<ApplicationScheduledJob> => {
  if (entity) {
    return { ...entity, payload: JSON.stringify(entity.payload) };
  }

  return {
    name: undefined,
    jobType: undefined,
    timeZone: DEFAULT_TIME_ZONE,
    payload: '{}',
  };
};

interface Props {
  scheduledJobs: ApplicationScheduledJob[];
  onSave: (scheduledJobs: ApplicationScheduledJob[]) => void;
  onRunJob: (job: ApplicationScheduledJob) => void;
}

const ApplicationConfigScheduledJobs: FunctionComponent<Props> = ({
  scheduledJobs,
  onSave,
  onRunJob,
}) => {
  const form = useForm<ApplicationScheduledJob>({
    mode: 'all',
  });

  const {
    dataStore: { userStore },
  } = useStore();

  const requestPage = async () => {
    return {
      total: scheduledJobs.length,
      results: scheduledJobs.map((scheduledJob) => ({
        _id: scheduledJob.name,
        ...scheduledJob,
      })),
    };
  };

  const columns: ColumnsType<ApplicationScheduledJob> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.name}</TextComponent>;
      },
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.jobType}</TextComponent>;
      },
    },
    {
      title: 'Timezone',
      dataIndex: 'timeZone',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.timeZone}</TextComponent>;
      },
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      width: '10%',
      render: (_, item) => {
        return <TextComponent>{item.schedule}</TextComponent>;
      },
    },
  ];

  const validateForm = async () => {
    await form.trigger();
    return form.formState.isValid;
  };

  const submitForm = async () => {
    const scheduledJob = form.getValues();

    const index = scheduledJobs.findIndex(
      (item) => item.name === scheduledJob.name
    );
    try {
      const a = scheduledJob.payload as string;
      scheduledJob.payload = JSON.parse(a);
    } catch (e) {
      scheduledJob.payload = {};
    }

    if (index === -1) {
      scheduledJobs.push(scheduledJob);
    } else {
      scheduledJobs[index] = scheduledJob;
    }
    onSave(scheduledJobs);
  };

  const onRunJobClicked = async () => {
    const result = await form.trigger(['payload', 'jobType']);
    if (!result) {
      message.error('Job is not valid to execute');
      return;
    }
    const scheduledJob = form.getValues();
    try {
      const a = scheduledJob.payload as string;
      scheduledJob.payload = JSON.parse(a);
    } catch (e) {
      scheduledJob.payload = {};
    }
    onRunJob(scheduledJob);
  };

  const onDelete = (name: string) => {
    const index = scheduledJobs.findIndex((item) => item.name === name);

    if (index !== -1) {
      scheduledJobs.splice(index, 1);
    }
    onSave(scheduledJobs);
  };

  return (
    <EntityPageWithDrawer
      getDefaultValues={getDefaultValues}
      form={form}
      validateForm={validateForm}
      onSubmit={submitForm}
      columns={columns}
      drawerBody={<ApplicationScheduledJobDrawer form={form} />}
      isEditAllowed
      isCreateAllowed
      onDelete={onDelete}
      extraActions={
        <ProtectedElement abilities={[Abilities.ManageApplicationConfig]}>
          <Button onClick={onRunJobClicked} type="primary">
            Execute Job Now
          </Button>
        </ProtectedElement>
      }
      requestNextPage={requestPage}
      abilities={userStore.currentUser.abilities}
    />
  );
};

export default ApplicationConfigScheduledJobs;
