import { Abilities, Role } from '@monorepo/types';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useStore } from '../../helpers/use-store';

type Props = PropsWithChildren<{
  abilities: Abilities[];
  notAllowedElement?: JSX.Element;
}>;

const ProtectedElement: FunctionComponent<Props> = ({
  children,
  notAllowedElement = null,
  abilities,
}) => {
  const {
    dataStore: {
      userStore: { currentUser, isUserAllowed },
    },
  } = useStore();

  if (currentUser.role === Role.SuperUser) {
    return children;
  }

  return isUserAllowed(currentUser, abilities) ? children : notAllowedElement;
};

export default ProtectedElement;
