import { EntityStore } from '@monorepo/client-common';
import { CreditTransaction, PaginationRequest } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import CreditsService from '../../../services/entities/credits-service';

export default class CreditStore extends EntityStore<
  CreditsService,
  CreditTransaction
> {
  constructor() {
    const {
      apiFactory: { creditsService },
    } = getEnv();

    super(creditsService);
  }

  paginate(paginateRequest: PaginationRequest) {
    return this.service.paginate<CreditTransaction>(paginateRequest);
  }
}
