import { Partner } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '@monorepo/client-common';
import PartnerService from '../../../services/entities/partner-service';

export default class PartnerStore extends EntityStore<PartnerService, Partner> {
  constructor() {
    const {
      apiFactory: { partnerService },
    } = getEnv();

    super(partnerService);
  }
}
