import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  BankOutlined,
  ContainerOutlined,
  CrownOutlined,
  DollarOutlined,
  FundViewOutlined,
  GiftOutlined,
  HeatMapOutlined,
  HomeOutlined,
  InboxOutlined,
  LeftCircleOutlined,
  MoneyCollectOutlined,
  OrderedListOutlined,
  RightCircleOutlined,
  SettingOutlined,
  SmileOutlined,
  StopOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useThemeToken } from '@monorepo/client-common';
import { TextComponent } from '@monorepo/react-components';
import { Abilities, AdminUser, isUserAllowed } from '@monorepo/types';
import { Flex, Layout as AntdLayout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import Logo from '../logo/logo';
import Header from './header/header';
import './layout.scss';

const { Content, Sider } = AntdLayout;
type MenuItem = {
  abilities?: Abilities[];
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: 'group';
};

const items = [
  {
    label: 'Home',
    key: 'home',
    icon: <HomeOutlined className="sider-icon" />,
  },
  {
    icon: <AppstoreOutlined className="sider-icon" />,
    label: 'App Config',
    key: 'application-config',
    abilities: [Abilities.ManageApplicationConfig],
  },
  {
    icon: <CrownOutlined className="sider-icon" />,
    label: 'Admin Users',
    key: 'admin-users',
    abilities: [Abilities.ManageAdminUsers],
  },
  {
    icon: <StopOutlined className="sider-icon" />,
    label: 'Blacklist Users',
    key: 'blacklist-users',
    abilities: [Abilities.BlacklistUsers],
  },
  {
    icon: <HeatMapOutlined className="sider-icon" />,
    label: 'Roles',
    key: 'roles',
    abilities: [Abilities.ManageRolesAbilities],
  },
  {
    label: 'Affiliate',
    key: 'affiliate',
    icon: <UsergroupAddOutlined className="sider-icon" />,
    abilities: [Abilities.ViewAffiliates],
    children: [
      {
        label: 'Payouts',
        key: 'affiliate-payouts',
        icon: <DollarOutlined className="sider-icon" />,
        abilities: [Abilities.ViewAffiliates],
      },
      {
        label: 'Groups',
        key: 'affiliate-groups',
        icon: <UsergroupAddOutlined className="sider-icon" />,
        abilities: [Abilities.ViewAffiliates],
      },
      {
        label: 'Commissions',
        key: 'affiliate-commissions',
        icon: <UnorderedListOutlined className="sider-icon" />,
        abilities: [Abilities.ViewAffiliates],
      },
    ],
  },
  {
    label: 'Samples',
    key: 'samples',
    icon: <FundViewOutlined className="sider-icon" />,
    abilities: [Abilities.ManageSamples],
    children: [
      {
        label: 'Organizations',
        key: 'samples-organizations',
        icon: <TeamOutlined className="sider-icon" />,
        abilities: [Abilities.ManageSamples],
      },
      {
        label: 'Coupons',
        key: 'samples-coupons',
        icon: <GiftOutlined className="sider-icon" />,
        abilities: [Abilities.ManageSamples],
      },
    ],
  },
  {
    icon: <SmileOutlined className="sider-icon" />,
    label: 'Partners',
    key: 'partners',
    abilities: [Abilities.ManagePartners],
  },
  {
    icon: <ContainerOutlined className="sider-icon" />,
    label: 'Programs',
    key: 'programs-groups',
    abilities: [Abilities.ManagePrograms],
    children: [
      {
        icon: <ContainerOutlined className="sider-icon" />,
        label: 'Programs',
        key: 'programs',
        abilities: [Abilities.ManagePrograms],
      },
      {
        icon: <InboxOutlined className="sider-icon" />,
        label: 'Groups',
        key: 'groups',
        abilities: [Abilities.ManageGroups],
      },
    ],
  },
  {
    icon: <AppstoreAddOutlined className="sider-icon" />,
    label: 'Add Ons',
    key: 'add-ons',
    abilities: [Abilities.ViewAddOns, Abilities.ManageAddOns],
  },
  {
    label: 'Users',
    key: 'users-section',
    abilities: [Abilities.ViewUsers, Abilities.ManageUsers],
    icon: <TeamOutlined className="sider-icon" />,
    children: [
      {
        label: 'Users',
        key: 'users',
        abilities: [Abilities.ViewUsers, Abilities.ManageUsers],
        icon: <TeamOutlined className="sider-icon" />,
      },
      {
        label: 'Payouts',
        key: 'user-payouts',
        icon: <DollarOutlined className="sider-icon" />,
        abilities: [Abilities.ViewPayments, Abilities.ViewPayouts],
      },
      {
        label: 'Credits',
        key: 'credit-transactions',
        icon: <MoneyCollectOutlined className="sider-icon" />,
        abilities: [Abilities.ViewCredits],
      },
    ],
  },
  {
    icon: <BankOutlined className="sider-icon" />,
    label: 'Accounts',
    key: 'accounts',
    abilities: [Abilities.ViewAccounts],
  },
  {
    icon: <OrderedListOutlined className="sider-icon" />,
    label: 'Orders',
    key: 'orders',
    abilities: [Abilities.ViewOrders],
  },
  {
    icon: <SettingOutlined className="sider-icon" />,
    label: 'Trading Systems',
    key: 'trading-systems',
    abilities: [Abilities.ManageTradingSystem],
  },
];

interface Props {
  children: React.ReactNode;
}

function filterMenuItems(currentUser: AdminUser, items: MenuItem[]) {
  return items.filter((item) => {
    if (item.children && item.children.length > 0) {
      const filteredChildren = filterMenuItems(currentUser, item.children);

      if (filteredChildren.length === 0) {
        return false;
      }

      item.children = filteredChildren;
    }

    if ((item.children || []).length > 0) {
      return true;
    }

    if (!item.abilities) {
      return true;
    }

    return item.abilities.some((ability) =>
      isUserAllowed({
        abilityRequired: ability,
        userAbilities: currentUser.abilities,
        role: currentUser.role,
      })
    );
  });
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  const {
    dataStore: {
      applicationConfigStore,
      userStore: { currentUser },
    },
  } = useStore();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token } = useThemeToken();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const filteredItems = filterMenuItems(currentUser, items);

  return (
    <AntdLayout className="h-full layout">
      <Sider
        collapsed={collapsed}
        collapsible={true}
        theme="light"
        breakpoint="lg"
        trigger={
          !collapsed ? (
            <LeftCircleOutlined className="sider-icon" />
          ) : (
            <RightCircleOutlined className="sider-icon" />
          )
        }
        onCollapse={(collapsed, type) => {
          toggleCollapsed();
        }}
      >
        <div className="logo-container" onClick={() => navigate('/overview')}>
          <Logo />
          {collapsed ? null : (
            <TextComponent>
              {applicationConfigStore.applicationConfig.name}
            </TextComponent>
          )}
        </div>
        <Menu
          mode="inline"
          onClick={(item) => navigate(item.key)}
          selectedKeys={pathname.split('/')}
          items={filteredItems}
        />
      </Sider>
      <AntdLayout>
        <Content className="content">
          <div
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            <Header />
            <Flex
              className="content-footer-container"
              vertical
              justify={'space-between'}
            >
              {children}
              <TextComponent className="footer-copyright">
                <a
                  style={{ color: token.colorPrimary }}
                  href={applicationConfigStore.applicationConfig.website}
                  rel="noreferrer"
                  target="_blank"
                >
                  {applicationConfigStore.applicationConfig.name}
                </a>{' '}
                - COPYRIGHT © {new Date().getFullYear()}
              </TextComponent>
            </Flex>
          </div>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default observer(Layout);
