import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Modal, ModalProps } from 'antd';
import { noop } from 'lodash';
import { LoadingState, useLoading } from '@monorepo/client-common';

export interface FormModalActions {
  open: (item?: any) => void;
  close: () => void;
}

type Props = PropsWithChildren &
  ModalProps & {
    form: UseFormReturn<any>;
    onClose?: () => void;
    title?: string;
    width?: string;
    onSave: (data: any) => Promise<void>;
    getDefaultValues?: () => any;
  };

const FormModal = forwardRef<FormModalActions, Props>(
  (
    {
      children,
      form,
      title,
      onClose = noop,
      onSave,
      width,
      getDefaultValues = noop,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState(false);
    const { loadingState, updateLoadingState } = useLoading();

    useEffect(() => {
      if (isOpen) {
        form.reset(getDefaultValues(), {});
      }
    }, [form, isOpen]);

    const _onClose = () => {
      onClose();
      setOpen(false);
      form.reset();
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          open: (item?: any) => {
            setOpen(true);
            form.reset(item || {});
          },
          close: () => {
            _onClose();
          },
        };
      },
      [form]
    );

    const _onSave = async () => {
      try {
        await form.trigger();

        if (!form.formState.isValid) {
          return;
        }

        updateLoadingState(LoadingState.Loading);

        await onSave(form.getValues());

        _onClose();
      } catch (e) {
        console.error(e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const isLoading = loadingState === LoadingState.Loading;

    return (
      <Modal
        title={title}
        width={width || '80%'}
        open={isOpen}
        okButtonProps={{
          disabled: !form.formState.isDirty,
        }}
        confirmLoading={isLoading}
        onCancel={_onClose}
        onOk={_onSave}
        {...rest}
      >
        {children}
      </Modal>
    );
  }
);

export default FormModal;
