import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { Partner, PartnerType } from '@monorepo/types';
import {
  FormInput,
  FormRadioGroup,
  FormSelect,
} from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';

const partners = Object.values(PartnerType).map((partner) => {
  return { value: partner, label: partner };
});

export const PartnerDrawer: FunctionComponent<{
  form: UseFormReturn<Partner>;
}> = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <FormInput
          placeholder="Title"
          text={'Title'}
          form={form}
          controllerProps={{
            name: 'title',
            rules: { required: { value: true, message: 'Title is required' } },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Description"
          text={'description'}
          form={form}
          controllerProps={{
            name: 'description',
            rules: {
              required: { value: true, message: 'Description is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Video Url"
          text={'Video Url'}
          form={form}
          controllerProps={{
            name: 'videoUrl',
            rules: {
              required: { value: true, message: 'Video Url is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Google Sheet Id"
          text={'Google Sheet Id'}
          form={form}
          controllerProps={{
            name: 'googleSheetId',
            rules: {
              required: { value: true, message: 'GoogleSheetId is required' },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{ name: 'type' }}
          text={'type'}
          options={partners}
        />
      </Col>
      <Col span={24}>
        <FormRadioGroup
          form={form}
          text={'Enabled'}
          options={[
            {
              value: false,
              text: 'No',
            },
            {
              value: true,
              text: 'Yes',
            },
          ]}
          controllerProps={{
            name: 'enabled',
            defaultValue: false,
          }}
        />
      </Col>
    </Row>
  );
};
