import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TextComponent } from '@monorepo/react-components';
import { ApplicationConfig } from '@monorepo/types';
import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Row,
  Typography,
} from 'antd';
import { FunctionComponent, useRef } from 'react';
import { FormModalActions } from '../../form-modal/form-modal';
import ApplicationConfigOverviewForm from './application-config-overview-form';

const { Link } = Typography;

interface Props {
  applicationConfig: ApplicationConfig;
  onSave: () => void;
}

const ApplicationConfigOverview: FunctionComponent<Props> = ({
  applicationConfig,
  onSave,
}) => {
  const applicationConfigFormRef = useRef<FormModalActions>(
    {} as FormModalActions
  );

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Name',
      children: (
        <TextComponent ellipsis={true}>{applicationConfig.name}</TextComponent>
      ),
    },
    {
      key: '2',
      label: 'TimeZone',
      span: 1,
      children: (
        <TextComponent ellipsis={true} capitalize={true}>
          {applicationConfig.timeZone}
        </TextComponent>
      ),
    },
    {
      key: '3',
      label: 'Should Create Demo Account',
      span: 1,
      style: {
        textAlign: 'center',
      },
      children: applicationConfig.shouldCreateDemoAccount ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      ),
    },
    {
      key: '4',
      label: 'Website',
      span: 1,
      children: (
        <Link href={applicationConfig.website} target="_blank">
          {applicationConfig.website}
        </Link>
      ),
    },
    {
      key: '5',
      label: 'Dashboard Layout',
      span: 1,
      children: (
        <TextComponent>{applicationConfig.dashboardLayout}</TextComponent>
      ),
    },
    {
      key: '6',
      label: 'Terms And Conditions',
      span: 1,
      children: (
        <Link href={applicationConfig.termsAndConditions} target="_blank">
          {applicationConfig.termsAndConditions}
        </Link>
      ),
    },
    {
      key: '7',
      label: 'Discord Link',
      span: 1,
      children: (
        <Link href={applicationConfig.discordLink} target="_blank">
          {applicationConfig.discordLink}
        </Link>
      ),
    },
    {
      key: 'restrictAccessToDiscord',
      label: 'Restrict Discord For New Users',
      span: 1,
      children: applicationConfig.restrictAccessToDiscord ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      ),
    },
    {
      key: 'youtubeLink',
      label: 'Youtube Link',
      span: 1,
      children: (
        <Link href={applicationConfig.youtubeLink} target="_blank">
          {applicationConfig.youtubeLink}
        </Link>
      ),
    },
    {
      key: 'facebookLink',
      label: 'Facebook Link',
      span: 1,
      children: (
        <Link href={applicationConfig.facebookLink} target="_blank">
          {applicationConfig.facebookLink}
        </Link>
      ),
    },
    {
      key: 'instagramLink',
      label: 'Instagram Link',
      span: 1,
      children: (
        <Link href={applicationConfig.instagramLink} target="_blank">
          {applicationConfig.instagramLink}
        </Link>
      ),
    },
    {
      key: 'twitterLink',
      label: 'Twitter Link',
      span: 1,
      children: (
        <Link href={applicationConfig.twitterLink} target="_blank">
          {applicationConfig.twitterLink}
        </Link>
      ),
    },
    {
      key: '8',
      label: 'User buying power limitation',
      span: 1,
      children: applicationConfig.userBuyingPowerLimitation ? (
        <TextComponent>
          {applicationConfig.userBuyingPowerLimitation}
        </TextComponent>
      ) : (
        <CloseOutlined />
      ),
    },
    {
      key: '10',
      label: 'Achievements',
      span: 1,
      style: {
        textAlign: 'center',
      },
      children: applicationConfig.achievements ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      ),
    },
    {
      key: 'groupPrograms',
      label: 'Group programs',
      span: 1,
      style: {
        textAlign: 'center',
      },
      children: applicationConfig.groupPrograms ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      ),
    },
    {
      key: 'countriesFilterType',
      label: 'Countries Filter Type',
      span: 1,
      style: {
        textAlign: 'center',
      },
      children: applicationConfig.countryFilter?.type,
    },
    {
      key: 'countriesFilterCountries',
      label: 'Countries To Filter',
      span: 2,
      style: {
        textAlign: 'center',
      },
      children: applicationConfig.countryFilter?.countries?.join(', ') || '-',
    },
  ];

  return (
    <Row gutter={[15, 15]}>
      <Col push={22} span={1}>
        <Button
          key="create-btn"
          onClick={() =>
            applicationConfigFormRef.current.open(applicationConfig)
          }
        >
          Edit
        </Button>
      </Col>
      <Col span={24}>
        <Descriptions column={7} layout="vertical" bordered items={items} />
        <ApplicationConfigOverviewForm
          onSave={onSave}
          applicationConfig={applicationConfig}
          ref={applicationConfigFormRef}
        />
      </Col>
    </Row>
  );
};

export default ApplicationConfigOverview;
