import { EntityStore } from '@monorepo/client-common';
import { OrganizationRequestFilters, Payout } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import OrganizationService from '../../../services/entities/organization-service';

export default class OrganizationStore extends EntityStore<
  OrganizationService,
  Payout
> {
  constructor() {
    const {
      apiFactory: { organizationService },
    } = getEnv();

    super(organizationService);
  }

  search(query: OrganizationRequestFilters) {
    return this.service.search(query);
  }
}
