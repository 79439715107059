import { CrudService } from '@monorepo/client-common';
import {
  AddOnType,
  AdminNotification,
  ApplicationConfig,
  ApplicationContract,
  ApplicationScheduledJob,
  ApplicationTradingSystem,
  Banner,
  BannerPlacement,
  BlacklistEmail,
  SidebarItem,
  TargetEmails,
  WithdrawRule,
} from '@monorepo/types';

export default class ApplicationConfigService extends CrudService {
  static override route = 'application-config';

  async updateAccountWithdrawRules(data: {
    id: string;
    accountWithdrawRules: WithdrawRule[];
  }) {
    const response = await this.httpService.post<
      { id: string; accountWithdrawRules: WithdrawRule[] },
      { data: string }
    >(`${this.path}/update-account-withdraw-rules`, data);

    return response.data;
  }

  async updateAffiliateWithdrawRules(data: {
    id: string;
    affiliateWithdrawRules: WithdrawRule[];
  }) {
    const response = await this.httpService.post<
      { id: string; affiliateWithdrawRules: WithdrawRule[] },
      { data: string }
    >(`${this.path}/update-affiliate-withdraw-rules`, data);

    return response.data;
  }

  async updateScheduledJobs(data: {
    id: string;
    scheduledJobs: ApplicationScheduledJob[];
  }) {
    const response = await this.httpService.post<
      { id: string; scheduledJobs: ApplicationScheduledJob[] },
      { data: string }
    >(`${this.path}/update-scheduled-jobs`, data);

    return response.data;
  }

  async runJob(data: { job: ApplicationScheduledJob }) {
    const response = await this.httpService.post<
      { job: ApplicationScheduledJob },
      {
        data: string;
      }
    >(`${this.path}/run-job`, data);

    return response.data;
  }

  async updateBlacklistEmails(data: {
    id: string;
    blacklistEmails: BlacklistEmail[];
  }) {
    const response = await this.httpService.post<
      { id: string; blacklistEmails: BlacklistEmail[] },
      { data: string }
    >(`${this.path}/update-blacklist-emails`, data);

    return response.data;
  }

  async updateTradingSystems(data: {
    id: string;
    tradingSystems: ApplicationTradingSystem[];
  }) {
    const response = await this.httpService.post<
      { id: string; tradingSystems: ApplicationTradingSystem[] },
      { data: string }
    >(`${this.path}/update-trading-systems`, data);

    return response.data;
  }

  updateTargetEmails(data: { id: string; targetEmails: TargetEmails }) {
    return this.httpService.post<
      { id: string; targetEmails: TargetEmails },
      { data: string }
    >(`${this.path}/update-target-emails`, data);
  }

  async updateAddOns(data: { id: string; addOns: AddOnType[] }) {
    const response = await this.httpService.post<
      { id: string; addOns: AddOnType[] },
      { data: string }
    >(`${this.path}/update-add-ons`, data);

    return response.data;
  }

  async updateSidebar(data: { id: string; sidebar: SidebarItem[] }) {
    const response = await this.httpService.post<
      { id: string; sidebar: SidebarItem[] },
      { data: string }
    >(`${this.path}/update-sidebar`, data);

    return response.data;
  }

  async updateContracts(data: {
    id: string;
    contracts: ApplicationContract[];
  }) {
    const response = await this.httpService.post<
      { id: string; contracts: ApplicationContract[] },
      { data: string }
    >(`${this.path}/update-contracts`, data);

    return response.data;
  }

  async updateAdminNotification(data: {
    id: string;
    adminNotification: AdminNotification[];
  }) {
    const response = await this.httpService.post<
      { id: string; adminNotification: AdminNotification[] },
      { data: string }
    >(`${this.path}/update-admin-notification`, data);

    return response.data;
  }

  async updateBanners(formData: FormData) {
    const response = await this.httpService.post<
      FormData,
      { data: ApplicationConfig }
    >(`${this.path}/create-banner`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }

  async deleteBanner(
    id: string,
    banners: Banner[],
    name: string,
    placement: BannerPlacement
  ) {
    const response = await this.httpService.post<
      {
        id: string;
        banners: Banner[];
        name: string;
        placement: BannerPlacement;
      },
      { data: string }
    >(`${this.path}/delete-banner`, { id, banners, name, placement });

    return response.data;
  }
}
