import {
  CalendarOutlined,
  ExclamationCircleFilled,
  FieldTimeOutlined,
  FundProjectionScreenOutlined,
  MoreOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { App, Button, Dropdown, message, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { FunctionComponent, useRef, useState } from 'react';
import { useStore } from '../../helpers/use-store';
import { Abilities, AccountStatus, Application, Role } from '@monorepo/types';
import { AccountAdmin } from './accounts';
import { FormModalActions } from '../form-modal/form-modal';
import AccountProfitableDayModal from './account-profitable-day';
import AccountEventsModal from './account-events';
import ProtectedElement from '../../components/protected-element/protected-element';

interface Props {
  account: AccountAdmin;
  reFetch: () => void;
}

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const MAX_CREATION_AMOUNT = 5;

const AccountActions: FunctionComponent<Props> = ({ account, reFetch }) => {
  const {
    _id,
    tsAccount: accountId,
    userId: accountUserId,
    programId,
    orderId,
    status,
    creationRetries,
  } = account;

  const {
    dataStore: {
      userStore: { isUserAllowed, currentUser },
      accountStore,
    },
  } = useStore();

  const {
    modal: { confirm },
  } = App.useApp();

  const profitDayModalFormRef = useRef<FormModalActions>(
    {} as FormModalActions
  );

  const [openEventsModal, setOpenEventsModal] = useState(false);

  const items: MenuProps['items'] = [];
  if (
    isUserAllowed(currentUser, [
      Abilities.ManageAccounts,
      Abilities.ImpersonateAccount,
    ])
  ) {
    items.push({
      key: 'impersonate',
      label: 'Dashboard',
      onClick: async () => {
        try {
          const url = await accountStore.impersonate(accountId, accountUserId);

          window.open(url, '_blank');

          message.info('Impersonated successfully');
        } catch (e) {
          console.error(`Failed impersonating: ${accountId}`, e);
        }
      },
      icon: <FundProjectionScreenOutlined />,
    });
  }
  if (currentUser.role === Role.SuperUser) {
    items.push({
      key: 'delete',
      label: 'delete',
      onClick: async () => {
        confirm({
          title: `Are you sure you want to delete account`,
          icon: <ExclamationCircleFilled />,
          content: `Account: ${accountId}`,
          async onOk() {
            try {
              const result = await accountStore.delete(_id);

              if (!result) {
                throw new Error(`Failed`);
              }

              message.info('Deleted successfully');
              reFetch();
            } catch (e) {
              message.error(`Failed deleting`);
              console.error(`Failed deleting: ${accountId}`, e);
            }
          },
        });
      },
      icon: <ExclamationCircleFilled />,
    });
  }

  const isCreatePendingEnabled =
    status === AccountStatus.PendingCreation &&
    creationRetries &&
    creationRetries >= MAX_CREATION_AMOUNT &&
    isUserAllowed(currentUser, [Abilities.CreatePendingAccounts]);

  if (isCreatePendingEnabled) {
    items.push({
      key: 'create',
      label: 'Create',
      onClick: async () => {
        try {
          const result = await accountStore.createPendingAccount({
            _id,
            programId,
            userId: accountUserId,
            orderId,
          });

          if (!result) {
            throw new Error(`Failed`);
          }

          message.info('Created successfully');
          reFetch();
        } catch (e) {
          message.error(`Failed creating`);
          console.error(`Failed creating: ${accountId}`, e);
        }
      },
      icon: <RedoOutlined />,
    });
  }

  const is5ers = project === Application.THE5ERS;

  if (
    isUserAllowed(currentUser, [
      Abilities.ManageProfitableDays,
      Abilities.ManageAccounts,
    ]) &&
    is5ers
  ) {
    items.push({
      key: 'profitable-day',
      label: 'Add profitable day',
      disabled: !accountId,
      onClick: () => profitDayModalFormRef.current.open(),
      icon: <CalendarOutlined />,
    });
  }

  if (currentUser.role === Role.SuperUser && is5ers) {
    items.push({
      key: 'sync',
      label: 'Sync',
      disabled: !accountId,
      onClick: () => accountStore.sync(_id),
      icon: <CalendarOutlined />,
    });
  }

  items.push({
    key: 'account-events',
    label: 'Account events',
    onClick: () => {
      setOpenEventsModal(true);
    },
    icon: <FieldTimeOutlined />,
  });

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <div className="accounts-actions">
        <Dropdown menu={{ items }}>
          <Button type="default" size="small">
            <Space>
              <MoreOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
      <ProtectedElement
        abilities={[Abilities.ManageProfitableDays, Abilities.ManageAccounts]}
      >
        <AccountProfitableDayModal
          accountId={_id}
          ref={profitDayModalFormRef}
        />
      </ProtectedElement>

      <AccountEventsModal
        accountId={accountId}
        open={openEventsModal}
        close={() => setOpenEventsModal(false)}
      />
    </>
  );
};

export default AccountActions;
