import { Col, Flex, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import {
  Coupon,
  CouponItemType,
  ProductType,
  TradingSystem,
} from '@monorepo/types';
import {
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormRadioGroup,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import { UseFormReturn } from 'react-hook-form';
import OrganizationAutocomplete from '../../components/organization-autocomplete/organization-autocomplete';
import ProgramAutocomplete from '../../components/program-autocomplete/program-autocomplete';
import { Divider } from 'antd/lib';
import AddOnAutocomplete from '../../components/add-on-autocomplete/add-on-autocomplete';
import isAlphanumeric from 'validator/es/lib/isAlphanumeric';
import isLowercase from 'validator/es/lib/isLowercase';
import { getEnv } from '../../helpers/mobx-easy-wrapper';

export const CouponsDrawer: FunctionComponent<{
  form: UseFormReturn<Coupon>;
}> = ({ form }) => {
  const {
    envConfig: { hub_url },
  } = getEnv();

  const productType = form.watch('items.0.productType');
  const productId = form.watch('items.0.metadata.productId');
  const onSelectOrganization = async (organizationCode: string) => {
    form.setValue('organizationCode', organizationCode, { shouldDirty: true });
    const previewLink = `${hub_url}#/coupons/${organizationCode}`;
    form.setValue('link', previewLink, { shouldDirty: true });
  };

  const isValidCouponName = (str: string) => {
    const result = isAlphanumeric(str) && isLowercase(str);
    return result || 'only lower case letters and numbers are allowed';
  };
  const onClearProgram = () => {
    form.setValue('items.0.metadata.productId', undefined);
    form.setValue('items.0.metadata.productName', undefined);
  };

  const onSelectProduct = async (item: { label: string; value: string }) => {
    form.setValue('items.0.metadata.productId', item.value, {
      shouldDirty: true,
    });
    form.setValue('items.0.metadata.productName', item.label, {
      shouldDirty: true,
    });
  };

  const isProductProgram = productType === ProductType.Program;

  const isProductAddon = productType === ProductType.AddOn;

  if (!(isProductProgram || isProductAddon)) {
    form.setValue('items.0.metadata', undefined);
  }

  return (
    <Row gutter={[0, 4]}>
      <Col span={12}>
        <Flex vertical gap={4}>
          <div>Organization:</div>
          <OrganizationAutocomplete
            onSelect={(organizationCode) =>
              onSelectOrganization(organizationCode)
            }
          />
        </Flex>
      </Col>
      <Col span={6}>
        <FormInputNumber
          placeholder="Amount"
          text={'Amount'}
          form={form}
          controllerProps={{
            name: 'amount',
            rules: {
              min: { value: 1, message: 'Coupon amount must be bigger then 0' },
            },
          }}
        />
      </Col>
      <Col span={6}>
        <FormDatePicker
          placeholder="Expiration Date"
          text={'Expiration Date'}
          form={form}
          controllerProps={{
            rules: {
              required: true,
            },
            name: 'expirationDate',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Copoun Name"
          text={'Coupon Name'}
          form={form}
          controllerProps={{
            name: 'name',
            rules: {
              required: { value: true, message: 'Name is required' },
              validate: isValidCouponName,
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Link"
          text={'Link'}
          form={form}
          props={{ disabled: true }}
          controllerProps={{
            name: 'link',
          }}
        />
      </Col>

      <Col span={24}>
        <FormInput
          placeholder="Comment"
          text={'Comment'}
          form={form}
          controllerProps={{
            name: 'comment',
          }}
        />
      </Col>
      <Divider>Coupon Item Details</Divider>

      <Col span={24}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'items.0.productType',
            rules: { required: 'Missing Product Type' },
          }}
          text={'Product Type'}
          placeholder={'Product'}
          options={Object.values(ProductType).map((productType) => ({
            label: (
              <TextComponent capitalize={true}>{productType}</TextComponent>
            ),
            value: productType,
          }))}
        />
      </Col>
      <Col span={24}>
        <FormRadioGroup
          text={'Discount type:'}
          form={form}
          options={[
            {
              value: CouponItemType.Nominal,
              text: '$',
            },
            {
              value: CouponItemType.Percentage,
              text: '%',
            },
          ]}
          controllerProps={{
            name: 'items.0.couponType',
          }}
        />
      </Col>
      <Col span={24}>
        <FormInputNumber
          placeholder="Discount Amount"
          text={'Discount Amount'}
          form={form}
          controllerProps={{
            name: 'items.0.amount',
            rules: {
              min: {
                value: 1,
                message: 'Discount amount must be more then 1',
              },
              max: {
                value: 100,
                message: 'Discount amount must be less then 100',
              },
            },
          }}
        />
      </Col>

      {isProductProgram && (
        <>
          <Col span={24}>
            <div>Filter by program name:</div>
            <ProgramAutocomplete
              onSelect={onSelectProduct}
              onClear={onClearProgram}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'items.0.metadata.tradingSystem',
                rules: {
                  required: productId ? 'Missing Trading System Type' : false,
                },
              }}
              text={'Trading System'}
              placeholder={'Trading System'}
              options={Object.values(TradingSystem).map((tradingSystem) => ({
                label: (
                  <TextComponent capitalize={true}>
                    {tradingSystem}
                  </TextComponent>
                ),
                value: tradingSystem,
              }))}
            />
          </Col>
        </>
      )}

      {isProductAddon && (
        <>
          <Col span={24}>
            <div>Filter by add on name:</div>
            <AddOnAutocomplete
              onSelect={onSelectProduct}
              onClear={onClearProgram}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              form={form}
              controllerProps={{
                name: 'items.0.metadata.tradingSystem',
                rules: {
                  required: productId ? 'Missing Trading System Type' : false,
                },
              }}
              text={'Trading System'}
              placeholder={'Trading System'}
              options={Object.values(TradingSystem).map((tradingSystem) => ({
                label: (
                  <TextComponent capitalize={true}>
                    {tradingSystem}
                  </TextComponent>
                ),
                value: tradingSystem,
              }))}
            />
          </Col>
        </>
      )}
    </Row>
  );
};
